import ResponsivePagination from 'react-responsive-pagination';


export const CustomResponsivePagination  = ({currentPage, totalPages, setCurrentPage}) => {

    return (
    <ResponsivePagination
        className="mt-4 pagination justify-content-center"
        current={currentPage}
        total={totalPages}
        onPageChange={setCurrentPage}
    />
);
}