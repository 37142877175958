import React from "react";
import Select from "react-select";

const JobType = () => {
  const options = [
    { label: "Développement Web", value: "web-development" },
    { label: "Développement Mobile", value: "mobile-development" },
    { label: "Data Science", value: "data-science" },
    { label: "Sécurité Informatique", value: "cyber-security" },
    { label: "Réseaux", value: "networking" },
    { label: "Cloud Computing", value: "cloud-computing" },
    { label: "Intelligence Artificielle", value: "ai" },
  ];

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "2px solid #50D3BB", // Ajout de la bordure
      borderRadius: "0",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isDisabled
          ? "#ccc" // Couleur du texte pour les options désactivées
          : isSelected
          ? "white" // Couleur du texte pour les options sélectionnées
          : isFocused
          ? "#50D3BB" // Couleur du texte pour les options survolées
          : "black", // Couleur du texte pour les options normales
      };
    },
  };

  return (
    <React.Fragment>
      <div className="selecte-tag position-relative">
        <Select
          isMulti
          styles={colourStyles}
          options={options}
          placeholder="Sélectionner les domaines"
        />
      </div>
    </React.Fragment>
  );
};

export default JobType;
