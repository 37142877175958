import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Masonry from "react-masonry-component";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "react-lottie";

// import * as animationData from "../../../assets/loading.json"; // Animation de chargement
import * as errorAnimationData from "../../../../src/assets/error.json"; // Animation pour l'erreur
import * as emptyAnimationData from "../../../../src/assets/emptyData.json"; // Animation pour données vides

import { fetchCompaniesPaged } from "../../../redux/company/action";
import CompanyCard from "./CompanyCard";
import Section from "./Section";
import Pagination from "./Pagination";
import JobCardItemSkeleton from "../../Jobs/JobGrid/JobCardItemSkeleton";

const CompanyList = () => {
  document.title = "Liste des entrepises | RecruTech";
  const firstCardRef = useRef(null);
  // Gérer l'état de chargement
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { company, error } = useSelector((state) => state.companyReducer);

  const setCurrentPage = (page) => {
    dispatch(fetchCompaniesPaged(page));
  };

  useEffect(() => {
    dispatch(fetchCompaniesPaged(company.currentPage));
    window.scrollTo(0, 0);
  }, [dispatch, company.currentPage]);

  // Gérer l'état de chargement en fonction des données ou des erreurs
  useEffect(() => {
    if (company) {
      // Simuler un délai de 3000ms (3 secondes)
      setTimeout(() => {
        setLoading(false);
      }, 1000); // 3000 millisecondes = 3 secondes
    }
  }, [company]);

  // Paramètres pour l'animation Lottie d'erreur
  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Paramètres pour l'animation Lottie de données vides
  const emptyOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          {loading ? (
            <div className="text-center mt-5">
              <Row className="align-items-center mb-1">
                <Col md={6}>
                  <JobCardItemSkeleton />
                </Col>
                <Col md={6}>
                  <JobCardItemSkeleton />
                </Col>
              </Row>
            </div>
          ) : error ? (
            <div className="text-center mt-0">
              <Lottie options={errorOptions} height={300} width={300} />
              <p className="text-danger mt-0">
                Une erreur s'est produite : {error}
              </p>
            </div>
          ) : company.content.length === 0 ? (
            <div className="text-center mt-0">
              <Lottie options={emptyOptions} height={300} width={300} />
              <p className="text-muted mt-0">
                Aucune entreprise trouvée. Veuillez réessayer plus tard.
              </p>
            </div>
          ) : (
            <>
              <Masonry className="row">
                {company.content.map((item, index) => (
                  <Col
                    key={item.id} // Utiliser l'ID de l'entreprise comme clé unique
                    lg={4}
                    md={6}
                    className="mb-4"
                  >
                    <CompanyCard company={item} />
                  </Col>
                ))}
              </Masonry>
              <Pagination
                currentPage={company.currentPage}
                totalPages={company.totalPages}
                fetchData={(page) => dispatch(fetchCompaniesPaged(page))}
                setCurrentPage={setCurrentPage}
                firstCardRef={firstCardRef}
              />
            </>
          )}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompanyList;
