import React, {useEffect, useState} from "react";
import {Tooltip} from "antd";
import {Card, CardBody} from "reactstrap";
import {useDispatch} from "react-redux";
import TokenService from "../../../../../service/TokenService";
import "./JobDetails.css";
import {removeFreelancerApplication} from "../../../../../redux/freelance/offer/action";

const RightSideContent = ({proposalDetails}) => {
    console.log("Détails de la candidature:", proposalDetails);
    const [user, setUser] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const getUser = TokenService.getUser();
        if (getUser) {
            setUser(getUser);
        }
        window.scrollTo(0, 0);
    }, []);

    const handleCancelApplication = () => {
        if (proposalDetails?.id) {
            dispatch(removeFreelancerApplication(proposalDetails.id));
        }
    };
    const statusMapping = {
        ACCEPT: "Accepté",
        IN_PROGRESS: "Reçue",
        REJECT: "Rejeté"
    };


    if (!proposalDetails?.id) {
        return <div>Aucune proposition disponible.</div>;
    }

    return (
        <div className="side-bar ms-lg-4">
            <Card className="job-overview">
                <CardBody className="p-4">
                    <h6 className="fs-17 mt-4">Détails de la proposition</h6>
                    <ul className="list-unstyled mt-3">
                        <li>
                            <div className="d-flex align-items-center mt-3">
                                <i className="mdi mdi-calendar-clock icon bg-success-subtle text-success"></i>
                                <div className="ms-3">
                                    <h6 className="fs-14 mb-2">Durée</h6>
                                    <p className="text-muted mb-0">
                                        {proposalDetails?.duration} jours
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex align-items-center mt-3">
                                <i className="mdi mdi-cash icon bg-success-subtle text-success"></i>
                                <div className="ms-3">
                                    <h6 className="fs-14 mb-2">Prix proposé</h6>
                                    <p className="text-muted mb-0">
                                        {proposalDetails?.price} {proposalDetails?.offer?.devise}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex align-items-center mt-3">
                                <i className="mdi mdi-message-text icon bg-success-subtle text-success"></i>
                                <div className="ms-3">
                                    <h6 className="fs-14 mb-2">Message</h6>
                                    <p className="text-muted mb-0">{proposalDetails?.message}</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex align-items-center mt-3">
                                <i className="mdi mdi-calendar-clock icon bg-success-subtle text-success"></i>
                                <div className="ms-3">
                                    <h6 className="fs-14 mb-2">Date de la candidature</h6>
                                    <p className="text-muted mb-0">
                                        {proposalDetails?.created_at.split("T")[0]}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex align-items-center mt-3">
                                <i className="mdi mdi-checkbox-marked-circle-outline icon bg-info-subtle text-info"></i>
                                <div className="ms-3">
                                    <h6 className="fs-14 mb-2">Statut de la candidature</h6>
                                    <p className="text-muted mb-0">
                                        {statusMapping[proposalDetails?.proposal_status] || "Statut inconnu"}
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="mt-4">
                        <Tooltip
                            title={user === null ? "Veuillez vous connecter pour poursuivre" : ""}
                            color="red"
                        >
                            <button
                                onClick={user !== null ? handleCancelApplication : undefined}
                                className="btn btn-danger btn-hover w-100 mt-2"
                            >
                                Annuler la candidature <i className="uil uil-times"></i>
                            </button>
                        </Tooltip>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default RightSideContent;
