import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";
import * as animationData from "../../../src/assets/loading.json";
import Lottie from "react-lottie";
import { Form } from "react-bootstrap";
import {
  socialSignUpAction,
  socialSignInAction,
} from "../../redux/auth/action";
import { useDispatch, useSelector } from "react-redux";
import Error404Image from "../../assets/images/404.png";

const Auth = () => {
  document.title = "Social connexion link | RecruTech";

  const [customerType, setCustomerType] = useState("JOB_SEEKER");
  const [loading, setLoading] = useState(false);
  const [oauthAction] = useState(localStorage.getItem("AuthAction") || "");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const { message } = useSelector((state) => state.message);
  const { authError } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailParam = params.get("email");
    const firstNameParam = params.get("given_name");
    const lastNameParam = params.get("family_name");
    const accessTokenParam = params.get("access_token");

    setEmail(emailParam || "");
    setFirstName(firstNameParam || "");
    setLastName(lastNameParam || "");
    setAccessToken(accessTokenParam || "");
    if (
      emailParam &&
      firstNameParam &&
      lastNameParam &&
      oauthAction === "LOGIN"
    ) {
      //   console.log("🔹 oauthAction:", oauthAction);
      dispatch(socialSignInAction({ email: emailParam }));
    }
  }, [dispatch, oauthAction]);

  const handleCustomerTypeChange = (e) => {
    setCustomerType(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!email || !lastName || !firstName || !accessToken) {
      return;
    }

    setLoading(true);
    await dispatch(
      socialSignUpAction({
        firstName,
        lastName,
        email,
        customerType,
        accessToken,
      })
    );
    setLoading(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  };

  if (email && lastName && firstName && oauthAction === "REGISTRATION") {
    return (
      <div className="main-content">
        <div className="page-content">
          <section className="bg-error bg-auth text-dark">
            <Container>
              <Row className="justify-content-center">
                <Col lg={6}>
                  <div className="text-center">
                    <img
                      src="https://res.cloudinary.com/dryskp7hv/image/upload/v1688758187/Eburnie%20hub/logo/rt_couleur_simple_480_dh81k5.png"
                      alt="Logo"
                      width={100}
                      className="img-fluid"
                    />
                    <p className="text-muted mt-3">
                      Veuillez sélectionner le type de compte
                    </p>
                    <Form onSubmit={onSubmit} className="auth-form">
                      {message && (
                        <div className="mb-3 text-danger error-message">
                          {message}
                        </div>
                      )}
                      <div className="mb-5">
                        <select
                          className="form-select"
                          name="customerType"
                          id="customerType"
                          value={customerType}
                          onChange={handleCustomerTypeChange}
                          required
                          style={{ backgroundColor: "white", color: "black" }}
                        >
                          <option value="JOB_SEEKER">Demandeur d'emploi</option>
                          <option value="FREELANCER">Freelanceur</option>
                        </select>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-hover w-100"
                          disabled={loading}
                        >
                          {loading ? <Spinner size="sm" /> : "S'inscrire"}
                        </button>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </div>
    );
  }

  if (authError) {
    return (
      <div className="main-content">
        <div className="page-content">
          <section className="bg-error bg-auth text-dark">
            <Container>
              <Row className="justify-content-center">
                <Col lg={6}>
                  <div className="text-center">
                    <img
                      src={Error404Image}
                      alt="Erreur"
                      className="img-fluid"
                    />
                    <h4 className="text-uppercase mt-3">
                      Aucun compte trouvé!
                    </h4>
                    <p className="text-muted">
                      Nous avons rencontré une erreur lors de la liaison avec
                      votre compte
                    </p>
                    <div className="mt-4">
                      <Link
                        className="btn btn-primary waves-effect waves-light"
                        to="/"
                      >
                        <i className="mdi mdi-home"></i> Revenir à l'accueil
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </div>
    );
  }

  return (
    <div className="main-content">
      {loading && (
        <div className="text-center">
          <Lottie options={defaultOptions} height={400} width={400} />
          <p className="text-muted">Connexion en cours...</p>
        </div>
      )}
    </div>
  );
};

export default Auth;
