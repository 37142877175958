import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Section from "../../../pages/Blog/BlogGrid/Section";
import BlogText from "../../Blog/BlogGrid/BlogText";
import BlogCategory from "../../Blog/BlogGrid/BlogCategory";
import { useDispatch, useSelector } from "react-redux";
import { fetchTrainingModuleDetails } from "../../../redux/training/action";
import { useParams } from "react-router-dom";
import "./style.css"; // Importer le fichier CSS pour les styles
import JobDetailsSkeleton from "../../Jobs/JobDetails/JobDetailsSkeleton";

const BlogGrid = () => {
  document.title = "Mes cours | RecruTech";

  const { slug } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { selectedModuleDetails } = useSelector(
    (state) => state.trainingReducer
  );

  const [activeModule, setActiveModule] = useState(null);
  const [activeCourse, setActiveCourse] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    // Début de la récupération des détails de formation
    dispatch(fetchTrainingModuleDetails(slug));
    window.scrollTo(0, 0);
  }, [dispatch, slug]);

  useEffect(() => {
    // Vérifiez si les détails de formation sont chargés
    if (selectedModuleDetails) {
      // Vérifier si le contenu contient des modules
      if (
        selectedModuleDetails.content &&
        selectedModuleDetails.content.length > 0
      ) {
        const firstModule = selectedModuleDetails.content[0];
        setActiveModule(firstModule.id);

        // Vérifier si le module contient des cours
        if (firstModule.courses && firstModule.courses.length > 0) {
          const firstCourse = firstModule.courses[0];
          setActiveCourse(firstCourse.id);

          // Vérifier si le cours contient des sections
          if (firstCourse.sections && firstCourse.sections.length > 0) {
            setSelectedCourse(firstCourse.sections[0].slug); // Sélectionner la première section
          }
        }
      }
      // Fin du chargement des données
      setLoading(false);
    }
  }, [selectedModuleDetails]);

  return (
    <React.Fragment>
      <Section selectedModuleDetails={selectedModuleDetails} />
      <section className="section">
        {loading ? (
          <Container>
            <Col lg={12}>
              <div className="mt-5">
                {/* Utilisation de Skeleton d'Ant Design */}
                <JobDetailsSkeleton />
              </div>
            </Col>
          </Container>
        ) : (
          <Container>
            <Row className="blog-grid">
              {/* Affichage des détails du cours dans la colonne droite */}
              <Col lg={8} md={8}>
                <div className="blog-post">
                  {selectedCourse ? (
                    <BlogText selectedCourse={selectedCourse} />
                  ) : (
                    <p className="text-muted">Aucune leçon disponible.</p>
                  )}
                </div>
              </Col>
              {/* Affichage des modules et cours via BlogCategory */}
              <Col lg={4} md={4}>
                <div className="blog-post">
                  <BlogCategory
                    selectedModuleDetails={selectedModuleDetails}
                    activeModule={activeModule}
                    activeCourse={activeCourse}
                    onSelectCourse={setSelectedCourse} // Passer la fonction pour sélectionner un cours
                    onSelectModule={setActiveModule} // Passer la fonction pour sélectionner un module
                    onSelectActiveCourse={setActiveCourse} // Passer la fonction pour sélectionner un cours
                  />
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </React.Fragment>
  );
};

export default BlogGrid;
