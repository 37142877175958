import api from "../Api";
import TokenService from "../TokenService"; // Assurez-vous d'importer votre service de token
import authHeader from "../../service/auth/auth-header";

const fetchCompanyDetails = async (companyId) => {
  return await api.get(`api/v1/public/candidates/company/${companyId}`);
};

const fetchJobdetails = async (jobSlug) => {
  return await api.get(`api/v1/public/candidates/jobs/${jobSlug}/slug`);
};

const fetchRecentJobs = async () => {
  return await api.get("api/v1/public/candidates/jobs/recent");
};

const fetchJobsPaged = async (page = 0, companyId = "") => {
  const url = `/api/v1/public/candidates/jobs/paged?page=${page}&size=14${
    companyId ? `&companyId=${companyId}` : ""
  }`;
  // const url = `http://jobcollector.15.235.115.29.nip.io/api/v1/jobs`;
  return await api.get(url);
};

const fetchSimilarJobsPaged = async (jobId) => {
  const url = `/api/v1/public/candidates/jobs/${jobId}/company/paged`;
  return await api.get(url);
};

// apply job
const applyJob = async (jobId) => {
  const accessToken = TokenService.getUser();
  // Creating the payload object with the jobId
  const payload = { jobId };

  // Send the payload in the POST request body
  const response = await api.post(`/api/v1/candidates/jobs/apply`, payload, {
    headers: authHeader(accessToken.data.id_token),
  });

  return response.data; // Return the response data
};

// application

const fetchApplications = async () => {
  const accessToken = TokenService.getUser();
  const response = await api.get(`/api/v1/candidates/jobs/applications`, {
    headers: authHeader(accessToken.data.id_token),
  });

  return response.data; // Return the response data
};

const JobService = {
  fetchCompanyDetails,
  fetchJobdetails,
  fetchRecentJobs,
  fetchJobsPaged,
  fetchSimilarJobsPaged,
  applyJob,
  fetchApplications,
};

export default JobService;
