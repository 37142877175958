import React, {useEffect} from "react";
import {Col, Container, Row} from "reactstrap";
import Section from "./Section";
import Sidebar from "./Sidebar";
import {OfferProvider} from "../OfferContext";
import ApplicationsPage from "./ApplicationsPage";
import SearchOptions from "./SearchOptions";

const MyApply = () => {
    useEffect(() => {
        document.title = "Mes Candidatures | RecruTech";
    }, []);

    return (
        <React.Fragment>
            <Section/>
            <section className="section">
                <OfferProvider>
                    <Container>
                        <Row>
                            <Col lg={8}>
                                <div className="me-lg-5">
                                    <SearchOptions/>
                                    <ApplicationsPage/>
                                </div>
                            </Col>
                            <Sidebar/>
                        </Row>
                    </Container>
                </OfferProvider>
            </section>
        </React.Fragment>
    );
};
export default MyApply