import React, { useEffect } from "react";
import { Col, Row, Card, CardBody, Container } from "reactstrap";
import { Rate } from "antd";
import Section from "../Section";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCandidateExperiences,
  fetchCandidateEducations,
  fetchCandidateSkills,
  fetchCandidateLanguages,
  fetchCandidateInfoPerso,
} from "../../../../redux/profile/action";

const PdfGenerator = () => {
  document.title = "Mon CV | RecruTech";
  // Fonction pour mapper le niveau de langue en valeurs numériques
  const getLevelValue = (level) => {
    switch (level) {
      case "BEGINNER":
        return 1;
      case "INTERMEDIARY":
        return 2;
      case "EXPERT":
        return 3;
      case "FLUENT":
        return 4;
      default:
        return 0;
    }
  };

  const dispatch = useDispatch();

  // Charger les informations depuis le store Redux
  const {
    allExperiences,
    allEducations,
    allSkills,
    allLanguages,
    infoPersoDetails,
  } = useSelector((state) => state.profileReducer);

  useEffect(() => {
    // Dispatch des actions pour charger les données nécessaires
    dispatch(fetchCandidateExperiences());
    dispatch(fetchCandidateEducations());
    dispatch(fetchCandidateSkills());
    dispatch(fetchCandidateLanguages());
    dispatch(fetchCandidateInfoPerso());
  }, [dispatch]);

  const formatDate = (datestring) => {
    const options = { month: "short", year: "numeric" };
    return new Date(datestring).toLocaleDateString("fr-FR", options);
  };

  const generatePDF = async () => {
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 10; // Marges
    const footerHeight = 10; // Hauteur du pied de page
    let currentHeight = margin;

    const addFooter = (pdf, pageNumber) => {
      const margin = 10;
      const pageHeight = pdf.internal.pageSize.getHeight();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const interlocutorText = "Votre Interlocuteur";
      const contactInfo =
        "Jean-Noël N'GUESSAN - noelynguessan@gmail.com - +33 7 77 12 50 51";
      const pageText = `Page ${pageNumber}`;
      pdf.setFontSize(10);
      pdf.setTextColor("#50D3BB");
      pdf.text(interlocutorText, margin, pageHeight - 10);
      pdf.text(pageText, pageWidth - margin - 20, pageHeight - 5);
      pdf.setTextColor(0, 0, 0);
      pdf.text(contactInfo, margin, pageHeight - 5);
    };

    // Capture de la section "Expériences"
    const experienceSection = document.getElementById("experience-section");
    const experienceCanvas = await html2canvas(experienceSection, { scale: 2 });
    const experienceImage = experienceCanvas.toDataURL("image/png");
    const experienceImageHeight =
      (experienceCanvas.height * (pageWidth - 2 * margin)) /
      experienceCanvas.width;

    // Page 1 : Expériences
    if (
      currentHeight + experienceImageHeight >
      pageHeight - margin - footerHeight
    ) {
      pdf.addPage();
      currentHeight = margin;
    }
    pdf.addImage(
      experienceImage,
      "PNG",
      margin,
      currentHeight,
      pageWidth - 2 * margin,
      experienceImageHeight
    );
    addFooter(pdf, 1);

    // Capture de la section "Formations"
    const educationSection = document.getElementById("education-section");
    const educationCanvas = await html2canvas(educationSection, { scale: 2 });
    const educationImage = educationCanvas.toDataURL("image/png");
    const educationImageHeight =
      (educationCanvas.height * (pageWidth - 2 * margin)) /
      educationCanvas.width;

    // Capture de la section "Compétences"
    const skillsSection = document.getElementById("skills-section");
    const skillsCanvas = await html2canvas(skillsSection, { scale: 2 });
    const skillsImage = skillsCanvas.toDataURL("image/png");
    const skillsImageHeight =
      (skillsCanvas.height * (pageWidth - 2 * margin)) / skillsCanvas.width;

    // Capture de la section "Langues"
    const languagesSection = document.getElementById("languages-section");
    const languagesCanvas = await html2canvas(languagesSection, { scale: 2 });
    const languagesImage = languagesCanvas.toDataURL("image/png");
    const languagesImageHeight =
      (languagesCanvas.height * (pageWidth - 2 * margin)) /
      languagesCanvas.width;

    // Page 2 : Formations + Compétences + Langues
    pdf.addPage();
    currentHeight = margin;
    pdf.addImage(
      educationImage,
      "PNG",
      margin,
      currentHeight,
      pageWidth - 2 * margin,
      educationImageHeight
    );
    currentHeight += educationImageHeight + 10;
    if (
      currentHeight + skillsImageHeight >
      pageHeight - margin - footerHeight
    ) {
      pdf.addPage();
      currentHeight = margin;
    }
    pdf.addImage(
      skillsImage,
      "PNG",
      margin,
      currentHeight,
      pageWidth - 2 * margin,
      skillsImageHeight
    );
    currentHeight += skillsImageHeight + 10;

    if (
      currentHeight + languagesImageHeight >
      pageHeight - margin - footerHeight
    ) {
      pdf.addPage();
      currentHeight = margin;
    }
    pdf.addImage(
      languagesImage,
      "PNG",
      margin,
      currentHeight,
      pageWidth - 2 * margin,
      languagesImageHeight
    );

    // Télécharger le PDF
    pdf.save(
      `${infoPersoDetails?.data.user.firstName}_${infoPersoDetails?.data.user.lastName}_Recrutech_CV.pdf`
    );
  };
  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            <Col lg={12}>
              <Card className="profile-content-page mt-4 mt-lg-0">
                <CardBody className="p-4">
                  <div id="pdf-content">
                    <div id="personal-info" className="text-center mb-3">
                      <h3>
                        <strong style={{ textTransform: "uppercase" }}>
                          <span>
                            {infoPersoDetails?.data.user.firstName}{" "}
                            {infoPersoDetails?.data.user.lastName}
                          </span>
                        </strong>
                      </h3>
                      <p>
                        <strong>{infoPersoDetails?.data.user.login}</strong>
                      </p>
                      <p>
                        <strong>{infoPersoDetails?.data.whatsapp}</strong>
                      </p>
                    </div>
                    <div id="skills-section" className="mt-4 mb-5">
                      <h5 className="fs-18 fw-bold text-primary">
                        <i className="uil uil-file-bookmark-alt fs-22"></i>{" "}
                        COMPETENCE(S){" "}
                      </h5>
                      <hr />
                      {allSkills?.content?.length > 0 && (
                        <table className="table table-inline mb-0">
                          <tbody>
                            {allSkills.content.map((skill) => (
                              <tr key={skill.id}>
                                <td className="fw-bold text-uppercase">
                                  {skill.fieldOfCompetence}
                                </td>
                                <td>
                                  {skill.competence}&nbsp;&nbsp;
                                  <Rate
                                    disabled
                                    value={skill.experienceLevel.id}
                                    count={4}
                                    style={{ color: "#50D3BB" }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <div
                      id="experience-section"
                      className="candidate-education-details"
                    >
                      <h6 className="fs-18 fw-bold mb-0 text-primary">
                        <i className="uil uil-briefcase fs-22"></i>{" "}
                        EXPERIENCE(S)
                      </h6>
                      <hr />
                      {allExperiences?.content?.length > 0 &&
                        allExperiences.content.map((experience) => (
                          <div key={experience.id} className="mt-4">
                            <Row className="align-items-start gx-0">
                              <Col lg={4}>
                                <p className="mb-1 fw-bold text-primary">
                                  {experience.company}
                                </p>
                                <p className="mb-1 fw-bold text-primary">
                                  {formatDate(experience.startDate)} -{" "}
                                  {formatDate(experience.endDate)}
                                </p>
                              </Col>
                              <Col lg={8}>
                                <h6 className="fs-16 mb-1 text-uppercase fw-bold">
                                  {experience.position}
                                </h6>
                                <p className="text-primary mb-1">Context</p>
                                {experience.context && (
                                  <p className="text-black fs-16">
                                    {experience.context}
                                  </p>
                                )}
                                {experience.actions && (
                                  <>
                                    <p className="text-primary mb-1">
                                      Missions
                                    </p>
                                    <ul className="text-black fs-16">
                                      {experience.actions
                                        .split("\n")
                                        .map((action, index) => (
                                          <li key={index}>{action.trim()}</li>
                                        ))}
                                    </ul>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </div>

                    <div
                      id="education-section"
                      className="candidate-education-details"
                    >
                      <h6 className="fs-18 fw-bold mb-0 text-primary">
                        <i className="uil uil-graduation-cap fs-22"></i>{" "}
                        FORMATION(S)
                      </h6>
                      <hr />
                      {allEducations?.content?.length > 0 &&
                        allEducations.content.map((education) => (
                          <div key={education.id} className="mt-4">
                            <Row className="align-items-start gx-0">
                              <Col lg={4}>
                                <p className="mb-1 fw-bold text-primary">
                                  {education.school}
                                </p>
                                <p className="mb-1 fw-bold text-primary">
                                  {formatDate(education.startDate)} -{" "}
                                  {formatDate(education.endDate)}
                                </p>
                              </Col>
                              <Col lg={8}>
                                <h6 className="fs-16 mb-1 text-uppercase fw-bold">
                                  {education.name}
                                </h6>
                                <p className="text-black fs-16">
                                  {education.summary}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </div>
                    <div
                      id="languages-section"
                      className="candidate-education-details"
                    >
                      <h6 className="fs-18 fw-bold mb-0 text-primary">
                        <i className="uil uil-comments-alt fs-22"></i> LANGUE(S){" "}
                      </h6>
                      <hr />
                      {allLanguages?.content?.length > 0 &&
                        allLanguages.content.map((language) => (
                          <div key={language.id} className="mt-4">
                            <Row className="align-items-start">
                              <Col lg={5}>
                                <p className="mb-1 fw-bold text-uppercase">
                                  {language.language.name}
                                </p>
                              </Col>
                              <Col lg={5} className="text-start">
                                <Rate
                                  disabled
                                  value={getLevelValue(language.level)}
                                  count={3}
                                  style={{ color: "#50D3BB" }}
                                />
                              </Col>
                            </Row>
                          </div>
                        ))}
                    </div>
                  </div>
                  <Row>
                    <Col className="text-end">
                      <button onClick={generatePDF} className="btn btn-danger">
                        <i className="uil uil-import"> </i>
                        Télécharger
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PdfGenerator;
