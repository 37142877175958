import React, {useEffect} from "react";
import {Col, Container, Row} from "reactstrap";
import {useParams} from "react-router-dom";
import Section from "./Section";
import {useDispatch, useSelector} from "react-redux";
import {fetchProposalDetails} from "../../../../../redux/freelance/offer/action";
import RightSideContent from "./RightSideContent";
import JobDetailsDescription from "./JobDetailsDescription";

const ApplyDetails = () => {
    const {slug} = useParams();
    const dispatch = useDispatch();
    document.title = "Candidature Details | RecruTech";

    // Sélectionner les détails de l'offre, de la proposition et l'état de chargement depuis le store
    const {proposalDetails, loading, error} = useSelector((state) => state.offerReducer) || {};

    useEffect(() => {
        if (slug) {
            dispatch(fetchProposalDetails(slug));
        }
        window.scrollTo(0, 0);
    }, [dispatch, slug]);
    // Affichage de l'état de chargement ou des erreurs
    if (loading) return <div>Chargement...</div>;
    if (error) return <div>Erreur: {error}</div>;

    return (
        <React.Fragment>
            <Section/>
            <section className="section">
                <Container>
                    <Row>
                        <Col lg={8}>
                            <JobDetailsDescription offerDetails={ proposalDetails?.data?.offer }/>
                        </Col>
                        <Col lg={4} className="mt-4 mt-lg-0">
                            <RightSideContent proposalDetails={ proposalDetails?.data }/>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default ApplyDetails;
