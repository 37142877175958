import React, {useEffect} from "react";
import {Col, Container, Row} from "reactstrap";
import Section from "./Section";
import MessengerPage from "./MessengerPage";





const Messenger = () => {
    useEffect(() => {
        document.title = "Ma Messagerie | RecruTech";
    }, []);

    return (
        <React.Fragment>
            <Section/>
            <section className="section">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="me-lg-5">
                                    <MessengerPage/>
                                </div>
                            </Col>

                        </Row>
                    </Container>
            </section>
        </React.Fragment>
    );
};
export default Messenger