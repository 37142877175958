import React, { useEffect } from "react";
import { fetchCourseSectionDetails } from "../../../redux/training/action";
import { useDispatch, useSelector } from "react-redux";
import JobDetailsSkeleton from "../../Jobs/JobDetails/JobDetailsSkeleton";
import "./style.css"; // Import du fichier CSS

const BlogText = ({ selectedCourse }) => {
  const dispatch = useDispatch();
  const { selectedDetails, loading, error } = useSelector(
    (state) => state.trainingReducer
  );

  useEffect(() => {
    if (selectedCourse) {
      dispatch(fetchCourseSectionDetails(selectedCourse));
      window.scrollTo(0, 0);
    }
  }, [dispatch, selectedCourse]);

  const parseHTMLContent = (htmlString) => {
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(htmlString, "text/html");

    // Ajouter une classe aux images
    const images = parsedHTML.querySelectorAll("img");
    images.forEach((img) => img.classList.add("blog-image"));

    return { __html: parsedHTML.body.innerHTML };
  };

  if (loading) {
    return <JobDetailsSkeleton />;
  }

  if (error) {
    return (
      <p className="text-danger">Erreur lors du chargement des détails.</p>
    );
  }

  if (!selectedDetails) {
    return <p>Veuillez sélectionner un cours pour voir les détails.</p>;
  }

  return (
    <div className="mt-1 pt-2">
      <div className="ms-lg-4 mt-1 mt-lg-0">
        <div className="blog-post mb-5">
          {/* Titre */}
          <div className="text-center mb-4">
            <h5 className="text-center mb-1">{selectedDetails.title}</h5>
          </div>

          {/* Description */}
          {selectedDetails.description &&
            selectedDetails.description !== "N/A" && (
              <p className="text-muted">{selectedDetails.description}</p>
            )}

          {/* Affichage dynamique en fonction de typeFile */}
          {selectedDetails.linkFile && (
            <div className="text-center mb-4">
              {selectedDetails.typeFile === "MOVIE" ? (
                <video controls width="100%" className="blog-video">
                  <source src={selectedDetails.linkFile} type="video/mp4" />
                  Votre navigateur ne supporte pas la lecture de vidéos.
                </video>
              ) : selectedDetails.typeFile === "IMAGE" ? (
                <img
                  src={selectedDetails.linkFile}
                  alt="Illustration"
                  className="blog-image img-fluid"
                />
              ) : null}
            </div>
          )}

          {/* Contenu formaté */}
          {selectedDetails.content && (
            <div
              dangerouslySetInnerHTML={parseHTMLContent(
                selectedDetails.content
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogText;
