import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import JobDetailsDescription from "./JobDetailsDescription";
import RightSideContent from "./RightSideContent";
import { useParams } from "react-router-dom";
import Section from "./Section";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "react-lottie";
import JobVacancyPost from "./JobVacancyPost";
import * as animationData from "../../../../assets/loading.json";
import JobDetailsSkeleton from "./JobDetailsSkeleton";
import { fetchOfferDetails } from "../../../../redux/freelance/offer/action";

const MissionDetails = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  document.title = "Mission Details | RecruTech";

  // Gérer l'état de chargement
  const [loading, setLoading] = useState(true);

  // Sélectionner les détails de l'offre et les erreurs depuis le store
  const { offerDetails,  error, proposalDetails } = useSelector((state) => state.offerReducer); // Mise à jour du reducer

  useEffect(() => {
    if (slug) {
      dispatch(fetchOfferDetails(slug)); // Mise à jour de l'action
    }
    window.scrollTo(0, 0);
  }, [dispatch, slug]);

  // Gérer l'état de chargement en fonction des données ou des erreurs
  useEffect(() => {
    if (offerDetails?.data || error) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [offerDetails, error]);

  // Paramètres pour l'animation Lottie de chargement
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            {loading ? (
              <Col lg={12}>
                <div className="mt-5">
                  <JobDetailsSkeleton />
                </div>
              </Col>
            ) : error ? (
              <div className="text-center mt-5">
                <Lottie options={defaultOptions} height={300} width={300} />
                <p className="text-danger mt-3">
                  Une erreur s'est produite : {error}
                </p>
              </div>
            ) : (
              <>
                <Col lg={8}>
                  <JobDetailsDescription offerDetails={offerDetails?.data} />
                  {offerDetails?.data && offerDetails?.data.description && (
                      < JobVacancyPost offerDetails={offerDetails?.data} />
                  )}
                </Col>
                <Col lg={4} className="mt-4 mt-lg-0">
                  <RightSideContent offerDetails={offerDetails?.data}/>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default MissionDetails;
