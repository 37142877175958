import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

// Import Images
import JobDetailImage from "../../../../../assets/images/job-detail.jpg";
import { useDispatch, useSelector } from "react-redux";
import { fetchLanguageTypes } from "../../../../../redux/profile/action";
import { fetchJobCategories } from "../../../../../redux/jobCategory/action";
import { renderHTMLContent } from "../../../../../utils";

const JobDetailsDescription = ({ proposalDetails }) => {
  const dispatch = useDispatch();
  const [isCoverLoaded, setIsCoverLoaded] = useState(false);
  const { languageTypes } = useSelector((state) => state.profileReducer);

  // Level Translations
  const levelTranslations = {
    Beginner: "Débutant",
    Intermediate: "Intermédiaire",
    Expert: "Avancé",
    // Add more levels if necessary
  };
  useEffect(() => {
    dispatch(fetchLanguageTypes());
    dispatch(fetchJobCategories());
  }, [dispatch]);

  // If proposalDetails?.offe is undefined or not fully loaded, return a loading state
  if (!proposalDetails) {
    return <div>Chargement des détails de l'offre...</div>;
  }

  // Handle image loading for cover photo
  const handleCoverImageLoad = () => {
    setIsCoverLoaded(true);
  };

  return (
    <React.Fragment>
      <Card className="job-detail overflow-hidden">
        <div>
          <img
            src={proposalDetails.enterprise_coverPhoto || JobDetailImage}
            alt="Cover"
            width="100%"
            height={80}
            className="img-fluid"
            onLoad={handleCoverImageLoad} // Triggered after cover image load
            style={isCoverLoaded ? { display: 'block' } : { display: 'none' }}
          />
          {/* The logo is shown only after the cover image is loaded */}
          {isCoverLoaded && (
            <div className="job-details-compnay-profile">
              <img
                src={
                  proposalDetails.enterprise_logo ||
                  "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                }
                alt="Company Logo"
                width="100"
                height="100"
                style={{ border: "2px solid black", borderRadius: "10px" }}
                className="img-fluid rounded-3"
              />
            </div>
          )}
        </div>
        <CardBody className="p-4">
          <div>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={8}>
                    <h5 className="mb-1">{proposalDetails.title}</h5>
                  </Col>
                  <Col md={4}>
                    <ul className="list-inline text-end mb-0">
                      <li className="list-inline-item text-muted">
                        <i className="mdi mdi-account-multiple"></i>{" "}
                        {proposalDetails.position_available} Poste
                        {proposalDetails.position_available > 1 ? "s" : ""}
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div className="mt-4">
            <Row className="g-2">
              <Col lg={6}>
                <div className="border rounded-start p-3">
                  <p className="text-black mb-0 fs-13">
                    Année(s) d'experience(s) :
                    <span className="fs-12 fw-bold">
                      {" "}
                      {proposalDetails.experience}
                    </span>
                  </p>
                </div>
              </Col>
              <Col lg={6}>
                <div className="border rounded-start p-3">
                  <p className="text-black mb-0 fs-13">
                    Durée de la mission :
                    <span className="fs-12 fw-bold">
                      {" "}
                      {proposalDetails.duration} Jour(s)
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="mt-4">
            <h5 className="mb-3 text-danger">Description de l'offre</h5>
            <div
              className="job-detail-desc"
              dangerouslySetInnerHTML={renderHTMLContent(proposalDetails.description)}
            />
          </div>

          <div className="mt-4">
            <h5 className="mb-3 text-danger">Qualification</h5>
            <div className="job-detail-desc mt-2">
              <div
                dangerouslySetInnerHTML={renderHTMLContent(proposalDetails.qualification)}
              />
            </div>
          </div>

          <div className="mt-4">
            <h5 className="mb-3 text-danger">Responsabilité</h5>
            <div
              className="job-detail-desc"
              dangerouslySetInnerHTML={renderHTMLContent(proposalDetails.responsibility)}
            />
          </div>

          <div className="mt-4">
            <h5 className="mb-3 text-danger">Compétences</h5>
            <div className="job-details-desc">
              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
                  {proposalDetails.skills?.map((skill, index) => (
                    <span key={index} className="badge bg-secondary">
                      {skill.skill.name}- {levelTranslations[skill.required_level]}
                    </span>
                  ))}
                </div>
              </ul>
            </div>
          </div>

          <div className="mt-4">
            <h5 className="mb-3 text-danger">Langues parlées</h5>
            <div className="job-details-desc">
              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                {proposalDetails.language_id?.length > 0 && (
                  <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
                    {proposalDetails.language_id.map((language_id, index) => {
                      const language = languageTypes.find(
                        (lang) => lang.id === language_id
                      );
                      return (
                        language && (
                          <span key={index} className="badge bg-secondary">
                            {language.name}
                          </span>
                        )
                      );
                    })}
                  </div>
                )}
              </ul>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default JobDetailsDescription;
