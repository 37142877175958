import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Card, CardBody, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { fetchSimilarOffers } from "../../../../redux/freelance/offer/action";
import { useDispatch, useSelector } from "react-redux";
import {
  formatAddress,
  renderHTMLContent,
  sliceDescription,
} from "../../../../utils";
import "./jobVacancySlider.css";

const JobVacancyPost = ({ offerDetails }) => {
  const dispatch = useDispatch();
  const { similarOffers } = useSelector((state) => state.offerReducer);
  const [, setActiveSlide] = useState(0);
  useEffect(() => {
    if (offerDetails) {
      dispatch(fetchSimilarOffers(offerDetails.slug));
    }
  }, [dispatch, offerDetails]);

  // Configuration améliorée du carrousel
  const settings = {
    dots: true,
    infinite: similarOffers?.data?.length > 2,
    speed: 700,
    slidesToShow: similarOffers?.data?.length > 1 ? 2 : 1,
    slidesToScroll: similarOffers?.data?.length > 1 ? 2 : 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: similarOffers?.data?.length > 1 ? 2 : 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Container className="slider-container">
      {similarOffers?.data?.length > 0 ? (
        <div className="similar-offers-slider">
          <Slider {...settings}>
            {similarOffers?.data.map((offer) => (
              <div key={offer.slug} className="slider-card-wrapper px-2">
                <Link
                  to={{ pathname: `/mission-Details/${offer.slug}` }}
                  className="primary-link"
                >
                  <Card
                    className="blog-masonry-box shadow overflow-hidden border-0 p-2 h-100"
                    style={{ minHeight: "300px" }}
                  >
                    <CardBody className="p-4">
                      <div className="d-flex align-items-center mt-1 mb-2">
                        <div className="flex-shrink-0">
                          <img
                            src={
                              offer.enterprise_logo ||
                              "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                            }
                            alt="Enterprise Logo"
                            className="avatar-xs rounded-circle"
                          />
                        </div>
                        <div className="ms-3">
                          <h6 className="text-muted fs-10 mb-0">
                            {offer.enterprise_name}
                          </h6>
                        </div>
                      </div>
                      <h6 className="fs-14 mb-0">{offer.title}</h6>
                      <ul className="list-inline text-muted mb-0">
                        <li className="list-inline-item">
                          <i className="mdi mdi-account"></i>{" "}
                          {offer.positionAvailable} Poste
                          {offer.positionAvailable > 1 ? "s" : ""} à pourvoir
                        </li>
                      </ul>
                      <div className="mt-3">
                        <h5 className="mb-3">Description de l'offre</h5>
                        <div
                          className="job-detail-desc"
                          dangerouslySetInnerHTML={renderHTMLContent(
                            sliceDescription(offer.description, 20)
                          )}
                        />
                      </div>
                      <div className="row d-flex align-items-start mt-3">
                        <div className="col-6">
                          <div className="ms-0">
                            {offer.skills
                              ?.slice(0, 3)
                              .map((skillItem, index) => (
                                <span
                                  key={index}
                                  className="badge bg-info-subtle text-info fs-10 me-2"
                                >
                                  {skillItem.skill.name}
                                </span>
                              ))}
                            {offer.skills?.length > 3 && (
                              <span className="badge bg-info-subtle text-info fs-10 me-2">
                                ...
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="ms-3">
                            <p className="text-muted">
                              <i className="mdi mdi-map-marker"></i>{" "}
                              {offer.address
                                ? formatAddress(offer.address)
                                : "Non renseigné"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="text-muted">
                          <i className="mdi mdi-calendar"></i> Délai de
                          candidature: {offer.deadline}
                        </p>
                        <p className="text-muted">
                          Budget: {offer.budget} {offer.devise}
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <div className="text-center mt-4">
          <p>Aucune offre similaire trouvée.</p>
        </div>
      )}
    </Container>
  );
};

export default JobVacancyPost;
