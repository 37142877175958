import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, Card, CardBody } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import TokenService from "../../../service/TokenService"; // Assurez-vous d'importer votre service de token
import { fetchCandidateInfoPerso } from "../../../redux/profile/action";
import { applyJob } from "../../../redux/job/action";
import { Tooltip } from "antd";
import "./JobDetails.css"; // Assurez-vous d'importer le fichier CSS

const RightSideContent = ({ selectedRightDetails }) => {
  const [user, setUser] = useState(null);
  const [modal, setModal] = useState(false);
  const openModal = () => {
    setModal(true);
    dispatch(fetchCandidateInfoPerso()); // Appel lors de l'ouverture du modal
  };
  const closeModal = () => setModal(false);
  const dispatch = useDispatch();

  const { authUser } = useSelector(({ auth }) => auth);
  const { infoPersoDetails } = useSelector((state) => state.profileReducer);
  useEffect(() => {
    const getUser = TokenService.getUser();
    if (getUser) {
      setUser(getUser);
    }
    // dispatch(fetchCandidateInfoPerso());
    window.scrollTo(0, 0);
  }, [dispatch]);

  const onSubmit = () => {
    if (authUser && authUser.isLoggedIn) {
      if (infoPersoDetails && infoPersoDetails.data.urlCV) {
        dispatch(applyJob(selectedRightDetails?.id));
        closeModal();
      } else {
        toast.warning("Veuillez mettre à jour votre profil pour continuer", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      toast.warning("Connectez-vous pour continuer", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="side-bar ms-lg-4">
        <Card className="job-overview">
          <CardBody className="p-4">
            <h6 className="fs-17">Aperçu du poste</h6>
            <ul className="list-unstyled mt-4 mb-0">
              <li>
                <div className="d-flex mt-4">
                  <i className="mdi mdi-calendar-clock icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Delai de candidature</h6>
                    <p className="text-muted mb-0">
                      {selectedRightDetails?.lastApplicationDate &&
                        new Date(
                          selectedRightDetails?.lastApplicationDate
                        ).toLocaleDateString("fr-FR", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-star-half-alt icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Experience</h6>
                    <p className="text-muted mb-0">
                      {selectedRightDetails?.experience} Ans
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-location-point icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Lieu</h6>
                    <p className="text-muted mb-0">
                      {selectedRightDetails?.address?.city},{" "}
                      {selectedRightDetails?.address?.country}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-history icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Date de publication</h6>
                    <p className="text-muted mb-0">
                      {selectedRightDetails?.postingDate &&
                        new Date(
                          selectedRightDetails?.postingDate
                        ).toLocaleDateString("fr-FR", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-3">
              <Tooltip
                title={
                  user === null ? "Veuillez vous connecter pour poursuivre" : ""
                }
                color="red"
              >
                <button
                  id="applyButton"
                  onClick={user !== null ? openModal : undefined}
                  className={`btn btn-primary btn-hover w-100 mt-2`}
                >
                  Candidater <i className="uil uil-arrow-right"></i>
                </button>
              </Tooltip>
            </div>
          </CardBody>
        </Card>

        <Card className="company-profile mt-4">
          <CardBody className="p-4">
            <div className="text-center">
              <img
                src={
                  selectedRightDetails?.company?.logo ||
                  "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                }
                width="50"
                height="50"
                alt=""
                style={{ border: "2px solid black", borderRadius: "10px" }}
                className="img-fluid rounded-3"
              />

              <div className="mt-4">
                <h6 className="fs-17 mb-1">
                  {selectedRightDetails?.company?.name}
                </h6>
                <p className="text-muted">Depuis juillet 2017</p>
              </div>
            </div>
            <ul className="list-unstyled mt-4">
              <li>
                <div className="d-flex">
                  <i className="uil uil-phone-volume text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Phone</h6>
                    <p className="text-muted fs-14 mb-0">
                      {selectedRightDetails?.company?.telephone}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="uil uil-envelope text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Email</h6>
                    <p className="text-muted fs-14 mb-0">
                      {selectedRightDetails?.company?.email}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="uil uil-globe text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Website</h6>
                    <p className="text-muted fs-14 text-break mb-0">
                      {selectedRightDetails?.company?.website}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="uil uil-map-marker text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Localisation</h6>
                    <p className="text-muted fs-14 mb-0">
                      {`${selectedRightDetails?.company?.address?.city}, ${selectedRightDetails?.company?.address?.country}`}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-4">
              <Link
                to={{
                  pathname: `/companydetails/${selectedRightDetails?.company?.id}`,
                }}
                className="btn btn-primary btn-hover w-100 rounded"
              >
                <i className="mdi mdi-eye"></i> Voir le Profile
              </Link>
            </div>
          </CardBody>
        </Card>

        {/* <div className="mt-4">
          <h6 className="fs-16 mb-3">Lieu de travail</h6>
          <iframe
            title="maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15830869428!2d-74.119763973046!3d40.69766374874431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1628067715234!5m2!1sen!2sin"
            style={{ width: `100%`, height: `250px` }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div> */}
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal isOpen={modal} toggle={openModal} centered>
              <ModalBody className="modal-body p-5">
                <div className="text-center mb-4">
                  <h5 className="modal-title mb-1" id="staticBackdropLabel">
                    Candidater avec mon profil{" "}
                  </h5>
                  <p className="text-muted mb-0 fs-15">
                    {selectedRightDetails?.title}
                  </p>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="btn-close"
                    aria-label="Close"
                  ></button>
                </div>

                <button
                  type="button"
                  onClick={onSubmit}
                  className="btn btn-primary w-100"
                >
                  Postuler
                </button>

                {/* Ajout du message pour mettre à jour le profil */}
                <div className="text-center mt-3">
                  <p className="text-muted mb-0">
                    Vous n'avez pas encore mis à jour votre profil ?
                  </p>
                  <a
                    href="/myprofile"
                    className="btn btn-link text-primary fw-semibold"
                  >
                    Mettre à jour mon profil
                  </a>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

export default RightSideContent;
