import * as AuthActionTypes from "../types";
import authService from "../../../service/auth";
import TokenService from "../../../service/TokenService";
import { clearMessage, setMessage } from "../../message/action";
import { toast } from "react-toastify"; // Ajoutez cette ligne

export const clearRegister = () => async (dispatch) => {
  dispatch({ type: AuthActionTypes.CLEAR_REGISTER });
};

export const signInAction =
  ({ username, password }) =>
  async (dispatch) => {
    dispatch({ type: AuthActionTypes.SIGNIN_USER_REQUEST });

    try {
      const response = await authService.signIn(username, false, password);

      if (response.status === 200 && response.data) {
        const { data: currentUser } = await authService.getCurrentUser(
          response.data.data.id_token
        );
        // Vérification du rôle de l'utilisateur
        const userRoles = currentUser.authorities || [];

        if (
          userRoles.includes("ROLE_CANDIDATE") ||
          userRoles.includes("ROLE_BENEFICIARY_ACADEMY")
        ) {
          const payload = { ...response.data, ...currentUser };
          TokenService.setUser(payload);

          dispatch({ type: AuthActionTypes.SIGNIN_USER_SUCCESS, payload });
          // window.location.reload();
          return { success: true };
        } else {
          console.warn("Accès refusé pour cet utilisateur:", userRoles);
          dispatch({ type: AuthActionTypes.SIGNIN_USER_ERROR });
          dispatch(setMessage("Accès refusé"));
          return { success: false };
        }
      } else {
        console.warn("Échec de la connexion: réponse invalide", response);
        dispatch({ type: AuthActionTypes.SIGNIN_USER_ERROR });
        dispatch(setMessage("Echec de connexion"));
        return { success: false };
      }
    } catch (error) {
      let message =
        error.response?.data?.message || error.message || error.toString();
      const errorCode = error?.response?.data?.errorCode;

      dispatch({ type: AuthActionTypes.SIGNIN_USER_ERROR, payload: errorCode });

      if (errorCode === "USER_NOT_ACTIVATED") {
        dispatch(resendOtp(username, password));
      } else {
        if (errorCode === "BadCredentialsException") {
          message = "Echec de connexion";
        }
        dispatch(setMessage(message));
      }

      return { success: false };
    }
  };

export const socialSignInAction =
  ({ email }) =>
  async (dispatch) => {
    dispatch({ type: AuthActionTypes.SIGNIN_USER_REQUEST });
    dispatch(clearMessage());
    try {
      const password =
        email + ':B!P26W*;v5$gyqbNz%xEGA@/n{fs"X]:C.a`=jd-9[_7HQr4hD';

      const response = await authService.signIn(email, false, password);

      if (response.status === 200 && response.data) {
        const { data: currentUser } = await authService.getCurrentUser(
          response.data.data.id_token
        );
        // Vérification du rôle de l'utilisateur
        const userRoles = currentUser.authorities || [];

        if (
          userRoles.includes("ROLE_CANDIDATE") ||
          userRoles.includes("ROLE_BENEFICIARY_ACADEMY")
        ) {
          const payload = { ...response.data, ...currentUser };
          TokenService.setUser(payload);
          // dispatch({ type: AuthActionTypes.SIGNIN_USER_SUCCESS, payload });
          localStorage.removeItem("Provider");
          localStorage.removeItem("AuthAction");
          window.location.href = "/"; // 🔹 Rediriger vers l'accueil après connexion réussie
        } else {
          console.warn("Accès refusé pour cet utilisateur:", userRoles);
          dispatch({ type: AuthActionTypes.SIGNIN_USER_ERROR });
          dispatch(setMessage("Accès refusé"));
        }
      } else {
        console.warn("Échec de la connexion: réponse invalide", response);
        dispatch({ type: AuthActionTypes.SIGNIN_USER_ERROR });
        dispatch(setMessage("Echec de connexion"));
      }
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode;

      dispatch({
        type: AuthActionTypes.SIGNIN_USER_ERROR,
        payload: errorCode,
      });
    }
  };

export const linkedinAuthAction = () => async (dispatch) => {
  dispatch({ type: AuthActionTypes.SOCIAL_LOGIN_REQUEST });

  try {
    const response = await authService.linkedinAuth();
    if (response && response.data && response.data.data.link) {
      // Redirection vers le lien de connexion LinkedIn
      window.location.href = response.data.data.link;
    } else {
      console.warn("Échec de la connexion: réponse invalide", response);
      dispatch({ type: AuthActionTypes.SOCIAL_LOGIN_ERROR });
      dispatch(setMessage("Connexion avec linkedin impossible"));
      return { success: false };
    }
  } catch (error) {
    const errorCode = error?.response?.data?.errorCode;
    dispatch({
      type: AuthActionTypes.SOCIAL_LOGIN_ERROR,
      payload: errorCode,
    });
    return { success: false };
  }
};
export const googleAuthAction = () => async (dispatch) => {
  dispatch({ type: AuthActionTypes.SOCIAL_LOGIN_REQUEST });

  try {
    const response = await authService.googleAuth();
    if (response && response.data && response.data.data.link) {
      // Redirection vers le lien de connexion LinkedIn
      window.location.href = response.data.data.link;
    } else {
      console.warn("Échec de la connexion: réponse invalide", response);
      dispatch({ type: AuthActionTypes.SOCIAL_LOGIN_ERROR });
      dispatch(setMessage("Connexion avec google impossible"));
      return { success: false };
    }
  } catch (error) {
    const errorCode = error?.response?.data?.errorCode;
    dispatch({
      type: AuthActionTypes.SOCIAL_LOGIN_ERROR,
      payload: errorCode,
    });
    return { success: false };
  }
};
export const signUpAction =
  ({ email, password, firstName, lastName, customerType }) =>
  async (dispatch) => {
    dispatch({ type: AuthActionTypes.SIGNUP_USER_REQUEST });
    dispatch(clearMessage());
    try {
      const payload = {
        login: email, // Utilisez l'email comme login
        firstName: firstName,
        lastName: lastName,
        email: email,
        langKey: "fr", // Langue par défaut définie sur "fr"
        authorities: ["ROLE_CANDIDATE"], // Autorité par défaut pour le candidat
        password: password,
        customerType: customerType,
      };

      const { data } = await authService.signUp(payload);
      if (data.statusCode === 200) {
        dispatch({
          type: AuthActionTypes.SIGNUP_USER_SUCCESS,
          payload: { email, password },
        });
        dispatch(setMessage("Votre compte a été crée avec succès"));
      } else {
        dispatch({ type: AuthActionTypes.SIGNUP_USER_ERROR });
        dispatch(setMessage("Erreur lors de la création de votre compte"));
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.tostring();
      dispatch({ type: AuthActionTypes.SIGNUP_USER_ERROR });
      dispatch(setMessage(message));
    }
  };

export const socialSignUpAction =
  ({ firstName, lastName, email, customerType, accessToken }) =>
  async (dispatch) => {
    dispatch({ type: AuthActionTypes.SOCIAL_SIGNUP_REQUEST });
    dispatch(clearMessage());
    try {
      const password =
        email + ':B!P26W*;v5$gyqbNz%xEGA@/n{fs"X]:C.a`=jd-9[_7HQr4hD';
      const payload = {
        login: email, // Utilisez l'email comme login
        firstName: firstName,
        lastName: lastName,
        email: email,
        langKey: "fr", // Langue par défaut définie sur "fr"
        authorities: ["ROLE_CANDIDATE"], // Autorité par défaut pour le candidat
        password: password,
        customerType: customerType,
      };

      const { data } = await authService.signUp(payload, accessToken);
      if (data.statusCode === 200) {
        dispatch({
          type: AuthActionTypes.SOCIAL_SIGNUP_SUCCESS,
          payload: { email, password },
        });
        dispatch(setMessage("Votre compte a été crée avec succès"));
        const result = await dispatch(
          signInAction({ username: email, password })
        );
        if (result?.success) {
          window.location.href = "/"; // 🔹 Rediriger vers l'accueil après connexion réussie
        }
        localStorage.removeItem("Provider");
        localStorage.removeItem("AuthAction");
      } else {
        dispatch({ type: AuthActionTypes.SOCIAL_SIGNUP_ERROR });
        dispatch(setMessage("Erreur lors de la création de votre compte"));
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.tostring();
      dispatch({ type: AuthActionTypes.SIGNUP_USER_ERROR });
      dispatch(setMessage(message));
    }
  };

export const activateAccount = (otpNumber) => async (dispatch) => {
  const email = localStorage.getItem("email");
  const password = localStorage.getItem("password");

  if (!email || !password) {
    toast.error("Informations de connexion manquantes.");
    return { success: false };
  }

  dispatch({ type: AuthActionTypes.OTP_USER_REQUEST });
  dispatch(clearMessage());

  try {
    const { data } = await authService.activateAccount(email, otpNumber);
    if (data.statusCode === 200) {
      dispatch({ type: AuthActionTypes.OTP_USER_SUCCESS });
      toast.success("Vérification effectuée avec succès");
      // Connexion automatique
      try {
        const loginResponse = await dispatch(
          signInAction({ username: email, password: password })
        );

        if (loginResponse && loginResponse.success) {
          // Nettoyage des données stockées
          localStorage.removeItem("email");
          localStorage.removeItem("password");

          return { success: true }; // Succès total
        } else {
          toast.error(
            "Connexion automatique échouée. Veuillez vous connecter manuellement."
          );
          return { success: false }; // Échec de la connexion
        }
      } catch (loginError) {
        toast.error(
          "Erreur lors de la connexion automatique. Veuillez vous connecter manuellement."
        );
        return { success: false };
      }
    } else {
      dispatch({ type: AuthActionTypes.OTP_USER_ERROR });
      toast.error("Le code de vérification est incorrect");
      return { success: false };
    }
  } catch (error) {
    const message =
      error.response?.data?.message || error.message || error.toString();

    dispatch({ type: AuthActionTypes.OTP_USER_ERROR });
    dispatch(setMessage(message));

    toast.error(message + " ! Le code de vérification est incorrect");
    return { success: false };
  }
};

export const resendOtp = () => async (dispatch) => {
  const email = localStorage.getItem("email");
  dispatch({ type: AuthActionTypes.OTP_RESEND_REQUEST });
  dispatch(clearMessage());

  try {
    const response = await authService.resendOtp(email);
    // Vérifiez si response et response.data existent
    if (response && response.data) {
      if (response.data.statusCode === 200) {
        dispatch({ type: AuthActionTypes.OTP_RESEND_SUCCESS });
        toast.success("Code renvoyé! Veuillez vérifier");
      } else {
        dispatch({ type: AuthActionTypes.OTP_RESEND_ERROR });
        toast.error("Erreur lors du renvoi du code de vérification");
      }
    } else {
      // Gestion des cas où response.data est undefined
      dispatch({ type: AuthActionTypes.OTP_RESEND_ERROR });
      toast.error("Erreur de réponse du serveur");
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.tostring();
    dispatch({
      type: AuthActionTypes.OTP_USER_ERROR,
      payload: error.response ? error.response.data.errorCode : "UNKNOWN_ERROR",
    });
    dispatch(setMessage(message));
  }
};

export const resetPasswordInit = (email) => async (dispatch) => {
  dispatch({ type: AuthActionTypes.OTP_USER_REQUEST });
  dispatch(clearMessage());

  try {
    const { data } = await authService.resetPasswordInit(email);

    if (data.statusCode === 200) {
      dispatch({ type: AuthActionTypes.OTP_USER_SUCCESS });
      toast.success("Vérification effectuée avec succès");

      return { success: true }; // Indiquer le succès
    } else {
      dispatch({ type: AuthActionTypes.OTP_USER_ERROR });
      toast.error("L'email' est incorrecte Veuillez vérifier");
      return { success: false }; // Indiquer l'échec
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.tostring();
    dispatch({ type: AuthActionTypes.OTP_USER_ERROR });
    toast.error(message);
    return { success: false }; // Indiquer l'échec
  }
};

export const resetPasswordFinish =
  (otpNumber, newPassword) => async (dispatch) => {
    dispatch({ type: AuthActionTypes.OTP_USER_REQUEST });
    dispatch(clearMessage());

    try {
      const { data } = await authService.resetPasswordFinish(
        otpNumber,
        newPassword
      );
      console.log("reset password", data);
      if (data.statusCode === 200) {
        dispatch({ type: AuthActionTypes.OTP_USER_SUCCESS });
        toast.success("Mot de passe mise à jour avec succès");

        return { success: true }; // Indiquer le succès
      } else {
        dispatch({ type: AuthActionTypes.OTP_USER_ERROR });
        toast.error("Code invalide Veuillez vérifier");
        return { success: false }; // Indiquer l'échec
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.tostring();
      dispatch({ type: AuthActionTypes.OTP_USER_ERROR });
      toast.error(message);
      return { success: false }; // Indiquer l'échec
    }
  };

export const signOutAction = () => async (dispatch) => {
  dispatch({ type: AuthActionTypes.SIGNOUT_USER_REQUEST });
  try {
    authService.signOut();
    dispatch({ type: AuthActionTypes.SIGNOUT_USER_SUCCESS });
  } catch (err) {
    dispatch({ type: AuthActionTypes.SIGNOUT_USER_ERROR, err });
  }
};

export const clearError = () => (dispatch) => {
  dispatch({ type: AuthActionTypes.CLEAR_ERROR });
};

const AuthService = {
  signUpAction,
  signInAction,
  signOutAction,
};
export default AuthService;
