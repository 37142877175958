import * as AuthActionTypes from "../types";
import TokenService from "../../../service/TokenService";

const user = TokenService.getUser();

const INITIAL_STATE = {
  isLoading: false,
  authUser: user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null },
  authError: false,
  signUpError: false,
  successful: false,
  otpVerif: false,
  username: null,
  password: null,
  errorCode: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthActionTypes.CLEAR_REGISTER:
      return {
        ...state,
        isLoading: false,
        otpVerif: false,
        successful: false,
      };
    //otp resend
    case AuthActionTypes.OTP_RESEND_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorCode: null,
      };
    case AuthActionTypes.OTP_RESEND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        username: action.payload.username,
        password: action.payload.password,
        errorCode: null,
      };
    case AuthActionTypes.OTP_RESEND_ERROR:
      return {
        ...state,
        isLoading: false,
        errorCode: action.payload,
      };

    // otp verif
    case AuthActionTypes.OTP_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        otpVerif: false,
      };
    case AuthActionTypes.OTP_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        otpVerif: true,
      };
    case AuthActionTypes.OTP_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        authUser: {
          ...state.authUser,
          isLoggedIn: false,
          user: null,
        },
        otpVerif: false,
      };

    case AuthActionTypes.CURRENT_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case AuthActionTypes.CURRENT_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        authUser: {
          ...state.authUser,
          user: action.payload,
        },
      };
    case AuthActionTypes.CURRENT_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        authUser: {
          ...state.authUser,
          isLoggedIn: false,
          user: null,
        },
        successful: false,
      };
    // sign up actions
    case AuthActionTypes.SIGNUP_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        signUpError: false,
        successful: false,
      };
    case AuthActionTypes.SIGNUP_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successful: true,
        username: action.payload.login,
        password: action.payload.password,
        signUpError: false,
      };
    case AuthActionTypes.SIGNUP_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        authUser: null,
        signUpError: true,
        successful: false,
      };
    // social signUp
    case AuthActionTypes.SOCIAL_SIGNUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        signUpError: false,
      };
    case AuthActionTypes.SOCIAL_SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        username: action.payload.email,
        signUpError: false,
      };
    case AuthActionTypes.SOCIAL_SIGNUP_ERROR:
      return {
        ...state,
        isLoading: false,
        authUser: null,
        signUpError: true,
      };

    // sign in actions
    case AuthActionTypes.SIGNIN_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        authError: false,
        errorCode: null,
      };
    case AuthActionTypes.SIGNIN_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        authUser: {
          ...state.authUser,
          isLoggedIn: true,
          user: action.payload,
          authError: false,
        },
      };
    case AuthActionTypes.SIGNIN_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        authUser: {
          ...state.authUser,
          isLoggedIn: false,
          user: null,
        },
        authError: true,
        errorCode: action.payload,
      };
    // socila login
    case AuthActionTypes.SOCIAL_LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AuthActionTypes.SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case AuthActionTypes.SOCIAL_LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    // sign out actions
    case AuthActionTypes.SIGNOUT_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        authUser: {
          ...state.authUser,
          isLoggedIn: false,
          user: null,
        },
      };
    case AuthActionTypes.SIGNOUT_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        authUser: {
          ...state.authUser,
          isLoggedIn: false,
          user: null,
        },
      };
    case AuthActionTypes.SIGNOUT_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        authError: true,
      };
    case AuthActionTypes.CLEAR_ERROR:
      return {
        ...state,
        isLoading: false,
        authError: false,
      };
    default:
      return state;
  }
};

export default authReducer;
