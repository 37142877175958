import React from "react";
import { Link} from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
/*import TokenService from "../../../../service/TokenService";*/

const Section = () => {
  /*const getUser = TokenService.getUser();*/
  /*const location = useLocation();*/


  return (
    <React.Fragment>
      <section className="page-title-box">
        <Container className="container">
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="text-center text-white">
                <h3 className="mb-4">Mes Contrats Freelance</h3>
                <div className="page-next">
                  <nav className="d-inline-block" aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <Link to="/">Accueil</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/freelance-jobs">Mes Contrats</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Mes Contrats
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
