import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";

const Pagination = ({
  currentPage,
  totalPages,
  fetchData,
  setCurrentPage,
  firstCardRef,
  companyId,
}) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const goToPage = (page) => {
    setCurrentPage(page - 1);
    fetchData(page - 1);
    scrollToFirstCard();
  };

  const scrollToFirstCard = () => {
    if (firstCardRef && firstCardRef.current) {
      firstCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const renderPaginationLinks = () => {
    const links = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      links.push(
        <li
          key={i}
          className={`page-item ${currentPage === i - 1 ? "active" : ""}`}
        >
          <Link
            className="page-link"
            to={{
              pathname: currentPath,
              search: `?companyId=${companyId ? companyId : ""}`,
            }}
            onClick={() => goToPage(i)}
          >
            {i}
          </Link>
        </li>
      );
    }

    return links;
  };

  return (
    <Row>
      <Col lg={12} className="mt-0 mb-3 pt-2">
        <nav aria-label="Page navigation example">
          <ul className="pagination job-pagination mb-0 justify-content-center">
            {/* Lien vers la première page */}
            <li className={`page-item ${currentPage === 0 ? "disabled" : ""}`}>
              <Link
                className="page-link"
                to={{
                  pathname: currentPath,
                  search: `?companyId=${companyId}`,
                }}
                onClick={() => goToPage(1)}
              >
                <i className="mdi mdi-chevron-double-left fs-15"></i>
              </Link>
            </li>

            {renderPaginationLinks()}

            {/* Lien vers la dernière page */}
            <li
              className={`page-item ${
                currentPage === totalPages - 1 ? "disabled" : ""
              }`}
            >
              <Link
                className="page-link"
                to={{
                  pathname: currentPath,
                  search: `?companyId=${companyId}`,
                }}
                onClick={() => goToPage(totalPages)}
              >
                <i className="mdi mdi-chevron-double-right fs-15"></i>
              </Link>
            </li>
          </ul>
        </nav>
      </Col>
    </Row>
  );
};

export default Pagination;
