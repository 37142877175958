// service/freelance/OfferService.js
import apiService from "../../ApiFreelance";
import TokenService from "../../../service/TokenService"; // Assurez-vous d'importer votre service de token
import authHeader from "../../../service/auth/auth-header";

// Fonction pour récupérer toutes les offres
const fetchAllOffers = async () => {
    return await apiService.get("/offer/published-offers/");
};

// Fonction pour récupérer les offres par page
const fetchOffersByPage = async (
    selectedDateFilter,
    query,
    domaines,
    country,
    experiences,
    profil,
    page = 1
) => {
    const domaineQuery =
        Array.isArray(domaines) && domaines.length > 0
            ? `category_id=${domaines.join(",")}`
            : "";

    const countryQuery =
        Array.isArray(country) && country.length > 0
            ? `country=${country.join(",")}`
            : "";
    const experienceQuery =
        Array.isArray(experiences) && experiences.length > 0
            ? `experience=${experiences.join(",")}`
            : "";

    const queryParams = [
        `search=${query}`,
        `date_filter=${selectedDateFilter}`,
        `page=${page}`,
        `profil=${profil}`,
        domaineQuery,
        countryQuery,
        experienceQuery,
    ]
        .filter(Boolean)
        .join("&");
    return await apiService.get(`/offer/published-offers/?${queryParams}`);
};

// Fonction pour récupérer les détails d'une offre spécifique
const fetchOfferDetails = async (slug) => {
    return await apiService.get(`/offer/${slug}/`);
};

// Fonction pour récupérer les 5 offres similaires en fonction des compétences et de la catégorie
const fetchSimilarOffers = async (slug) => {
    return await apiService.get(`/offer/${slug}/similar-offers/`);
};

// Fonction pour récupérer les offres récentes
const fetchRecentFreelanceJobs = async () => {
    return await apiService.get("/offer/recents/");
};

// Fonction pour récupérer les adresses des offres freelances
const fetchAddressOffers = async () => {
    return await apiService.get("/addressJob/");
};

// Fonction pour récupérer les offres en favories
const fetchFavOffers = async (search, page) => {
    return await apiService.get(`/fav-offer/?page=${page}&search=${search}`);
};

// Fonction pour mettre une offre en favoris
const addOfferInFavorite = async (OfferId) => {
    return await apiService.put(`/fav-offer/${OfferId}/like/`);
};

// Fonction pour retirer une offre des favoris
const removeOfferInFavorite = async (OfferId) => {
    return await apiService.put(`/fav-offer/${OfferId}/unlike/`);
};

//Fonction pour postuler

const applyOffer = async (offer, message, duration, price, devise) => {
    const accessToken = TokenService.getUser();
    const payload = {offer, message, duration, price, devise};

    const response = await apiService.post(`/offer/apply`, payload, {
        headers: authHeader(accessToken.data.id_token),
    });

    return response.data; // Retourne les données de la réponse
};

// Fonction pour récupérer les candidatures de l'utilisateur
const fetchApplications = async (page) => {
    const user = TokenService.getUser();
    if (!user || !user.data || !user.data.id_token) {
        throw new Error("Token d'authentification non trouvé");
    }

    const accessToken = user.data.id_token;

    const response = await apiService.get(`/offer/applies/applications/?page=${page}`, {
            headers: authHeader(accessToken),
        }
    );
    return response;
};

// Fonction pour annuler une candidature
const removeApplication = async (slug) => {
  if (!slug) {
    throw new Error("Le slug de la candidature est manquant.");
  }

  const accessToken = TokenService.getUser();

  try {
    const response = await apiService.delete(
      `/offer/apply/${slug}/remove-apply/`,
      {
        headers: authHeader(accessToken.data.id_token),
      }
    );

    return response; // Retourne la réponse complète
  } catch (error) {
    console.error("Erreur lors de la suppression de la candidature :", error);
    throw error;
  }
};



// Fonction pour récupérer les candidatures d'une offre spécifique
const fetchOfferApplications = async (offerSlug) => {
    return await apiService.get(`/offer/applies/${offerSlug}/applications/`);
};


// pour récupérer les détails d'une offre
const fetchApplicationDetails = async (slug) => {
    return await apiService.get(`/offer/apply/${slug}/`);
};


// pour récupérer les détails d'une proposition
const fetchProposalDetails = async (slug) => {
    const accessToken = TokenService.getUser();
    return await apiService.get(`/offer/apply/${slug}/`,
        {
            headers: authHeader(accessToken.data.id_token),
        });
};





//Création de contracts factices
const mockContracts = [
    {
        id: 1,
        contractNumber: "CTR-2025001",
        title: "Développement d'une application mobile",
        company: "Tech Solutions",
        startDate: "2025-01-15",
        endDate: "2025-06-15",
        status: "En cours",
    },
    {
        id: 2,
        contractNumber: "CTR-2025002",
        title: "Conception d'un site e-commerce",
        company: "WebMarket",
        startDate: "2025-02-01",
        endDate: "2025-07-30",
        status: "Terminé",
    },
    {
        id: 3,
        contractNumber: "CTR-2025003",
        title: "Refonte UX/UI d'une plateforme",
        company: "Creative Agency",
        startDate: "2025-03-10",
        endDate: "2025-09-10",
        status: "Annulé",
    },
];

// Fonction pour récupérer les contrats factices
const fetchContracts = () => {
    return new Promise((resolve) => {
        setTimeout(() => resolve({data: mockContracts}), 1000);
    });
};

// Fonction pour récupérer les détails contrats factices

const mockContractDetails = [
    {
        id: 1,
        contractNumber: "CTR-2025001",
        title: "Développement d'une application mobile",
        company: "Tech Solutions",
        startDate: "2025-01-15",
        endDate: "2025-06-15",
        status: "En cours",
        description:
            "Développement d'une application mobile pour un client dans le secteur technologique.",
        tasks: [
            "Définition des spécifications",
            "Design de l'interface",
            "Développement des fonctionnalités principales",
            "Tests de performance",
        ],
        budget: "100,000€",
        contactPerson: "John Doe",
        contactEmail: "john.doe@techsolutions.com",
    },
    {
        id: 2,
        contractNumber: "CTR-2025002",
        title: "Conception d'un site e-commerce",
        company: "WebMarket",
        startDate: "2025-02-01",
        endDate: "2025-07-30",
        status: "Terminé",
        description:
            "Conception et développement d'une plateforme de e-commerce complète.",
        tasks: [
            "Création de la maquette",
            "Intégration du système de paiement",
            "Mise en place de l'infrastructure serveur",
            "Test utilisateur",
        ],
        budget: "200,000€",
        contactPerson: "Jane Smith",
        contactEmail: "jane.smith@webmarket.com",
    },
    {
        id: 3,
        contractNumber: "CTR-2025003",
        title: "Refonte UX/UI d'une plateforme",
        company: "Creative Agency",
        startDate: "2025-03-10",
        endDate: "2025-09-10",
        status: "Annulé",
        description:
            "Refonte de l'expérience utilisateur et de l'interface d'une plateforme web existante.",
        tasks: [
            "Analyse des besoins utilisateurs",
            "Proposition de nouvelles interfaces",
            "Tests d'ergonomie",
        ],
        budget: "50,000€",
        contactPerson: "Mark Lee",
        contactEmail: "mark.lee@creativeagency.com",
    },
];

// Fonction pour récupérer les détails d'un contrat factice
const fetchContractDetails = (id) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const contract = mockContractDetails.find(
                (contract) => contract.id === id
            );
            if (contract) {
                resolve({data: contract});
            } else {
                reject("Contrat non trouvé");
            }
        }, 1000);
    });
};

const FreelanceJobService = {
    fetchAllOffers,
    fetchOffersByPage,
    fetchOfferDetails,
    fetchSimilarOffers,
    fetchAddressOffers,
    applyOffer,
    fetchApplications,
    fetchFavOffers,
    addOfferInFavorite,
    removeOfferInFavorite,
    removeApplication,
    fetchContracts,
    fetchContractDetails,
    fetchApplicationDetails,
    fetchProposalDetails,
    fetchRecentFreelanceJobs,
    fetchOfferApplications,
};

export default FreelanceJobService;
