export const FETCH_JOBS_REQUEST = "FETCH_JOBS_REQUEST";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const FETCH_JOBS_FAILURE = "FETCH_JOBS_FAILURE";

export const FETCH_RECENT_JOBS_REQUEST = "FETCH_RECENT_JOBS_REQUEST";
export const FETCH_RECENT_JOBS_SUCCESS = "FETCH_RECENT_JOBS_SUCCESS";
export const FETCH_RECENT_JOBS_FAILURE = "FETCH_RECENT_JOBS_FAILURE";

// similar jobs

export const FETCH_SIMILAR_JOBS_REQUEST = "FETCH_SIMILAR_JOBS_REQUEST";
export const FETCH_SIMILAR_JOBS_SUCCESS = "FETCH_SIMILAR_JOBS_SUCCESS";
export const FETCH_SIMILAR_JOBS_FAILURE = "FETCH_SIMILAR_JOBS_FAILURE";

// job details

export const SELECT_JOB_REQUEST = "SELECT_JOB_REQUEST";
export const SELECT_JOB_SUCCESS = "SELECT_JOB_SUCCESS";
export const SELECT_JOB_FAILURE = "SELECT_JOB_FAILURE";

// apply job

export const APPLY_JOB_REQUEST = "APPLY_JOB_REQUEST";
export const APPLY_JOB_SUCCESS = "APPLY_JOB_SUCCESS";
export const APPLY_JOB_FAILURE = "APPLY_JOB_FAILURE";
