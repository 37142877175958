import React, { useEffect } from "react";
import { Col, Card, CardBody, Row, Spinner } from "reactstrap";
import { fetchJobsPaged } from "../../../redux/job/action";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatAddress } from "../../../utils";
import { fetchJobDetails } from "../../../redux/job/action";

const LeftSideContent = ({ selectedDetails }) => {
  const dispatch = useDispatch();
  const { job, loading } = useSelector((state) => state.jobReducer);

  const handleJobClick = (selectedDetails) => {
    dispatch(fetchJobDetails(selectedDetails));
  };

  const getBadgeClass = (contract) => {
    switch (contract) {
      case "CDI":
        return "badge bg-success-subtle text-success fs-10 me-2";
      case "CDD":
        return "badge bg-danger-subtle text-danger fs-10 me-2";
      case "Freelance":
        return "badge bg-info-subtle text-info fs-10 mt-1 me-2";
      case "Stage":
        return "badge bg-warning-subtle text-warning fs-10 me-2";
      default:
        return "badge bg-secondary-subtle text-secondary fs-10 me-2";
    }
  };

  useEffect(() => {
    dispatch(fetchJobsPaged(job?.currentPage, selectedDetails?.id)); // Mettre à jour avec job.currentPage si nécessaire
    window.scrollTo(0, 0);
  }, [dispatch, job?.currentPage, selectedDetails?.id]);

  return (
    <React.Fragment>
      <Col lg={5}>
        <Card className="side-bar">
          <CardBody className="p-4">
            <div className="candidate-profile text-center">
              <img
                src={
                  selectedDetails.logo ||
                  "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                }
                alt=""
                className="avatar-lg rounded-circle"
              />
              <h6 className="fs-18 mb-3 mt-4">{selectedDetails.name}</h6>
              <ul className="candidate-detail-social-menu list-inline mb-0">
                <li className="list-inline-item">
                  <Link to={selectedDetails?.linkedin} className="social-link">
                    <i className="uil uil-linkedin-alt"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={selectedDetails?.telephone} className="social-link">
                    <i className="uil uil-whatsapp"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={selectedDetails?.telephone} className="social-link">
                    <i className="uil uil-phone-alt"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </CardBody>

          <CardBody className="candidate-profile-overview border-top p-4">
            <h6 className="fs-17 fw-semibold mb-3">Aperçu du profil</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <div className="d-flex">
                  <label className="text-dark">Email</label>
                  <div>
                    <p className="text-muted mb-0">{selectedDetails?.email}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">Téléphone</label>
                  <div>
                    <p className="text-muted mb-0">
                      {selectedDetails?.telephone}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">Adresse</label>
                  <div>
                    <p className="text-muted mb-0">
                      {`${selectedDetails?.address?.city}, ${selectedDetails?.address?.country}`}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">Website</label>
                  <div>
                    <p className="text-muted text-break mb-0">
                      <a
                        href={selectedDetails?.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {selectedDetails?.website}
                      </a>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </CardBody>
          <CardBody className="p-4 border-top">
            <div>
              <h6 className="fs-8 fw-semibold mb-4">Offre actuelle</h6>{" "}
              {/* Taille de texte réduite */}
              {loading ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                job?.content &&
                job?.content.slice(0, 2).map((job) => (
                  <Col md={12} key={job.id}>
                    <div className="job-box bookmark-post card mt-4">
                      <div className="p-4">
                        <Row className="align-items-center mb-1">
                          <Col md={8}>
                            <div className="text-start">
                              <h5 className="fs-16 mb-1">
                                {" "}
                                {/* Taille de texte réduite */}
                                <Link
                                  to={
                                    job?.slug ? `/jobdetails/${job?.slug}` : "#"
                                  }
                                  className="primary-link"
                                  onClick={() =>
                                    job.slug && handleJobClick(job)
                                  }
                                >
                                  {job?.title}
                                </Link>
                              </h5>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="text-end mb-4">
                              <Link
                                to={{
                                  pathname: `/companydetails/${job?.company?.id}`,
                                }}
                              >
                                <img
                                  src={
                                    job?.company?.logo ||
                                    "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                                  }
                                  alt=""
                                  className="img-fluid rounded-3"
                                  width={50}
                                  height={50}
                                />
                              </Link>
                            </div>
                          </Col>
                        </Row>
                        <Row className=" text-align-justify mb-0">
                          <Col md={7}>
                            <div
                              style={{ textAlign: "justify" }}
                              className="text-start mb-0 mb-md-0"
                            >
                              <div className="ms-0">
                                {job?.contractType.map((contract, index) => (
                                  <span
                                    key={`${contract}-${index}`}
                                    className={getBadgeClass(contract)}
                                  >
                                    {contract}
                                  </span>
                                ))}
                              </div>{" "}
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className=" text-end mb-0 mb-md-0">
                              <p className="text-muted fs-12 mb-0">
                                {" "}
                                {/* Taille de texte réduite */}
                                {job?.company?.name}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="p-3 bg-light">
                        <Row className="justify-content-between">
                          <Col md={8}>
                            <div className="d-flex mb-0">
                              <div className="flex-shrink-0">
                                <i className="mdi mdi-map-marker text-primary me-1"></i>
                              </div>
                              <p className="text-muted mb-0 fs-12">
                                {" "}
                                {/* Taille de texte réduite */}
                                {formatAddress(job?.address)}
                              </p>
                            </div>
                          </Col>
                          <Col lg={4} md={3}>
                            <div className="d-flex mb-0">
                              <div className="flex-shrink-0">
                                <i className="uil uil-clock-three text-primary me-1"></i>
                              </div>
                              <p className="text-muted mb-0 fs-12">
                                {" "}
                                {/* Taille de texte réduite */}
                                {job?.durationSincePosting}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                ))
              )}
            </div>

            {job?.content?.length > 2 && (
              <div className="text-center mt-4">
                <Link
                  to={`/jobs?companyId=${selectedDetails.id}`}
                  className="primary-link form-text"
                >
                  Voir plus <i className="mdi mdi-arrow-right"></i>
                </Link>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default LeftSideContent;
