import React, { useEffect } from "react";
import Section from "../Section";
import { Col, Container, Row } from "reactstrap";
import JobFavVacancy from "./JobFavVacancy";
import JobFavSearch from "./JobFavSearch";
import { FavOfferProvider } from "./FavOfferContext";

const OfferFavList = () => {
    useEffect(() => {
        document.title = "Mes Favoris | RecruTech";
    }, []);

    return (
        <React.Fragment>
            <Section />
            <section className="section">
                <FavOfferProvider>
                    <Container className="d-flex justify-content-center align-items-center">
                        <Row className="w-100">
                            <Col lg={12}>
                                <div className="justify-content-center me-lg-9">
                                    <JobFavSearch />
                                    <JobFavVacancy />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </FavOfferProvider>
            </section>
        </React.Fragment>
    );
};

export default OfferFavList;
