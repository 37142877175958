import React, { useEffect, useState } from "react";
import { Col, Card, CardBody, Container, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { fetchSimilarJobsPaged } from "../../../redux/job/action";
import { useDispatch, useSelector } from "react-redux";
import { formatAddress } from "../../../utils";
import Masonry from "react-masonry-component";

const JobVacancyPost = ({ selectedSimilarJobs, companyId }) => {
  const dispatch = useDispatch();
  const { similarJobs, loadingSimilarJobs, errorSimilarJobs } =
    useSelector((state) => state.jobReducer) || {};
  const [showAlljob] = useState(false);

  const getBadgeClass = (contract) => {
    switch (contract) {
      case "CDI":
        return "badge bg-success-subtle text-success fs-10 me-2";
      case "CDD":
        return "badge bg-danger-subtle text-danger fs-10 me-2";
      case "Freelance":
        return "badge bg-info-subtle text-info fs-10 mt-1 me-2";
      case "Stage":
        return "badge bg-warning-subtle text-warning fs-10 me-2";
      default:
        return "badge bg-secondary-subtle text-secondary fs-10 me-2";
    }
  };

  useEffect(() => {
    if (selectedSimilarJobs) {
      dispatch(fetchSimilarJobsPaged(selectedSimilarJobs));
      window.scrollTo(0, 0);
    }
  }, [dispatch, selectedSimilarJobs]);

  // Vérifiez que job et job.content existent avant d'accéder à job.content
  const visiblejob = Array.isArray(similarJobs?.content)
    ? showAlljob
      ? similarJobs.content
      : similarJobs.content.slice(0, 2)
    : [];

  return (
    <React.Fragment>
      <Container>
        {loadingSimilarJobs ? (
          <Spinner color="primary" />
        ) : errorSimilarJobs || visiblejob.length === 0 ? (
          <div className="text-center mt-4">
            <p>Aucun emploi similaire trouvé.</p>
          </div>
        ) : (
          <>
            <Masonry className="row mt-3">
              {visiblejob.map((job) => (
                <Col key={job.slug} lg={12} md={12} className="mb-4">
                  <Link
                    to={{ pathname: `/jobdetails/${job.slug}` }}
                    className="primary-link"
                  >
                    <Card
                      className="blog-masonry-box shadow overflow-hidden border-0 p-2"
                      style={{ height: "100%" }}
                    >
                      <CardBody className="p-4" style={{ height: "200px" }}>
                        <div className="d-flex align-items-center mt-1 mb-2">
                          <div className="flex-shrink-0">
                            <img
                              src={
                                job.company?.logo ||
                                "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                              }
                              alt=""
                              className="avatar-xs rounded-circle"
                            />
                          </div>
                          <div className="ms-3">
                            <h6 className="text-muted fs-10 mb-0">
                              {job.company?.name}
                            </h6>
                          </div>
                        </div>
                        <h6 className="fs-14 mb-0">{job.title}</h6>
                        <div className="row d-flex align-items-start mt-3">
                          <div className="col-6">
                            <div className="ms-0">
                              {job.contractType.map((contract) => (
                                <span
                                  key={contract}
                                  className={getBadgeClass(contract)}
                                >
                                  {contract}
                                </span>
                              ))}
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="ms-3">
                              <p className="text-muted">
                                <i className="mdi mdi-map-marker"></i>{" "}
                                {formatAddress(job.address)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <p className="text-muted">
                          <i className="mdi mdi-calendar"></i>{" "}
                          {job.durationSincePosting}
                        </p>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Masonry>
          </>
        )}
      </Container>
      {!showAlljob && similarJobs?.content?.length > 2 && (
        <div className="text-center mt-4">
          <Link
            to={`/jobs?companyId=${companyId}`}
            className="primary-link form-text"
          >
            Voir plus <i className="mdi mdi-arrow-right"></i>
          </Link>
        </div>
      )}
    </React.Fragment>
  );
};

export default JobVacancyPost;
