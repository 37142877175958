import React from "react";
import { Calendar } from "antd";
import { ConfigProvider } from "antd";
import frFR from "antd/es/locale/fr_FR"; // Importation de la locale française
import "dayjs/locale/fr"; // Importation de la locale pour dayjs
// import "antd/dist/antd.css";

const CalendarComponent = () => {
  // You can add any necessary state or logic here

  return (
    <ConfigProvider locale={frFR}>
      <div style={{ width: 600, border: "1px solid #d9d9d9", borderRadius: 4 }}>
        <Calendar />
      </div>{" "}
    </ConfigProvider>
  );
};

export default CalendarComponent;
