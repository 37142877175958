import * as TrainingActionTypes from "../types";

const initialState = {
  allTrainings: {
    content: [],
    totalPages: undefined,
    currentPage: 0,
    totalElements: undefined,
  },
  allAcademies: {
    content: [],
    totalPages: undefined,
    currentPage: 0,
    totalElements: undefined,
  },
  loading: false,
  error: undefined,
  recentTrainings: [],
  beneficiaryProjects: [],
  selectedDetails: undefined,
  selectedModuleDetails: undefined,
};

const trainingReducer = (state = initialState, action) => {
  switch (action.type) {
    case TrainingActionTypes.FETCH_TRAININGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TrainingActionTypes.FETCH_TRAININGS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTrainings: {
          content: action.payload.data.content,
          totalPages: action.payload.data.totalPages,
          currentPage: action.payload.data.currentPage,
          totalElements: action.payload.data.totalElements,
        },
        error: null,
      };

    case TrainingActionTypes.FETCH_TRAININGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TrainingActionTypes.FETCH_ACADEMIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TrainingActionTypes.FETCH_ACADEMIES_SUCCESS:
      return {
        ...state,
        loading: false,
        allAcademies: {
          content: action.payload.data.content,
          totalPages: action.payload.data.totalPages,
          currentPage: action.payload.data.currentPage,
          totalElements: action.payload.data.totalElements,
        },
        error: null,
      };

    case TrainingActionTypes.FETCH_ACADEMIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TrainingActionTypes.FETCH_RECENT_TRAININGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TrainingActionTypes.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        beneficiaryProjects: action.payload,
        error: null,
      };
    case TrainingActionTypes.FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TrainingActionTypes.FETCH_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TrainingActionTypes.FETCH_RECENT_TRAININGS_SUCCESS:
      return {
        ...state,
        loading: false,
        recentTrainings: action.payload.data,
        error: null,
      };
    case TrainingActionTypes.FETCH_RECENT_TRAININGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TrainingActionTypes.SELECT_TRAINING_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        selectedDetails: undefined, // Reset selectedDetails on new request
      };
    case TrainingActionTypes.SELECT_TRAINING_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedDetails: action.payload.data.data,
        error: null,
      };
    case TrainingActionTypes.SELECT_TRAINING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    //training academy
    case TrainingActionTypes.SELECT_TRAINING_ACADEMY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        selectedDetails: undefined, // Reset selectedDetails on new request
      };
    case TrainingActionTypes.SELECT_TRAINING_ACADEMY_SUCCESS:
      // console.log("Fetching all training", action.payload.data.content[0]);
      return {
        ...state,
        loading: false,
        selectedDetails: action.payload.data,
        error: null,
      };
    case TrainingActionTypes.SELECT_TRAINING_ACADEMY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // course details
    case TrainingActionTypes.SELECT_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        selectedDetails: undefined, // Reset selectedDetails on new request
      };
    case TrainingActionTypes.SELECT_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedDetails: action.payload.data,
        error: null,
      };
    case TrainingActionTypes.SELECT_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // module details
    case TrainingActionTypes.SELECT_MODULE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        selectedModuleDetails: undefined, // Reset selectedDetails on new request
      };
    case TrainingActionTypes.SELECT_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedModuleDetails: action.payload.data,
        error: null,
      };
    case TrainingActionTypes.SELECT_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // academy details
    case TrainingActionTypes.SELECT_ACADEMY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        selectedDetails: undefined, // Reset selectedDetails on new request
      };
    case TrainingActionTypes.SELECT_ACADEMY_SUCCESS:
      // console.log("ACADEMY_SUCCESS", action.payload.data.content[0]);
      return {
        ...state,
        loading: false,
        selectedDetails: action.payload.data.content[0],
        error: null,
      };
    case TrainingActionTypes.SELECT_ACADEMY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default trainingReducer;
