import React, { useState } from "react";
import { Button, Collapse, Row } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Assurez-vous d'importer Bootstrap CSS

const BlogCategory = ({
  selectedModuleDetails,
  activeModule,
  activeCourse,
  onSelectCourse,
  onSelectModule,
  onSelectActiveCourse,
}) => {
  const [, setActiveModuleState] = useState(null);

  // Vérifier que selectedModuleDetails et son contenu existent et qu'il s'agit bien d'un tableau
  if (!selectedModuleDetails || !Array.isArray(selectedModuleDetails.content)) {
    return null;
  }

  // Trier les modules par ID croissant sans modifier l'original
  const sortedModules = [...selectedModuleDetails.content].sort(
    (a, b) => a.id - b.id
  );

  // Fonction pour extraire et parser le contenu HTML
  const parseHTML = (htmlstring) => {
    if (!htmlstring) return null;

    // Vérifier si le contenu ressemble à du HTML
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(htmlstring);
    if (!isHTML) {
      // Si ce n'est pas du HTML, renvoyer une figcaption simple
      return (
        <figcaption className="blockquote-footer fs-14 mb-0">
          {htmlstring}
        </figcaption>
      );
    }

    // Si c'est du HTML, parser comme avant
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(htmlstring, "text/html");

    // Créer une liste des éléments li et p
    const listItems = Array.from(
      parsedHTML.querySelectorAll("ol > li, ul > li, p")
    ).map((item, index) => (
      <figcaption key={index} className="blockquote-footer fs-14 mb-3">
        <span dangerouslySetInnerHTML={{ __html: item.innerHTML }} />
      </figcaption>
    ));

    // Filtrer les éléments vides
    return listItems.filter(
      (item) =>
        item.props.children.props.dangerouslySetInnerHTML.__html.trim() !== ""
    );
  };

  return (
    <React.Fragment>
      <div className="mt-4 pt-2">
        <div className="sd-title">
          <h6 className="fs-16 mb-3">Contenu de la formation</h6>
        </div>
        <div className="side-bar mt-5 mb-3 mt-lg-0">
          <div className="accordion" id="accordionExample">
            {sortedModules.map((module) => (
              <div className="accordion-item" key={module.id}>
                <h2 className="accordion-header" id={`heading${module.id}`}>
                  <Button
                    className={`accordion-button ${
                      activeModule === module.id ? "active" : ""
                    }`}
                    onClick={() => {
                      setActiveModuleState(module.id);
                      onSelectModule(module.id);
                    }}
                    role="button"
                    aria-expanded={activeModule === module.id}
                  >
                    {module.title}
                  </Button>
                </h2>
                <Collapse isOpen={activeModule === module.id}>
                  <div className="accordion-body">
                    {/* Boucle pour afficher les cours */}
                    {module.courses.map((course) => (
                      <div key={course.id} className="mb-1">
                        <h6 className="text-warning">{course.title}</h6>
                        {/* Boucle pour afficher les sections */}
                        {course.sections && course.sections.length > 0 ? (
                          course.sections.map((section) => (
                            <Row
                              key={section.id}
                              className={`mt-4 ${
                                activeCourse === section.slug ? "active" : ""
                              }`}
                            >
                              <p className="text-muted fs-14">
                                <a
                                  href="#!"
                                  onClick={() => {
                                    onSelectCourse(section.slug);
                                    onSelectActiveCourse(course.id);
                                  }}
                                >
                                  {parseHTML(section.title)}
                                </a>
                              </p>
                            </Row>
                          ))
                        ) : (
                          <p className="text-muted fs-14">
                            Aucune section disponible
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlogCategory;
