import * as OfferActionTypes from "../types/index";

const initialState = {
    allOffers: [],
    allRecentsOffers: [],
    similarOffers: [],
    jobAddresses: [],
    favOffers: [],
    applications: [],
    deletedApplication: null,
    currentPage: 1,
    totalPages: 0,
    offerDetails: null,
    loading: false,
    error: undefined,
    applicationStatus: null,
    proposalDetails: null,
    contracts: [], //contrats mockée
};

const offerReducer = (state = initialState, action) => {
    switch (action.type) {
        //Pour récupérer toutes les offres
        case OfferActionTypes.FETCH_OFFERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case OfferActionTypes.FETCH_OFFERS_SUCCESS:
            return {
                ...state,
                allOffers: action.payload,
                loading: false,
                error: null,
            };
        case OfferActionTypes.FETCH_OFFERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        //  pour récupérer les détails d'une offre

        case OfferActionTypes.FETCH_OFFER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                offerDetails: null,
                error: null,
            };
        case OfferActionTypes.FETCH_OFFER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                offerDetails: action.payload,
                error: null,
            };
        case OfferActionTypes.FETCH_OFFER_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case OfferActionTypes.FETCH_SIMILAR_OFFERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case OfferActionTypes.FETCH_SIMILAR_OFFERS_SUCCESS:
            return {
                ...state,
                loading: false,
                similarOffers: action.payload,
                error: null,
            };
        case OfferActionTypes.FETCH_SIMILAR_OFFERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case OfferActionTypes.FETCH_OFFERS_ADDRESSES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case OfferActionTypes.FETCH_OFFERS_ADDRESSES_SUCCESS:
            return {
                ...state,
                loading: false,
                jobAddresses: action.payload.data,
                error: null,
            };
        case OfferActionTypes.FETCH_OFFERS_ADDRESSES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case OfferActionTypes.FETCH_FAVORITE_OFFERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case OfferActionTypes.FETCH_FAVORITE_OFFERS_SUCCESS:
            return {
                ...state,
                loading: false,
                favOffers: action.payload,
                error: null,
            };
        case OfferActionTypes.FETCH_FAVORITE_OFFERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case OfferActionTypes.ADD_FAVORITE_OFFER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case OfferActionTypes.ADD_FAVORITE_OFFER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case OfferActionTypes.ADD_FAVORITE_OFFER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case OfferActionTypes.REMOVE_FAVORITE_OFFER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case OfferActionTypes.REMOVE_FAVORITE_OFFER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case OfferActionTypes.REMOVE_FAVORITE_OFFER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case OfferActionTypes.FETCH_RECENT_FREELANCE_JOBS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case OfferActionTypes.FETCH_RECENT_FREELANCE_JOBS_SUCCESS:
            return {
                ...state,
                loading: false,
                allRecentsOffers: action.payload,
                error: null,
            };
        case OfferActionTypes.FETCH_RECENT_FREELANCE_JOBS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        //  cas pour gérer la candidature à une offre freelance

        case OfferActionTypes.FETCH_APPLICATIONS_FREELANCE_JOB_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case OfferActionTypes.FETCH_APPLICATIONS_FREELANCE_JOB_SUCCESS:
            return {
                ...state,
                loading: false,
                applications: action.payload,
                error: null,
            };
        case OfferActionTypes.FETCH_APPLICATIONS_FREELANCE_JOB_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case OfferActionTypes.APPLY_FREELANCE_JOB_REQUEST:
            return {
                ...state,
                loading: true,
                applicationStatus: "encours",
                error: null,
            };
        case OfferActionTypes.APPLY_FREELANCE_JOB_SUCCESS:
            return {
                ...state,
                loading: false,
                applicationStatus: "success",
                error: null,
            };
        case OfferActionTypes.APPLY_FREELANCE_JOB_FAILURE:
            return {
                ...state,
                loading: false,
                applicationStatus: "error",
                error: action.payload,
            };
        case OfferActionTypes.REMOVE_APPLICATION_REQUEST:
            return {
                ...state,
                loading: true,
                applicationStatus: "encours",
                error: null
            };
        case OfferActionTypes.REMOVE_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                applicationStatus: "success",
                error: null
            };
        case OfferActionTypes.REMOVE_APPLICATION_FAILURE:
            return {
                ...state,
                loading: false,
                applicationStatus: "error",
                error: action.payload
            };
        //


        //Pour récupérer les contrats
        case OfferActionTypes.FETCH_CONTRACTS_REQUEST:
            return {...state, loading: true, error: null};

        case OfferActionTypes.FETCH_CONTRACTS_SUCCESS:
            return {...state, loading: false, contracts: action.payload};

        case OfferActionTypes.FETCH_CONTRACTS_FAILURE:
            return {...state, loading: false, error: action.payload};

        //détails proposition
        case OfferActionTypes.FETCH_PROPOSAL_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case OfferActionTypes.FETCH_PROPOSAL_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                proposalDetails: action.payload,
            };
        case OfferActionTypes.FETCH_PROPOSAL_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        // Détails des contrats
        case OfferActionTypes.FETCH_CONTRACT_DETAILS_REQUEST:
            return {...state, loading: true};
        case OfferActionTypes.FETCH_CONTRACT_DETAILS_SUCCESS:
            return {...state, contract: action.payload, loading: false};
        case OfferActionTypes.FETCH_CONTRACT_DETAILS_FAILURE:
            return {...state, error: action.payload, loading: false};

        default:
            return state;
    }
};
export default offerReducer;
