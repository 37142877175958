import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Table, Card, Spin, message} from "antd";
import {fetchContracts} from "../../../redux/freelance/offer/action";
import {useNavigate} from "react-router-dom";

const ContractsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {contracts = [], error, loading} = useSelector((state) => state.offerReducer || {
        contracts: [],
        error: null,
        loading: false
    });

    useEffect(() => {
        dispatch(fetchContracts());
    }, [dispatch]);

    const columns = [
        {title: "Nº Contrat", dataIndex: "contractNumber", key: "contractNumber", width: 150},
        {title: "Titre du Contrat", dataIndex: "title", key: "title"},
        {title: "Entreprise", dataIndex: "company", key: "company"},
        {title: "Date de Début", dataIndex: "startDate", key: "startDate"},
        {title: "Date de Fin", dataIndex: "endDate", key: "endDate"},
        {
            title: "Statut",
            dataIndex: "status",
            key: "status",
            render: (status) => {
                let color = "default";
                if (status === "En cours") color = "blue";
                else if (status === "Terminé") color = "green";
                else if (status === "Annulé") color = "red";
                return <span style={{color}}>{status}</span>;
            },
        },
    ];

    const handleRowClick = (record) => {
        message.info(`Contrat sélectionné : ${record.title}`);
        navigate(`/details-contract/${record.id}`);
    };

    // Gérer le survol de la ligne
    const [hoveredRow, setHoveredRow] = useState(null);

    const handleMouseEnter = (record) => {
        setHoveredRow(record.id);
    };

    const handleMouseLeave = () => {
        setHoveredRow(null);
    };

    const rowStyle = (record) => ({
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        backgroundColor: hoveredRow === record.id ? '#e6f7ff' : 'transparent',
    });

    // Affichage conditionnel en fonction du statut de chargement ou d'erreur
    return (
        <Card className="shadow-lg p-8">
            {error && <p className="text-danger">{error}</p>}
            {loading ? (
                <div style={{textAlign: "center", padding: "20px"}}>
                    <Spin size="large"/>
                </div>
            ) : (
                <Table
                    columns={columns}
                    dataSource={contracts}
                    pagination={{pageSize: 5}}
                    rowKey={(record) => record.id || record.contractNumber}
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record),
                        onMouseEnter: () => handleMouseEnter(record),
                        onMouseLeave: handleMouseLeave,
                        style: rowStyle(record),
                    })}
                />
            )}
        </Card>
    );
};

export default ContractsPage;
