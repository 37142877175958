export const SIGNUP_USER_REQUEST = "SIGNUP_USER_REQUEST";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_ERROR = "SIGNUP_USER_ERROR";

export const SIGNIN_USER_REQUEST = "SIGNIN_USER_REQUEST";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNIN_USER_ERROR = "SIGNIN_USER_ERROR";

// social login

export const SOCIAL_LOGIN_REQUEST = "SOCIAL_LOGIN_REQUEST";
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";
export const SOCIAL_LOGIN_ERROR = "SOCIAL_LOGIN_ERROR";

// social signUp

export const SOCIAL_SIGNUP_REQUEST = "SOCIAL_SIGNUP_REQUEST";
export const SOCIAL_SIGNUP_SUCCESS = "SOCIAL_SIGNUP_SUCCESS";
export const SOCIAL_SIGNUP_ERROR = "SOCIAL_SIGNUP_ERROR";

// forgot password

export const SIGNOUT_USER_REQUEST = "SIGNOUT_USER_REQUEST";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const SIGNOUT_USER_ERROR = "SIGNOUT_USER_ERROR";

export const CURRENT_USER_REQUEST = "CURRENT_USER_REQUEST";
export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS";
export const CURRENT_USER_ERROR = "CURRENT_USER_ERROR";

export const OTP_USER_REQUEST = "OTP_USER_REQUEST";
export const OTP_USER_SUCCESS = "OTP_USER_SUCCESS";
export const OTP_USER_ERROR = "OTP_USER_ERROR";

export const OTP_RESEND_REQUEST = "OTP_RESEND_REQUEST";
export const OTP_RESEND_SUCCESS = "OTP_RESEND_SUCCESS";
export const OTP_RESEND_ERROR = "OTP_RESEND_ERROR";

export const CLEAR_ERROR = "CLEAR_ERROR";
export const CLEAR_REGISTER = "CLEAR_REGISTER";
