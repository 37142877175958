import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Heart, ArrowLeft } from "lucide-react"; // Icônes modernes
import TokenService from "../../../service/TokenService";

const Section = () => {
  const getUser = TokenService.getUser();
  const location = useLocation();
  const isMyFavsPage = location.pathname === "/freelance-jobs/myfavs";

  return (
    <React.Fragment>
      <section className="page-title-box">
        <Container className="container">
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="text-center text-white">
                <h3 className="mb-4">Nos Missions Freelances</h3>
                <div className="page-next">
                  <nav className="d-inline-block" aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <Link to="/">Accueil</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/freelance-jobs">Freelance</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Nos Offres
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </Col>
          </Row>

          {getUser && (
            <Row className="justify-content-center mt-3">
              <Col md={6} className="text-center">
                <Link
                  to={
                    isMyFavsPage ? "/freelance-jobs" : "/freelance-jobs/myfavs"
                  }
                  className="nav-link d-flex align-items-center justify-content-center gap-2"
                  style={{ fontSize: "1rem", padding: "10px 15px" }}
                >
                  {isMyFavsPage ? (
                    <>
                      <ArrowLeft size={18} /> Retour aux offres
                    </>
                  ) : (
                    <>
                      <Heart size={18} className="text-danger" /> Mes Favoris
                    </>
                  )}
                </Link>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
