import React from "react";
import { Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Input, Row } from "reactstrap";
import {useFavOfferContext} from "./FavOfferContext";

const JobFavSearch = () => {
    const { query, setQuery, handleSearch } = useFavOfferContext();
    return (
        <React.Fragment>
            <div className="job-list-header">
                <Form action="#">
                    <Row className="g-2">
                        <Col lg={9} md={6}>
                            <div className="filler-job-form">
                                <Input
                                    className="form-control"
                                    type="search"
                                    placeholder="Compétences, titre, ville, ..."
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                />
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <Link to="#" className="btn btn-primary w-100" onClick={handleSearch}>
                                <i className="uil uil-filter"></i> Filter
                            </Link>
                        </Col>
                    </Row>
                </Form>
            </div>
        </React.Fragment>
    );
};

export default JobFavSearch;
