import React from "react";
import { Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Importer useNavigate
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "antd";

const Tags = ({ selectedDetails, user }) => {
  const navigate = useNavigate(); // Initialiser useNavigate
  // const { authUser } = useSelector(({ auth }) => auth);
  // Assurez-vous que selectedDetails est défini et non vide
  if (
    !selectedDetails ||
    !selectedDetails.trainingIncludes ||
    !selectedDetails.requirements ||
    !selectedDetails.targetAudience
  ) {
    return null; // ou un message d'erreur ou un composant de chargement
  }

  // Fonction pour extraire et parser le contenu HTML
  const parseHTML = (htmlstring) => {
    if (!htmlstring) return null;

    // Vérifier si le contenu ressemble à du HTML
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(htmlstring);
    if (!isHTML) {
      // Si ce n'est pas du HTML, renvoyer une figcaption simple
      return (
        <figcaption className="blockquote-footer fs-14 mb-3">
          {htmlstring}
        </figcaption>
      );
    }

    // Si c'est du HTML, parser comme avant
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(htmlstring, "text/html");

    // Créer une liste des éléments li et p
    const listItems = Array.from(
      parsedHTML.querySelectorAll("ol > li, ul > li, p")
    ).map((item, index) => (
      <figcaption key={index} className="blockquote-footer fs-14 mb-3">
        <span dangerouslySetInnerHTML={{ __html: item.innerHTML }} />
      </figcaption>
    ));

    // Filtrer les éléments vides
    return listItems.filter(
      (item) =>
        item.props.children.props.dangerouslySetInnerHTML.__html.trim() !== ""
    );
  };

  const onSubmit = () => {
    if (
      selectedDetails &&
      Array.isArray(selectedDetails.trainingModules) &&
      selectedDetails.trainingModules.length !== 0
    ) {
      navigate(`/course/${selectedDetails.slug}`);
    } else {
      toast.warning("Aucun cours disponible", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="mt-4 pt-2">
        <div className="sd-title mb-3">
          <h6 className="fs-16 mb-3">Cette formation comprend</h6>
        </div>
        <Row className="mb-4">
          {parseHTML(selectedDetails.trainingIncludes)}
        </Row>
      </div>
      <div className="mt-4 pt-2">
        <div className="sd-title mb-3">
          <h6 className="fs-16 mb-3">Exigences</h6>
        </div>
        <Row className="mt-4">{parseHTML(selectedDetails.requirements)}</Row>
      </div>
      <div className="mt-4 pt-2">
        <div className="sd-title mb-3">
          <h6 className="fs-16 mb-3">À qui s'adresse cette formation ?</h6>
        </div>
        <Row className="mt-4">{parseHTML(selectedDetails.targetAudience)}</Row>
      </div>
      <div className="mt-4 pt-2">
        <div className="sd-title mb-3">
          <h6 className="fs-16 mb-3">Rejoindre la formation</h6>
        </div>
        <div className="mt-0">
          <Tooltip
            title={
              user === null ? "Veuillez vous connecter pour poursuivre" : ""
            }
            color="red"
          >
            <button
              id="applyButton"
              onClick={user !== null ? onSubmit : undefined}
              className={`btn btn-primary btn-hover w-100 mt-2`}
            >
              Commencer <i className="uil uil-arrow-right"></i>
            </button>
          </Tooltip>
        </div>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

export default Tags;
