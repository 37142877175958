import React, { useEffect, useState } from "react";
import { Table, Modal } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Lottie from "react-lottie";
import * as errorAnimationData from "../../../../assets/error.json";
import * as emptyAnimationData from "../../../../assets/emptyData.json";
import JobCardItemSkeleton from "../../../Jobs/JobGrid/JobCardItemSkeleton";
import { Row, Col } from "reactstrap";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import {
  fetchFreelancerApplications,
  removeFreelancerApplication,
} from "../../../../redux/freelance/offer/action";
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ApplicationsPage = () => {
  const dispatch = useDispatch();
  const { applications, allOffers } = useSelector(
    (state) => state.offerReducer
  );
  const [currentPage] = useState(1);
  const [fetchLoading, setFetchLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [applicationToDelete, setApplicationToDelete] = useState(null);
  const totalPage = applications?.data?.length
  console.log(applications)
  useEffect(() => {
    const fetchData = async () => {
      setFetchLoading(true);
      try {
        await dispatch(fetchFreelancerApplications(currentPage));
      } catch (err) {
        setError("Impossible de charger les candidatures.");
      } finally {
        setFetchLoading(false);
      }
    };
    fetchData();
  }, [currentPage, dispatch]);

  const handleDeleteApplication = async () => {
    if (applicationToDelete) {
      try {
        setFetchLoading(true);
        await dispatch(removeFreelancerApplication(applicationToDelete));
        setShowModal(false);
        // Refresh the application list after successful deletion
        dispatch(fetchFreelancerApplications(currentPage));
      } catch (err) {
        toast.error(
            "Une erreur est survenue lors de l'annulation de la candidature."
        );
      } finally {
        setFetchLoading(false);
        setApplicationToDelete(null);
      }
    }
  };

  if (fetchLoading) {
    return (
      <div className="text-center mt-5">
        <Row className="align-items-center mb-1">
          <Col md={12}>
            <JobCardItemSkeleton />
          </Col>
          <Col md={12}>
            <JobCardItemSkeleton />
          </Col>
        </Row>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-0">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: errorAnimationData,
          }}
          height={300}
          width={300}
        />
        <p className="text-danger mt-0">Une erreur s'est produite : {error}</p>
      </div>
    );
  }

  if (!applications || !applications.data || applications.data.length === 0) {
    return (
      <div className="text-center mt-0">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: emptyAnimationData,
          }}
          height={300}
          width={300}
        />
        <p className="text-muted mt-0">
          Vous n'avez postulé à aucune offre pour le moment.
        </p>
      </div>
    );
  }

  const statusLabels = {
    ACCEPT: "Accepté",
    IN_PROGRESS: "Reçue",
    REJECT: "Rejetée",
  };

  const data = applications.data
    .map((application, key) => {
      const offer = allOffers?.data?.find((o) => o.id === application.offer);
      if (!offer) return null;
      return {
        key,
        libelle: offer.title || "Non spécifié",
        decisionJobStatus:
          statusLabels[application.proposal_status] || "En attente",
        company: offer.enterprise_name || "N/A",
        dateApplication: moment(application.created_at).format("DD/MM/YYYY"),
        slug: application.slug || "#",
      };
    })
    .filter((item) => item !== null);

  const getBadgeClass = (status) => {
    switch (status) {
      case "Accepté":
        return "badge bg-success-subtle text-success";
      case "Reçu":
        return "badge bg-info-subtle text-info";
      case "Rejeté":
        return "badge bg-danger-subtle text-danger";
      default:
        return "badge bg-warning-subtle text-warning";
    }
  };

  const columns = [
    {
      title: "Libellé",
      dataIndex: "libelle",
      key: "libelle",
      render: (text, record) => (
        <Link
          to={`/myapplication-details/${record.slug}/`}
          className="text-dark fw-bold"
        >
          <strong style={{ textTransform: "uppercase" }}>{text}</strong>
        </Link>
      ),
    },
    {
      title: "Statut",
      dataIndex: "decisionJobStatus",
      key: "decisionJobStatus",
      render: (status) => (
        <span className={`${getBadgeClass(status)} d-flex align-items-center`}>
          {status}
        </span>
      ),
    },
    {
      title: "Entreprise",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Date de candidature",
      dataIndex: "dateApplication",
      key: "dateApplication",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <DeleteOutlined
          style={{
            color: "red",
            cursor: "pointer",
            transition: "transform 0.3s ease-in-out",
          }}
          onMouseEnter={(e) => (e.target.style.transform = "scale(1.2)")}
          onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          onClick={() => {
            setApplicationToDelete(applications.data[record.key]?.slug);
            setShowModal(true);
          }}
        />
      ),
    },
  ];

  return (
    <div className="mt-4">
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
        pageSize: 14, // Nombre d'éléments par page
        showSizeChanger: true, // Permet à l'utilisateur de changer le nombre d'éléments par page
        pageSizeOptions: ['5', '10', '20'], // Options pour la taille de la page
      }}
      />
      <ToastContainer />
      <Modal
        title={
          <span className="text-danger">
            <i className="uil uil-exclamation-triangle"></i> Attention
          </span>
        }
        visible={showModal}
        onOk={handleDeleteApplication}
        onCancel={() => setShowModal(false)}
        centered
        okText="Confirmer"
        cancelText="Annuler"
        okButtonProps={{ danger: true }}
      >
        <p className="text-danger">
          <i className="uil uil-exclamation-circle"></i> Cette action est
          irréversible. Êtes-vous sûr de vouloir annuler votre candidature ?
        </p>
      </Modal>
    </div>
  );
};

export default ApplicationsPage;

