// const formatAddress = (address) => {
//   // const { city, country } = address;
//   const { country } = address;

//   return `${country}`;

//   // return `${city}, ${country}`;
// };
const formatAddress = (address) => {
  if (!address) return "Adresse non disponible";

  const { country } = address;
  return country || "Pays non disponible";
};

const renderHTMLContent = (htmlContent) => {
  return { __html: htmlContent };
};

const sliceDescription = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};
export { formatAddress, renderHTMLContent, sliceDescription };
