import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Section from "./Section";
import ContractsPage from "./ContractsPage"; // Correction du nom importé

const Contracts = () => {
    useEffect(() => {
        document.title = "Mes Contrats | RecruTech";
    }, []);

    return (
        <React.Fragment>
            <Section />
            <section className="section">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="me-lg-5">
                                <ContractsPage />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default Contracts;
