import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Card, Col, Input, Row, CardBody } from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import {
  activateAccount,
  clearError,
  resendOtp,
} from "../../redux/auth/action"; // Assurez-vous que resendOtpAction est importé correctement
import { clearMessage } from "../../redux/message/action";
import Lottie from "react-lottie";
import * as animationData from "../../../src/assets/sendEmail.json";
import { Form } from "react-bootstrap";

const SendOtp = () => {
  document.title = "Verify OTP | RecruTech";

  const [otpNumber, setOtpNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = localStorage.getItem("email");

  const handleOptChange = (e) => {
    dispatch(clearMessage());
    setOtpNumber(e.target.value);
  };

  const submitVerification = async () => {
    setLoading(true);
    const result = await dispatch(activateAccount(otpNumber));
    if (result.success) {
      navigate("/"); // Naviguer uniquement si l'activation est réussie
    }
    setLoading(false);
  };

  const resendOtpAction = () => {
    dispatch(resendOtp()); // Appel correct de l'action
  };

  useEffect(() => {
    dispatch(clearError());
    dispatch(clearMessage());
  }, [dispatch]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src="https://res.cloudinary.com/dryskp7hv/image/upload/v1688758187/Eburnie%20hub/logo/rt_couleur_simple_480_dh81k5.png"
                                height="100"
                                alt=""
                                className="logo-dark"
                              />
                            </Link>
                            <div className="mt-5">
                              <div className="lottie-container">
                                <Lottie
                                  options={defaultOptions}
                                  height={300}
                                  width={300}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content p-5 h-100 text-black">
                            <div className="text-center mb-4">
                              <h5>Activer mon compte</h5>
                            </div>
                            <Form className="auth-form text-white">
                              <div
                                className="alert alert-warning text-center mb-4"
                                role="alert"
                              >
                                Entrer le code reçu par mail {email} pour
                                activer votre compte!
                              </div>
                              <div className="mb-4">
                                <Input
                                  type="text"
                                  className="form-control w-100" // Ajout de la classe w-100
                                  required
                                  id="confirmInput"
                                  placeholder="xxxx-xxxx-xxxx"
                                  value={otpNumber}
                                  onChange={handleOptChange}
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              </div>
                              <div className="mt-3">
                                <button
                                  type="button"
                                  onClick={submitVerification}
                                  className="btn btn-primary w-100"
                                  disabled={loading} // Désactiver le bouton lorsque le chargement est en cours
                                >
                                  {loading ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    "Confirmer"
                                  )}
                                </button>
                              </div>
                            </Form>
                            <div className="pt-1">
                              <p>
                                Si vous n'avez pas reçu de mail de confirmation
                                de création de compte, veuillez cliquer sur
                                <button
                                  type="button"
                                  onClick={resendOtpAction}
                                  style={{
                                    background: "none",
                                    border: "none",
                                    padding: "0",
                                    color: "#007bff",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                  }}
                                >
                                  Renvoyer le code
                                </button>
                              </p>
                            </div>
                            <ToastContainer />
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SendOtp;
