export const FETCH_TRAININGS_REQUEST = "FETCH_TRAININGS_REQUEST";
export const FETCH_TRAININGS_SUCCESS = "FETCH_TRAININGS_SUCCESS";
export const FETCH_TRAININGS_FAILURE = "FETCH_TRAININGS_FAILURE";

export const FETCH_RECENT_TRAININGS_REQUEST = "FETCH_RECENT_TRAININGS_REQUEST";
export const FETCH_RECENT_TRAININGS_SUCCESS = "FETCH_RECENT_TRAININGS_SUCCESS";
export const FETCH_RECENT_TRAININGS_FAILURE = "FETCH_RECENT_TRAININGS_FAILURE";

export const SELECT_TRAINING_REQUEST = "SELECT_TRAINING_REQUEST";
export const SELECT_TRAINING_SUCCESS = "SELECT_TRAINING_SUCCESS";
export const SELECT_TRAINING_FAILURE = "SELECT_TRAINING_FAILURE";

export const SELECT_MODULE_REQUEST = "SELECT_MODULE_REQUEST";
export const SELECT_MODULE_SUCCESS = "SELECT_MODULE_SUCCESS";
export const SELECT_MODULE_FAILURE = "SELECT_MODULE_FAILURE";

export const SELECT_COURSE_REQUEST = "SELECT_COURSE_REQUEST";
export const SELECT_COURSE_SUCCESS = "SELECT_COURSE_SUCCESS";
export const SELECT_COURSE_FAILURE = "SELECT_COURSE_FAILURE";

export const SELECT_TRAINING_ACADEMY_REQUEST =
  "SELECT_TRAINING_ACADEMY_REQUEST";
export const SELECT_TRAINING_ACADEMY_SUCCESS =
  "SELECT_TRAINING_ACADEMY_SUCCESS";
export const SELECT_TRAINING_ACADEMY_FAILURE =
  "SELECT_TRAINING_ACADEMY_FAILURE";

export const SELECT_ACADEMY_REQUEST = "SELECT_ACADEMY_REQUEST";
export const SELECT_ACADEMY_SUCCESS = "SELECT_ACADEMY_SUCCESS";
export const SELECT_ACADEMY_FAILURE = "SELECT_ACADEMY_FAILURE";

export const FETCH_ACADEMIES_REQUEST = "FETCH_ACADEMIES_REQUEST";
export const FETCH_ACADEMIES_SUCCESS = "FETCH_ACADEMIES_SUCCESS";
export const FETCH_ACADEMIES_FAILURE = "FETCH_ACADEMIES_FAILURE";

export const FETCH_PROJECTS_REQUEST = "FETCH_PROJECTS_REQUEST";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_FAILURE = "FETCH_PROJECTS_FAILURE";

// Project types.js

export const ADD_PROJECT_REQUEST = "ADD_PROJECT_REQUEST";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_FAILURE = "ADD_PROJECT_FAILURE";

export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";

export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";
