import React, { useEffect, useRef, useState } from "react";
import { Container, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTrainingsPaged } from "../../../redux/training/action";
import Masonry from "react-masonry-component";
import Section from "../../Blog/BlogMasonary/Section";
import MasonaryContent from "../../Blog/BlogMasonary/MasonaryContent";
import { useLocation } from "react-router-dom";

import Lottie from "react-lottie";
// import * as loadingAnimationData from "../../../../src/assets/loading.json";
import * as errorAnimationData from "../../../../src/assets/error.json"; // Animation pour l'erreur
import * as emptyAnimationData from "../../../../src/assets/emptyData.json"; // Animation pour données vides
import Pagination from "../../CandidateAndCompany/CompanyList/Pagination";
import JobCardItemSkeleton from "../../Jobs/JobGrid/JobCardItemSkeleton";

const BlogMasonary = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  document.title = "Nos Formations | RecruTech";
  const [loading, setLoading] = useState(true);
  const firstCardRef = useRef(null);
  const dispatch = useDispatch();
  const query = useQuery();
  const companyId = query.get("companyId") || ""; // Récupérer l'ID de l'entreprise ou une chaîne vide
  const { allTrainings, error } = useSelector((state) => state.trainingReducer);

  const setCurrentPage = (page) => {
    dispatch(fetchAllTrainingsPaged(page)); // Passer la page actuelle
  };

  useEffect(() => {
    // Initialiser avec la première page
    dispatch(fetchAllTrainingsPaged(allTrainings.currentPage, companyId)); // Passer 1 pour la première page
    window.scrollTo(0, 0);
  }, [dispatch, allTrainings.currentPage, companyId]);

  // Gérer l'état de chargement en fonction des données ou des erreurs
  useEffect(() => {
    if (allTrainings) {
      // Simuler un délai de 3000ms (3 secondes)
      setTimeout(() => {
        setLoading(false);
      }, 1000); // 3000 millisecondes = 3 secondes
    }
  }, [allTrainings]);

  // Paramètres pour l'animation Lottie d'erreur
  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // Paramètres pour l'animation Lottie de données vides
  const emptyOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // Dans le rendu du composant
  return (
    <React.Fragment>
      <Section />
      <section className="mt-5">
        <Container>
          {loading ? (
            <div className="text-center mt-5">
              <Row className="align-items-center mb-5">
                <Col md={6}>
                  <JobCardItemSkeleton />
                </Col>
                <Col md={6}>
                  <JobCardItemSkeleton />
                </Col>
              </Row>{" "}
            </div>
          ) : error ? (
            <div className="text-center mt-5">
              <Lottie options={errorOptions} height={300} width={300} />
              <p className="text-danger mt-5 mb-5">
                Une erreur s'est produite : {error}
              </p>
            </div>
          ) : allTrainings?.content?.length === 0 ? ( // Utilisation du "?" pour éviter l'erreur
            <div className="text-center mt-5">
              <Lottie options={emptyOptions} height={300} width={300} />
              <p className="text-muted mt-3">
                Aucune formation trouvée. Veuillez réessayer plus tard.
              </p>
            </div>
          ) : (
            <>
              <Masonry className="row mb-3">
                {allTrainings.content.map((item, index) => (
                  <MasonaryContent
                    key={item.id || `training-${index}`}
                    training={item}
                    index={index}
                    firstCardRef={index === 0 ? firstCardRef : null} // Utilisation correcte de ref
                  />
                ))}
              </Masonry>
              <Pagination
                currentPage={allTrainings.currentPage}
                totalPages={allTrainings.totalPages}
                fetchData={fetchAllTrainingsPaged}
                setCurrentPage={setCurrentPage}
                firstCardRef={firstCardRef}
              />
            </>
          )}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default BlogMasonary;
