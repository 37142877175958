import Api from "../Api";
import TokenService from "../TokenService";
import authHeader from "./auth-header";

// authService.js

const signIn = async (username, rememberMe, password) => {
  return await Api.post("/api/v1/authenticate", {
    username,
    rememberMe,
    password,
  });
};

const linkedinAuth = async () => {
  const url = `/api/v1/auth/linkedin`;
  return await Api.get(url);
};

const googleAuth = async () => {
  const url = `/api/v1/auth/google`;
  return await Api.get(url);
};

const signUp = async (payload, accessToken) => {
  const provider = localStorage.getItem("Provider");
  return await Api.post(
    `/api/v1/public/candidates/register?accessToken=${accessToken}&provider=${provider}`,
    payload
  );
};

const activateAccount = async (email, otpNumber) => {
  return await Api.post("/api/v1/account/activate", {
    key: email,
    otpNumber: otpNumber,
  });
};

const resetPasswordInit = async (email) => {
  return await Api.post("/api/v1/account/reset-password/init", {
    email: email,
  });
};

const resetPasswordFinish = async (otpNumber, newPassword) => {
  return await Api.post("/api/v1/account/reset-password/finish", {
    key: otpNumber,
    newPassword: newPassword,
  });
};

const resendOtp = async (email) => {
  return await Api.get("/api/v1/resend-otp", { params: { email } });
};

const getCurrentUser = async (token) => {
  const { data: response } = await Api.get("api/v1/account", {
    headers: authHeader(token),
  });
  return response;
};

const signOut = () => {
  TokenService.removeUser();
};

const AuthService = {
  signIn,
  signOut,
  signUp,
  getCurrentUser,
  activateAccount,
  resendOtp,
  resetPasswordInit,
  resetPasswordFinish,
  linkedinAuth,
  googleAuth,
};

export default AuthService;
