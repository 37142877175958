import ProfileService from "../../../service/profile";
import * as ProfileActionTypes from "../types";
import {toast} from "react-toastify"; // Ajoutez cette ligne

export const resetSelectedDetails = () => ({
    type: "RESET_SELECTED_DETAILS",
});

export const fetchContractTypes = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_CONTRACT_TYPES_REQUEST});
        try {
            const response = await ProfileService.fetchContractTypes();
            // console.log('rrr',response);

            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_CONTRACT_TYPES_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_CONTRACT_TYPES_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const fetchAllDomain = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_DOMAIN_TYPES_REQUEST});
        try {
            const response = await ProfileService.fetchDomainTypes();
            // console.log('rrr',response);

            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_DOMAIN_TYPES_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_DOMAIN_TYPES_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const fetchLevelTypes = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_LEVEL_TYPES_REQUEST});
        try {
            const response = await ProfileService.fetchLevelTypes();
            // console.log('rrr',response);

            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_LEVEL_TYPES_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_LEVEL_TYPES_FAILURE,
                payload: error.message,
            });
        }
    };
};

// infoPerso

export const fetchCandidateInfoPerso = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_REQUEST});
        try {
            const response = await ProfileService.fetchCandidateInfoPerso();
            // console.log("rrr", response);
            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_FAILURE,
                payload: error.message,
            });
        }
    };
};
export const updateCandidateInfoPerso = (infoPersoData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.UPDATE_CANDIDATE_INFO_PERSO_REQUEST});
        try {
            const response = await ProfileService.updateCandidateInfoPerso(
                infoPersoData
            );
            // console.log('rrr',response);
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.UPDATE_CANDIDATE_INFO_PERSO_SUCCESS,
                    payload: response.data,
                });
                toast.success("Informations mises à jour avec succès");
            } else {
                toast.error("Une erreur est survenue lors de la mise à jour");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.UPDATE_CANDIDATE_INFO_PERSO_FAILURE,
                payload: error.message,
            });
        }
    };
};
// Ma situation

export const fetchCandidateSituation = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_CANDIDATE_SITUATION_REQUEST});
        try {
            const response = await ProfileService.fetchCandidateInfoSituation();
            // console.log('rrr',response);
            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_CANDIDATE_SITUATION_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_CANDIDATE_SITUATION_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const updateCandidateSituation = (maSituationData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.UPDATE_CANDIDATE_SITUATION_REQUEST});
        try {
            const response = await ProfileService.updateCandidateInfoSituation(
                maSituationData
            );
            // console.log("rrrsaaaaaaaaaaa", response);
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.UPDATE_CANDIDATE_SITUATION_SUCCESS,
                    payload: response.data,
                });
                toast.success("Informations mises à jour avec succès");
            } else {
                toast.error("Une erreur est survenue lors de la mise à jour");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.UPDATE_CANDIDATE_SITUATION_FAILURE,
                payload: error.message,
            });
        }
    };
};


// Ma situation Portfolio

export const fetchPortfolio = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_PORTFOLIO_REQUEST});
        try {
            const response = await ProfileService.fetchPortfolioInfo();

            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_PORTFOLIO_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_PORTFOLIO_FAILURE,
                payload: error.message,
            });
        }
    };
};

export  const updatePortfolio = (portfolioData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.UPDATE_PORTFOLIO_REQUEST});
        try {
            const response = await ProfileService.updatePortfolioInfo(portfolioData);

            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.UPDATE_PORTFOLIO_SUCCESS,
                    payload: response.data,
                });
                toast.success("Portfolio mis à jour avec succès");
            } else {
                toast.error("Une erreur est survenue lors de la mise à jour");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.UPDATE_PORTFOLIO_FAILURE,
                payload: error.message,
            });
        }
    };
};

// preference

export const fetchCandidatePreferences = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_CANDIDATE_PREFERENCES_REQUEST});
        try {
            const response = await ProfileService.fetchCandidateInfoPreferences();
            // console.log('rrr',response);
            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_CANDIDATE_PREFERENCES_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_CANDIDATE_PREFERENCES_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const updateCandidatePreferences = (preferencesData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.UPDATE_CANDIDATE_PREFERENCES_REQUEST});
        try {
            const response = await ProfileService.updateCandidateInfoPreferences(
                preferencesData
            );
            // console.log('rrr',response);
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.UPDATE_CANDIDATE_PREFERENCES_SUCCESS,
                    payload: response.data,
                });
                toast.success("Informations mises à jour avec succès");
            } else {
                toast.error(
                    "Une erreur est survenue lors de la mise à jour de vos préferences"
                );
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.UPDATE_CANDIDATE_PREFERENCES_FAILURE,
                payload: error.message,
            });
        }
    };
};

// preferences freelance
export const fetchFreelancePreferences = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_FREELANCE_PREFERENCES_REQUEST});
        try {
            const response = await ProfileService.fetchFreelanceInfoPreferences();
            // console.log('rrr', response);
            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_FREELANCE_PREFERENCES_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_FREELANCE_PREFERENCES_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const updateFreelancePreferences = (freelancePreferencesData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.UPDATE_FREELANCE_PREFERENCES_REQUEST});
        try {
            const response = await ProfileService.updateFreelanceInfoPreferences(
                freelancePreferencesData
            );
            // console.log('rrr', response);
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.UPDATE_FREELANCE_PREFERENCES_SUCCESS,
                    payload: response.data,
                });
                toast.success("Informations Freelance mises à jour avec succès");
            } else {
                toast.error(
                    "Une erreur est survenue lors de la mise à jour de vos préférences Freelance"
                );
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.UPDATE_FREELANCE_PREFERENCES_FAILURE,
                payload: error.message,
            });
        }
    };
};
// language types actions

export const fetchLanguageTypes = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_LANGUAGE_TYPES_REQUEST});
        try {
            const response = await ProfileService.fetchLanguageTypes();
            // console.log('rrr',response);
            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_LANGUAGE_TYPES_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_LANGUAGE_TYPES_FAILURE,
                payload: error.message,
            });
        }
    };
};

//Experience actions

export const fetchCandidateExperiences = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_CANDIDATE_EXPERIENCE_REQUEST});
        try {
            const response = await ProfileService.fetchCandidateExperiences();
            // console.log("rrrsssssss", response);
            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_CANDIDATE_EXPERIENCE_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_CANDIDATE_EXPERIENCE_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const fetchCandidateExperienceById = (experienceId) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.SELECT_PROFILE_REQUEST});
        try {
            const response = await ProfileService.fetchCandidateExperienceById(
                experienceId
            );
            // console.log('rrrs',response);
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.SELECT_PROFILE_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.SELECT_PROFILE_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const addCandidateExperience = (experienceData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.ADD_CANDIDATE_EXPERIENCE_REQUEST});
        try {
            const response = await ProfileService.addCandidateExperience(
                experienceData
            );
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.ADD_CANDIDATE_EXPERIENCE_SUCCESS,
                    payload: response.data,
                });
                toast.success("Expérience ajoutée avec succès !");
            } else {
                toast.error("Une erreur est survenue lors de l'ajout !");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.ADD_CANDIDATE_EXPERIENCE_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const updateCandidateExperience = (experienceData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.UPDATE_CANDIDATE_EXPERIENCE_REQUEST});
        try {
            const response = await ProfileService.updateCandidateExperience(
                experienceData
            );
            if (response && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.UPDATE_CANDIDATE_EXPERIENCE_SUCCESS,
                    payload: response.data,
                });
                toast.success("Expérience modifiée avec succès !");
            } else {
                toast.error("Une erreur est survenue lors de la modification !");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.UPDATE_CANDIDATE_EXPERIENCE_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const deleteCandidateExperience = (experienceId) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.DELETE_CANDIDATE_EXPERIENCE_REQUEST});
        try {
            const response = await ProfileService.deleteCandidateExperience(
                experienceId
            );
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.DELETE_CANDIDATE_EXPERIENCE_SUCCESS,
                    payload: response.data,
                });
                toast.success("Experience supprimée avec succès !");
            } else {
                toast.error("Une erreur est survenue lors de la suppression !");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.DELETE_CANDIDATE_EXPERIENCE_FAILURE,
                payload: error.message,
            });
        }
    };
};

// Formation

export const fetchCandidateEducations = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_CANDIDATE_EDUCATION_REQUEST});
        try {
            const response = await ProfileService.fetchCandidateEducations();
            // console.log('rrr',response);
            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_CANDIDATE_EDUCATION_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_CANDIDATE_EDUCATION_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const fetchCandidateEducationById = (educationId) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.SELECT_PROFILE_REQUEST});
        try {
            const response = await ProfileService.fetchCandidateEducationById(
                educationId
            );
            // console.log('rrrs',response);
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.SELECT_PROFILE_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.SELECT_PROFILE_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const addCandidateEducation = (educationData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.ADD_CANDIDATE_EDUCATION_REQUEST});
        try {
            const response = await ProfileService.addCandidateEducation(
                educationData
            );
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.ADD_CANDIDATE_EDUCATION_SUCCESS,
                    payload: response.data,
                });
                toast.success("Formation ajoutée avec succès !");
            } else {
                toast.error("Une erreur est survenue lors de l'ajout !");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.ADD_CANDIDATE_EDUCATION_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const updateCandidateEducation = (educationData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.UPDATE_CANDIDATE_EDUCATION_REQUEST});
        try {
            const response = await ProfileService.updateCandidateEducation(
                educationData
            );
            if (response && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.UPDATE_CANDIDATE_EDUCATION_SUCCESS,
                    payload: response.data,
                });
                toast.success("Formation modifiée avec succès !");
            } else {
                toast.error("Une erreur est survenue lors de la modification !");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.UPDATE_CANDIDATE_EDUCATION_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const deleteCandidateEducation = (educationId) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.DELETE_CANDIDATE_EDUCATION_REQUEST});
        try {
            const response = await ProfileService.deleteCandidateEducation(
                educationId
            );
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.DELETE_CANDIDATE_EDUCATION_SUCCESS,
                    payload: response.data,
                });
                toast.success("Formation supprimée avec succès !");
            } else {
                toast.error("Une erreur est survenue lors de la suppression !");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.DELETE_CANDIDATE_EDUCATION_FAILURE,
                payload: error.message,
            });
        }
    };
};

// Compétences

export const fetchCandidateSkills = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_CANDIDATE_SKILLS_REQUEST});
        try {
            const response = await ProfileService.fetchCandidateSkills();
            // console.log('rrr',response);
            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_CANDIDATE_SKILLS_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_CANDIDATE_SKILLS_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const fetchCandidateSkillById = (skillId) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.SELECT_PROFILE_REQUEST});
        try {
            const response = await ProfileService.fetchCandidateSkillById(skillId);
            // console.log('rrrs',response);
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.SELECT_PROFILE_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.SELECT_PROFILE_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const addCandidateSkill = (skillData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.ADD_CANDIDATE_SKILL_REQUEST});
        try {
            const response = await ProfileService.addCandidateSkill(skillData);
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.ADD_CANDIDATE_SKILL_SUCCESS,
                    payload: response.data,
                });
                toast.success("Compétence ajoutée avec succès !");
            } else {
                toast.error("Une erreur est survenue lors de l'ajout !");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.ADD_CANDIDATE_SKILL_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const updateCandidateSkill = (skillData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.UPDATE_CANDIDATE_SKILL_REQUEST});
        try {
            const response = await ProfileService.updateCandidateSkill(skillData);
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.UPDATE_CANDIDATE_SKILL_SUCCESS,
                    payload: response.data,
                });
                toast.success("Compétence modifiée avec succès !");
            } else {
                toast.error("Une erreur est survenue lors de la modification !");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.UPDATE_CANDIDATE_SKILL_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const deleteCandidateSkill = (skillId) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.DELETE_CANDIDATE_SKILL_REQUEST});
        try {
            const response = await ProfileService.deleteCandidateSkill(skillId);
            if (response && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.DELETE_CANDIDATE_SKILL_SUCCESS,
                    payload: response.data,
                });
                toast.success("Compétence supprimée avec succès !");
            } else {
                toast.error("Une erreur est survenue lors de la suppression !");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.DELETE_CANDIDATE_SKILL_FAILURE,
                payload: error.message,
            });
        }
    };
};

// Languages

export const fetchCandidateLanguages = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_CANDIDATE_LANGUAGES_REQUEST});
        try {
            const response = await ProfileService.fetchCandidatelanguages();
            // console.log('rrr',response);
            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_CANDIDATE_LANGUAGES_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_CANDIDATE_LANGUAGES_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const fetchCandidateLanguageById = (languageId) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.SELECT_PROFILE_REQUEST});
        try {
            const response = await ProfileService.fetchCandidateLanguageById(
                languageId
            );
            // console.log('rrrs',response);
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.SELECT_PROFILE_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.SELECT_PROFILE_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const addCandidateLanguage = (languageData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.ADD_CANDIDATE_LANGUAGE_REQUEST});
        try {
            const response = await ProfileService.addCandidateLanguage(languageData);
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.ADD_CANDIDATE_LANGUAGE_SUCCESS,
                    payload: response.data,
                });
                toast.success("langue ajoutée avec succès !");
            } else {
                toast.error("Une erreur est survenue lors de l'ajout !");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.ADD_CANDIDATE_LANGUAGE_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const updateCandidateLanguage = (languageData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.UPDATE_CANDIDATE_LANGUAGE_REQUEST});
        try {
            const response = await ProfileService.updateCandidateLanguage(
                languageData
            );
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.UPDATE_CANDIDATE_LANGUAGE_SUCCESS,
                    payload: response.data,
                });
                toast.success("langue modifiée avec succès !");
            } else {
                toast.error("Une erreur est survenue lors de la modification !");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.UPDATE_CANDIDATE_LANGUAGE_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const deleteCandidateLanguage = (languageId) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.DELETE_CANDIDATE_LANGUAGE_REQUEST});
        try {
            const response = await ProfileService.deleteCandidateLanguage(languageId);
            if (response && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.DELETE_CANDIDATE_LANGUAGE_SUCCESS,
                    payload: response.data,
                });
                toast.success("langue supprimée avec succès !");
            } else {
                toast.error("Une erreur est survenue lors de la suppression !");
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.DELETE_CANDIDATE_LANGUAGE_FAILURE,
                payload: error.message,
            });
        }
    };
};

// Hobbies

export const fetchCandidateHobbies = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_CANDIDATE_HOBBIES_REQUEST});
        try {
            const response = await ProfileService.fetchCandidateHobbies();
            // console.log('rrr',response);
            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_CANDIDATE_HOBBIES_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_CANDIDATE_HOBBIES_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const fetchCandidateHobbyById = (hobbyId) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.SELECT_PROFILE_REQUEST});
        try {
            const response = await ProfileService.fetchCandidateHobbyById(hobbyId);
            // console.log('rrrs',response);
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.SELECT_PROFILE_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.SELECT_PROFILE_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const addCandidateHobby = (hobbyData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.ADD_CANDIDATE_HOBBY_REQUEST});
        try {
            const response = await ProfileService.addCandidateHobby(hobbyData);
            dispatch({
                type: ProfileActionTypes.ADD_CANDIDATE_HOBBY_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.ADD_CANDIDATE_HOBBY_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const updateCandidateHobby = (hobbyData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.UPDATE_CANDIDATE_HOBBY_REQUEST});
        try {
            const response = await ProfileService.updateCandidateHobby(hobbyData);
            dispatch({
                type: ProfileActionTypes.UPDATE_CANDIDATE_HOBBY_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.UPDATE_CANDIDATE_HOBBY_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const deleteCandidateHobby = (hobbyId) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.DELETE_CANDIDATE_HOBBY_REQUEST});
        try {
            const response = await ProfileService.deleteCandidateHobby(hobbyId);
            dispatch({
                type: ProfileActionTypes.DELETE_CANDIDATE_HOBBY_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.DELETE_CANDIDATE_HOBBY_FAILURE,
                payload: error.message,
            });
        }
    };
};

// Info person profile

export const fetchCandidateInfo = () => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_REQUEST});
        try {
            const response = await ProfileService.fetchCandidateInfoPerso();
            // console.log('rrr',response);
            if (response && response.data && response.status === 200) {
                dispatch({
                    type: ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.FETCH_CANDIDATE_INFO_PERSO_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const updateCandidateInfo = (infoData) => {
    return async (dispatch) => {
        dispatch({type: ProfileActionTypes.UPDATE_CANDIDATE_INFO_PERSO_REQUEST});
        try {
            const response = await ProfileService.updateCandidateInfoPerso(infoData);
            dispatch({
                type: ProfileActionTypes.UPDATE_CANDIDATE_INFO_PERSO_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.UPDATE_CANDIDATE_INFO_PERSO_FAILURE,
                payload: error.message,
            });
        }
    };
};

// ProfilePicture

export const uploadProfilePicture = (data) => {
    return async (dispatch) => {
        dispatch({
            type: ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_REQUEST,
        });
        try {
            // console.log("updateProfilePicturesss", pictureData);
            const response = await ProfileService.updateCandidateProfilePicture(data);
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_SUCCESS,
                    payload: response.data,
                });
            } else {
                toast.error(
                    "Une erreur est survenue lors de la mise à jour de la photo de profile"
                );
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const uploadProfileCvCertificate = (cvFile, certificatFile) => {
    return async (dispatch) => {
        dispatch({
            type: ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_REQUEST,
        });
        try {
            // console.log("updateProfilePicturesss", certificatFile);
            const responseCv = await ProfileService.uploadProfileCv(cvFile);
            // console.log("updateProfilePicturesssssss", response);

            if (responseCv && responseCv.data && responseCv.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_SUCCESS,
                    payload: responseCv.data,
                });
                const responseCertificate =
                    await ProfileService.uploadProfileCertificate(certificatFile);

                if (
                    responseCertificate &&
                    responseCertificate.data &&
                    responseCertificate.statusCode === 200
                ) {
                    window.location.reload(); // Recharge toute la page
                } else {
                    toast.error(
                        "Une erreur est survenue lors de la mise à jour du certificat"
                    );
                }
            } else {
                toast.error("Une erreur est survenue lors de la mise à jour du CV");
                const responseCertificate =
                    await ProfileService.uploadProfileCertificate(certificatFile);
                if (
                    responseCertificate &&
                    responseCertificate.data &&
                    responseCertificate.statusCode === 200
                ) {
                    window.location.reload(); // Recharge toute la page
                } else {
                    toast.error(
                        "Une erreur est survenue lors de la mise à jour du certificat"
                    );
                }
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const uploadProfileCertificate = (data) => {
    return async (dispatch) => {
        dispatch({
            type: ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_REQUEST,
        });
        try {
            // console.log("updateProfilePicturesss", pictureData);
            const response = await ProfileService.uploadProfileCertificate(data);
            if (response && response.data && response.statusCode === 200) {
                dispatch({
                    type: ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_SUCCESS,
                    payload: response.data,
                });
                // window.location.reload(); // Recharge toute la page
            }
        } catch (error) {
            dispatch({
                type: ProfileActionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE_FAILURE,
                payload: error.message,
            });
        }
    };
};
