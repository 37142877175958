import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import {fetchJobCategories} from "../../../redux/jobCategory/action";
import {
    addOfferInFavorite,
    fetchAddressOffers,
    fetchOffersByPage,
    removeOfferInFavorite,
} from "../../../redux/freelance/offer/action";


const OfferContext = createContext({
    query: "",
    setQuery: () => {},
    currentPage: 1,
    setCurrentPage: () => {},
    selectProfil: null,
    setSelectProfil: () => {},
    selectedDateFilter: "",
    setSelectedDateFilter: () => {},
    selectedDomaine: [],
    setSelectedDomaine: () => {},
    selectedLocation: [],
    setSelectedLocation: () => {},
    selectedExperience: [],
    setSelectedExperience: () => {},
    handleSearch: () => {},
    handleClickFav: () => {},
    handleClickUnFav: () => {},
});

export const OfferProvider = ({ children }) => {
    const dispatch = useDispatch();
    // États partagés
    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectProfil, setSelectProfil] = useState(null);
    const [selectedDateFilter, setSelectedDateFilter] = useState("");
    const [selectedDomaine, setSelectedDomaine] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedExperience, setSelectedExperience] = useState([]);

    // Charger les données initiales
    useEffect(() => {
        dispatch(fetchJobCategories());
        dispatch(fetchAddressOffers());
    }, [dispatch]);

    // Effet pour le filtrage
    useEffect(() => {
        dispatch(fetchOffersByPage(selectedDateFilter, query, selectedDomaine, selectedLocation, selectedExperience, selectProfil, currentPage));
    }, [selectedDateFilter, selectedDomaine, selectedLocation, query, selectedExperience, selectProfil, currentPage, dispatch]);

    // Handler pour la recherche
    const handleSearch = () => {
        setCurrentPage(1); // Réinitialiser à la première page lors d'une nouvelle recherche
        dispatch(fetchOffersByPage(selectedDateFilter, query, selectedDomaine, selectedLocation, selectedExperience, selectProfil, 1));
    };

    const handleClickFav = useCallback((offerId) => {
        if (!offerId) return;

        dispatch(addOfferInFavorite(offerId))
            .then(() => {
                // Rafraîchir la liste après ajout aux favoris
                return dispatch(fetchOffersByPage(
                    selectedDateFilter,
                    query,
                    selectedDomaine,
                    selectedLocation,
                    selectedExperience,
                    selectProfil,
                    currentPage
                ));
            })
            .catch(error => {
                console.error("Erreur lors de l'ajout aux favoris:", error);
            });
    }, [
        selectedDateFilter,
        query,
        selectedDomaine,
        selectedLocation,
        selectedExperience,
        selectProfil,
        currentPage,
        dispatch
    ]);

    // Handler pour retirer une offre des favoris
    const handleClickUnFav = useCallback((offerId) => {
        if (!offerId) return;

        dispatch(removeOfferInFavorite(offerId))
            .then(() => {
                // Rafraîchir la liste après retrait des favoris
                return dispatch(fetchOffersByPage(
                    selectedDateFilter,
                    query,
                    selectedDomaine,
                    selectedLocation,
                    selectedExperience,
                    selectProfil,
                    currentPage
                ));
            })
            .catch(error => {
                console.error("Erreur lors du retrait des favoris:", error);
            });
    }, [
        selectedDateFilter,
        query,
        selectedDomaine,
        selectedLocation,
        selectedExperience,
        selectProfil,
        currentPage,
        dispatch
    ]);

    return (
        <OfferContext.Provider
            value={{
                query,
                setQuery,
                currentPage,
                setCurrentPage,
                selectProfil,
                setSelectProfil,
                selectedDateFilter,
                setSelectedDateFilter,
                selectedDomaine,
                setSelectedDomaine,
                selectedLocation,
                setSelectedLocation,
                selectedExperience,
                setSelectedExperience,
                handleSearch,
                handleClickFav,
                handleClickUnFav,
            }}
        >
            {children}
        </OfferContext.Provider>
    );
};

export const useOfferContext = () => useContext(OfferContext);
