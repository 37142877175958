import React, { useState, useRef, useEffect } from 'react';
import { Layout, Menu, List, Avatar, Input, Button, Divider } from 'antd';
import { MessageOutlined, SendOutlined } from '@ant-design/icons';

const { Header, Content, Sider } = Layout;

const messagesData = [
  {
    id: 1,
    user: 'Paul Ekra',
    text: 'Salut, comment ça va ?',
    time: '10:30 AM',
  },
  {
    id: 2,
    user: 'N\'dri Eric',
    text: 'Ça va bien, merci ! Et toi ?',
    time: '10:35 AM',
  },
  {
    id: 3,
    user: 'Janine Mauricette',
    text: 'Super, merci ! On se voit plus tard ?',
    time: '10:40 AM',
  },
];

const usersData = [
  {
    id: 1,
    name: 'Paul Ekra',
    avatar: 'https://i.pravatar.cc/150?img=1', // Remplacez par une image spécifique
  },
  {
    id: 2,
    name: 'N\'dri Eric',
    avatar: 'https://i.pravatar.cc/150?img=2', // Remplacez par une image spécifique
  },
  {
    id: 3,
    name: 'Janine Mauricette',
    avatar: 'https://i.pravatar.cc/150?img=3', // Remplacez par une image spécifique
  },
  {
    id: 4,
    name: 'Damo Yvan',
    avatar: 'https://i.pravatar.cc/150?img=4', // Remplacez par une image spécifique
  },
];

const MessengerPage = () => {
  const [selectedUser, setSelectedUser] = useState(usersData[0]);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState(messagesData);
  const messagesContainerRef = useRef(null); // Référence pour le conteneur des messages

  // Fonction pour faire défiler vers le bas
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  // Déclencher le défilement à chaque mise à jour des messages
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = () => {
    if (message.trim()) {
      const newMessage = {
        id: messages.length + 1,
        user: 'Vous',
        text: message,
        time: new Date().toLocaleTimeString(),
      };
      setMessages([...messages, newMessage]);
      setMessage('');
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* Sidebar pour les utilisateurs */}
      <Sider width={250} theme="light">
        <div style={{ padding: '16px', fontWeight: 'bold', fontSize: '18px' }}>
          <MessageOutlined /> Conversations
        </div>
        <Divider style={{ margin: 0 }} />
        <Menu mode="inline" defaultSelectedKeys={['1']}>
          {usersData.map((user) => (
            <Menu.Item
              key={user.id}
              icon={<Avatar src={user.avatar} size="small" />}
              onClick={() => setSelectedUser(user)}
            >
              {user.name}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>

      {/* Contenu principal */}
      <Layout>
        <Header style={{ background: '#fff', padding: '0 16px', borderBottom: '1px solid #f0f0f0' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={selectedUser.avatar} size="large" />
            <div style={{ marginLeft: '12px', fontSize: '18px', fontWeight: '500' }}>
              {selectedUser.name}
            </div>
          </div>
        </Header>
        <Content style={{ padding: '24px', background: '#f0f2f5' }}>
          <div
            ref={messagesContainerRef} // Référence pour le conteneur des messages
            style={{
              background: '#fff',
              padding: '16px',
              borderRadius: '8px',
              height: '60vh',
              overflowY: 'auto',
              scrollbarWidth: 'none', // Masquer la barre de défilement pour Firefox
              msOverflowStyle: 'none', // Masquer la barre de défilement pour IE/Edge
            }}
          >
            <List
              dataSource={messages}
              renderItem={(item) => (
                <List.Item
                  style={{
                    justifyContent: item.user === 'Vous' ? 'flex-end' : 'flex-start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: item.user === 'Vous' ? 'flex-end' : 'flex-start',
                      maxWidth: '70%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        flexDirection: item.user === 'Vous' ? 'row-reverse' : 'row',
                      }}
                    >
                      <Avatar
                        src={item.user === 'Vous' ? 'https://i.pravatar.cc/150?img=5' : selectedUser.avatar}
                      />
                      <div style={{ fontWeight: '500' }}>{item.user}</div>
                    </div>
                    <div
                      style={{
                        background: item.user === 'Vous' ? '#1890ff' : '#f0f0f0',
                        color: item.user === 'Vous' ? '#fff' : '#000',
                        padding: '8px 12px',
                        borderRadius: item.user === 'Vous' ? '12px 12px 0 12px' : '12px 12px 12px 0',
                        marginTop: '4px',
                      }}
                    >
                      {item.text}
                    </div>
                    <div style={{ color: '#999', fontSize: '12px', marginTop: '4px' }}>
                      {item.time}
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </div>
          <div style={{ marginTop: '16px', display: 'flex', gap: '8px' }}>
            <Input
              placeholder="Tapez un message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onPressEnter={handleSendMessage}
            />
            <Button className="btn-primary btn-hover" icon={<SendOutlined />} onClick={handleSendMessage}>
              Envoyer
            </Button>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MessengerPage;