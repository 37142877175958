import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
// import { Collapse } from "antd";
import { Nav, NavLink, TabContent, TabPane, NavItem } from "reactstrap";
import classnames from "classnames";
//Import Images
import contactImage from "../../assets/images/contact.png";
// import FormSection from "./FormSection"; // Assurez-vous que le chemin est correct
import CalendarComponent from "./CalendarComponent";

const ContactContent = () => {
  const [activeTab, setActiveTab] = useState("1");
  // const [openSection, setOpenSection] = useState(null);

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // const items = [
  //   {
  //     key: "formationCertification",
  //     label: "Formation / Certification",
  //     children: (
  //       <FormSection
  //         isOpen={true} // Dans le cas du Collapse, vous n'avez pas besoin de gérer manuellement l'ouverture
  //         sectionId="formationCertification"
  //         sectionTitle="Formation / Certification"
  //       />
  //     ),
  //   },
  //   {
  //     key: "contratCondition",
  //     label: "Contrat / Conditions",
  //     children: (
  //       <FormSection
  //         isOpen={true}
  //         sectionId="contratCondition"
  //         sectionTitle="Contrat / Conditions"
  //       />
  //     ),
  //   },
  //   {
  //     key: "salary",
  //     label: "Salaires / devis",
  //     children: (
  //       <FormSection
  //         isOpen={true}
  //         sectionId="salary"
  //         sectionTitle="Salaires / devis"
  //       />
  //     ),
  //   },
  //   {
  //     key: "cvLifting",
  //     label: "CV Lifting",
  //     children: (
  //       <FormSection
  //         isOpen={true}
  //         sectionId="cvLifting"
  //         sectionTitle="CV Lifting"
  //       />
  //     ),
  //   },
  //   {
  //     key: "others",
  //     label: "Autres",
  //     children: (
  //       <FormSection isOpen={true} sectionId="others" sectionTitle="Autres" />
  //     ),
  //   },
  // ];

  // const toggleSection = (section) => {
  //   setOpenSection(openSection === section ? null : section);
  // };

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center mt-3">
            <div className="section-title mt-4 mt-lg-0">
              <h4 className="title mb-5">
                Commencer à travailler avec Eburnie Hub c'est tout simplement
                génial!
              </h4>
            </div>
            <Col lg={7}>
              <Nav
                className="profile-content-nav nav-pills border-bottom mb-4"
                id="pills-tab"
                role="tablist"
              >
                <NavItem role="presentation">
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      tabChange("1");
                    }}
                    type="button"
                  >
                    Prendre RDV
                  </NavLink>
                </NavItem>
                {/* <NavItem role="presentation">
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      tabChange("2");
                    }}
                    type="button"
                  >
                    Autres demandes
                  </NavLink>
                </NavItem> */}
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <CalendarComponent />
                </TabPane>
                {/* <TabPane tabId="2">
                  <Collapse accordion items={items} />
                </TabPane> */}
              </TabContent>
            </Col>
            <Col lg={5} className="ms-auto order-first order-lg-last">
              <div className="text-center">
                <img src={contactImage} alt="" className="img-fluid" />
              </div>
              <div className="mt-4 pt-3">
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-map-marker"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">Abidjan, Cocody Riviera 2</p>
                  </div>
                </div>
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-envelope"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">infos@eburniehub.com</p>
                  </div>
                </div>
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-phone-alt"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">(+225) 0758 661 307</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ContactContent;
