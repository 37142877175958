import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Section from "../Offers/Section";
import JobSearchOptions from "../Offers/JobSearchOptions";
import Sidebar from "../Offers/Sidebar";
import JobVacancyPost2 from "../Offers/JobVacancyPost2";
import { OfferProvider } from "./OfferContext";

const OfferList = () => {
  useEffect(() => {
    document.title = "Offres freelances | RecruTech";
  }, []);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <OfferProvider>
          <Container>
            <Row>
              <Col lg={8}>
                <div className="me-lg-5">
                  <JobSearchOptions />
                  <JobVacancyPost2 />
                </div>
              </Col>
              <Sidebar />
            </Row>
          </Container>
        </OfferProvider>
      </section>
    </React.Fragment>
  );
};
export default OfferList;
