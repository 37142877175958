import React, { useState } from "react";
import { Button, Col, Collapse, Input, Label } from "reactstrap";
import { useSelector } from "react-redux";
import { useOfferContext } from "./OfferContext";

const Sidebar = () => {
  const { selectedDateFilter, setSelectedDateFilter, selectedDomaine, setSelectedDomaine, selectedLocation, setSelectedLocation, selectedExperience, setSelectedExperience, selectProfil, setSelectProfil, setCurrentPage } = useOfferContext();

  const [toggleFirst, setToggleFirst] = useState(true);
  const [toggleSecond, setToggleSecond] = useState(true);
  const [toggleFourth, setToggleFourth] = useState(true);
  const [toggleFifth, setToggleFifth] = useState(true);

  const { jobCategories } = useSelector((state) => state.jobCategoryReducer);
  const { jobAddresses } = useSelector((state) => state.offerReducer);
  const { infoPersoDetails } = useSelector((state) => state.profileReducer);

  // Handle changes in checkboxes for domains
  const handleDomaineChange = (event) => {
    const { value, checked } = event.target;
    setSelectedDomaine((prev) =>
        checked ? [...new Set([...prev, value])] : prev.filter((item) => item !== value)
    );
    setCurrentPage(1);
  };

  // Handle changes in checkboxes for locations
  const handleLocationChange = (event) => {
    const { value, checked } = event.target;
    setSelectedLocation((prev) =>
        checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
    setCurrentPage(1);
  };

  // Handle date filter radio change
  const handleDateFilterChange = (event) => {
    setSelectedDateFilter(event.target.value);
    setCurrentPage(1);
  };
  const handleExperienceChange = (event) => {
    const { value, checked } = event.target;
    setSelectedExperience((prev) =>
        checked ? [...new Set([...prev, value])] : prev.filter((item) => item !== value)
    );
    setCurrentPage(1);
  };

  // Réinitialiser les filtres
  const resetFilters = () => {
    setSelectedDateFilter("all"); // Valeur par défaut pour les dates
    setSelectedDomaine([]); // Réinitialiser les domaines
    setSelectedLocation([]); // Réinitialiser les locations
    setSelectedExperience([]); // Réinitialiser l'expérience
    setSelectProfil(""); // Réinitialiser le profil
    setCurrentPage(1); // Réinitialiser la page
  };


  return (
      <React.Fragment>
        <Col lg={4}>
          <div className="side-bar mt-5 mt-lg-0">
            <div className="accordion" id="accordionExample">
                {/* Recommendation Section */}
                {infoPersoDetails?.data?.customerType === "FREELANCER" && (
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header" id="tagCloud">
                    <Button
                        className="accordion-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setToggleFirst(!toggleFirst);
                        }}
                        role="button"
                        id="collapseExample"
                    >
                      Profil
                    </Button>
                  </h2>
                  <Collapse isOpen={toggleFirst}>
                    <div className="accordion-body" style={{ maxHeight: "300px", overflowY: "auto" }}>
                      <div className="side-title">
                        <div className="form-check mt-2">
                          <Input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              value="true"
                              checked={selectProfil === "true"}
                              onChange={(e) => {
                                const newValue = e.target.checked ? "true" : "";
                                setSelectProfil(newValue);
                                setCurrentPage(1);
                              }}
                          />
                          <Label className="form-check-label ms-2 text-muted" htmlFor="flexCheckDefault">
                            Recommandations
                          </Label>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              )}
              {/* Domaine Section */}
              <div className="accordion-item mt-3">
                <h2 className="accordion-header" id="tagCloud">
                  <Button
                      className="accordion-button"
                      onClick={(e) => {
                        e.preventDefault();
                        setToggleFifth(!toggleFifth);
                      }}
                      role="button"
                      id="collapseExample"
                  >
                    Domaine
                  </Button>
                </h2>
                <Collapse isOpen={toggleFifth}>
                  <div className="accordion-body" style={{ maxHeight: "300px", overflowY: "auto" }}>
                    <div className="side-title">
                      {/* Liste des domaines */}
                      {jobCategories.map((domain) => (
                          <div key={domain.id} className="form-check mt-2">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                id={`domain-${domain.id}`}
                                value={String(domain.id)}
                                checked={selectedDomaine.includes(String(domain.id))}
                                onChange={handleDomaineChange}
                            />
                            <Label className="form-check-label ms-2 text-muted" htmlFor={domain.id}>
                              {domain.name}
                            </Label>
                          </div>
                      ))}
                    </div>
                  </div>
                </Collapse>
              </div>

              {/* Location Section */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="locationOne">
                  <Button
                      className="accordion-button"
                      onClick={(e) => {
                        e.preventDefault();
                        setToggleFirst(!toggleFirst);
                      }}
                      role="button"
                      id="collapseExample"
                  >
                    Pays
                  </Button>
                </h2>
                <Collapse isOpen={toggleFirst}>
                  <div className="accordion-body" style={{ maxHeight: "300px", overflowY: "auto" }}>
                    {jobAddresses?.map((address) => (
                        <div key={address.id} className="form-check mt-2">
                          <Input
                              className="form-check-input"
                              type="checkbox"
                              id={address.id}
                              value={address.country}
                              checked={selectedLocation.includes(address.country)}
                              onChange={handleLocationChange}
                          />
                          <Label className="form-check-label ms-2 text-muted" htmlFor={address.id}>
                            {address.country}
                          </Label>
                        </div>
                    ))}
                  </div>
                </Collapse>
              </div>

              {/* Experience Section */}
              <div className="accordion-item mt-3">
                <h2 className="accordion-header" id="experienceOne">
                  <Button
                      className="accordion-button"
                      onClick={(e) => {
                        e.preventDefault();
                        setToggleSecond(!toggleSecond);
                      }}
                      role="button"
                      id="collapseExample"
                  >
                    Expérience
                  </Button>
                </h2>
                <Collapse isOpen={toggleSecond}>
                  <div className="accordion-body">
                    <div className="side-title">
                      {[
                        { key: "0", label: "Sans expérience" },
                        { key: "0-3", label: "0-3 ans" },
                        { key: "3-6", label: "3-6 ans" },
                        { key: "6-plus", label: "Plus de 6 ans" },
                      ].map(({ key, label }) => (
                          <div key={key} className="form-check mt-2">
                            <Input
                                className="form-check-input"
                                type="checkbox"
                                id={key}
                                value={key}
                                checked={selectedExperience.includes(key)} // Compare avec la clé ici
                                onChange={handleExperienceChange}
                            />
                            <Label className="form-check-label ms-2 text-muted" htmlFor={`dateFilter-${key}`}>
                              {label}
                            </Label>
                          </div>
                      ))}
                    </div>
                  </div>
                </Collapse>
              </div>

              {/* Date Section */}
              <div className="accordion-item mt-3">
                <h2 className="accordion-header" id="datePosted">
                  <Button
                      className="accordion-button"
                      onClick={(e) => {
                        e.preventDefault();
                        setToggleFourth(!toggleFourth);
                      }}
                      role="button"
                  >
                    Date de publication
                  </Button>
                </h2>
                <Collapse isOpen={toggleFourth}>
                  <div className="accordion-body">
                    <div className="side-title form-check-all">
                      {[
                        { key: "all", label: "Tout" },
                        { key: "lastHour", label: "Dernière Heure" },
                        { key: "last24Hours", label: "Dernières 24 Heures" },
                        { key: "last7Days", label: "Derniers 7 Jours" },
                        { key: "last14Days", label: "Derniers 14 Jours" },
                        { key: "last30Days", label: "Derniers 30 Jours" },
                      ].map(({ key, label }) => (
                          <div className="form-check mt-2" key={key}>
                            <Input
                                className="form-check-input"
                                type="radio"
                                name="dateFilter"
                                value={key}
                                id={`dateFilter-${key}`}
                                checked={selectedDateFilter === key}
                                onChange={handleDateFilterChange}
                            />
                            <Label className="form-check-label ms-2 text-muted" htmlFor={`dateFilter-${key}`}>
                              {label}
                            </Label>
                          </div>
                      ))}
                    </div>
                  </div>
                </Collapse>
              </div>
              {/* Reset Filters Button */}
              <Button
                  color="danger"
                  onClick={resetFilters}
                  className="mt-4"
                  style={{ width: "100%" }}
              >
                Réinitialiser les filtres
              </Button>
            </div>
          </div>
        </Col>
      </React.Fragment>
  );
};

export default Sidebar;