import React, { useState } from "react";
import { Col, Row, Modal, ModalBody, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import TokenService from "../../../service/TokenService";
import {
  formatAddress,
  renderHTMLContent,
  sliceDescription,
} from "../../../utils";
import { CustomResponsivePagination } from "../../../Layout/CommonLayout/Pagination";
import { FiveStarRating } from "../../../Layout/CommonLayout/FiveStarRating";
import { useOfferContext } from "./OfferContext";
import Lottie from "react-lottie";
import JobCardItemSkeleton from "../../Jobs/JobGrid/JobCardItemSkeleton";

import * as errorAnimationData from "../../../../src/assets/error.json"; // Animation pour l'erreur
import * as emptyAnimationData from "../../../../src/assets/emptyData.json"; // Animation pour données vides

const JobVacancyPost2 = () => {
  const getUser = TokenService.getUser();
  const { allOffers, loading, error } = useSelector(
    (state) => state.offerReducer
  );
  const { currentPage, setCurrentPage, handleClickFav } = useOfferContext();
  const totalPages = allOffers?.total_pages;

  // Modal
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  // Paramètres pour l'animation Lottie d'erreur
  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // Paramètres pour l'animation Lottie de données vides
  const emptyOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Row className="align-items-center mb-1">
          <Col md={12}>
            <JobCardItemSkeleton />
          </Col>
          <Col md={12}>
            <JobCardItemSkeleton />
          </Col>
        </Row>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-0">
        <Lottie options={errorOptions} height={300} width={300} />
        <p className="text-danger mt-0">Une erreur s'est produite : {error}</p>
      </div>
    );
  }

  if (allOffers?.data?.length === 0) {
    return (
      <div className="text-center mt-0">
        <Lottie options={emptyOptions} height={300} width={300} />
        <p className="text-muted mt-0">
          Aucune offre trouvée. Veuillez réessayer plus tard.
        </p>
      </div>
    );
  }

  return (
    <React.Fragment>
      {allOffers?.data?.map((offer, key) => (
        <div key={key} className="job-box card mt-4 shadow-sm border-0">
          <div className="p-4">
            <Row className="align-items-center">
              <Col lg={11}>
                <div>
                  &nbsp;&nbsp;&nbsp;
                  <h5 className="fs-17 mb-1 d-flex align-items-center">
                    {/* Logo et Titre à côté */}
                    <img
                      src={
                        offer.enterprise_logo ||
                        "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                      }
                      alt={offer.enterprise_name || "Entreprise"}
                      className="img-fluid rounded-3"
                      style={{ maxWidth: "60px", height: "auto" }}
                    />{" "}
                    &nbsp;&nbsp;
                    <Link
                      to={`/mission-details/${offer.slug}`}
                      className="text-dark fw-bold"
                    >
                      {offer.enterprise_name}
                    </Link>
                  </h5>
                  <p className="text-muted mb-3 mt-2 d-flex justify-content-between">
                    <span className="d-flex text-primary align-items-center">
                      {" "}
                      {offer.title}
                    </span>
                  </p>
                  <div className="job-detail-desc">
                    <div
                      dangerouslySetInnerHTML={renderHTMLContent(
                        sliceDescription(offer.description, 200)
                      )}
                    />
                  </div>
                  <div className="mt-3">
                    <ul className="list-inline d-flex flex-wrap gap-2">
                      {offer.skills?.map((skill, index) => (
                        <li className="list-inline-item" key={index}>
                          <span className="badge bg-info">
                            {skill.skill.name}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
            {getUser && (
              <div className="bookmark-label text-center">
                <Link
                  to="#"
                  onClick={() => {
                    handleClickFav(offer?.id);
                  }}
                  className={`align-middle ${
                    offer?.has_favorite ? "disabled-link" : ""
                  }`}
                >
                  <i
                    className={`mdi mdi-star fs-18 ${
                      offer?.has_favorite ? "text-warning" : "text-muted"
                    }`}
                  ></i>{" "}
                  {/* Icône étoile avec couleur dynamique */}
                </Link>
              </div>
            )}
          </div>
          <div className="p-3 bg-light">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <div>
                <span className="d-flex align-items-center">
                  <i className="mdi mdi-map-marker text-end"></i>{" "}
                  {offer.address
                    ? formatAddress(offer.address)
                    : "Non renseigné"}
                </span>
              </div>
              <FiveStarRating rating={0} />
              <small className="text-muted fw-normal">
                <i className="uil uil-hourglass"></i> {offer.duration} jours
              </small>
              <p className="text-muted mb-0">
                <i className="uil uil-wallet"></i> {Math.floor(offer?.budget)}{" "}
                {offer.devise}
              </p>
            </div>
          </div>
        </div>
      ))}

      {/* Modal de candidature */}
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalBody className="p-4">
          <div className="text-center mb-4">
            <h5 className="modal-title">Postuler à cette Offre</h5>
          </div>
          <button
            type="button"
            onClick={toggleModal}
            className="btn-close position-absolute top-0 end-0 m-3"
          ></button>

          <div className="mb-3">
            <Label for="specialization">Titre ou Spécialisation</Label>
            <Input
              type="text"
              id="specialization"
              placeholder="Ex. Développeur Full Stack"
              required
            />
          </div>

          <div className="mb-3">
            <Label for="coverLetter">Lettre de Motivation</Label>
            <textarea
              className="form-control"
              id="coverLetter"
              rows="5"
              placeholder="Entrez votre lettre de motivation"
              required
            ></textarea>
          </div>

          <div className="mb-3">
            <Label for="resumeUpload">Téléchargez votre CV</Label>
            <Input type="file" id="resumeUpload" required />
          </div>

          <div className="mb-3">
            <Label for="skills">Compétences</Label>
            <Input
              type="text"
              id="skills"
              placeholder="Listez vos compétences (ex. JavaScript, React)"
              required
            />
          </div>

          <div className="mb-3">
            <Label for="rate">Tarif (€/heure)</Label>
            <Input
              type="number"
              id="rate"
              placeholder="Entrez votre tarif horaire"
              required
            />
          </div>

          <div className="mb-3">
            <Label for="availability">Disponibilité</Label>
            <Input
              type="text"
              id="availability"
              placeholder="Quand pouvez-vous commencer ?"
              required
            />
          </div>

          <button type="submit" className="btn btn-primary w-100">
            Envoyer ma Candidature
          </button>
        </ModalBody>
      </Modal>

      {totalPages > 1 && (
        <CustomResponsivePagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      )}
      <ToastContainer />
    </React.Fragment>
  );
};

export default JobVacancyPost2;
