import JobService from "../../../service/job";
import * as JobActionTypes from "../types";
import { toast } from "react-toastify"; // Import toast

export const fetchJobsPaged = (page, companyId) => {
  return async (dispatch) => {
    dispatch({ type: JobActionTypes.FETCH_JOBS_REQUEST });

    try {
      const response = await JobService.fetchJobsPaged(page, companyId);
      dispatch({
        type: JobActionTypes.FETCH_JOBS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: JobActionTypes.FETCH_JOBS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchSimilarJobsPaged = (jobId) => {
  return async (dispatch) => {
    dispatch({ type: JobActionTypes.FETCH_SIMILAR_JOBS_REQUEST });

    try {
      const response = await JobService.fetchSimilarJobsPaged(jobId);
      dispatch({
        type: JobActionTypes.FETCH_SIMILAR_JOBS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: JobActionTypes.FETCH_SIMILAR_JOBS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchRecentJobs = () => {
  return async (dispatch) => {
    dispatch({ type: JobActionTypes.FETCH_RECENT_JOBS_REQUEST });
    try {
      const response = await JobService.fetchRecentJobs();
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({
          type: JobActionTypes.FETCH_RECENT_JOBS_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: JobActionTypes.FETCH_RECENT_JOBS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchJobDetails = (jobSlug) => {
  return async (dispatch) => {
    dispatch({ type: JobActionTypes.SELECT_JOB_REQUEST });
    try {
      // console.log("responsesss", jobSlug);
      const response = await JobService.fetchJobdetails(jobSlug);
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({
          type: JobActionTypes.SELECT_JOB_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: JobActionTypes.SELECT_JOB_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchCompanyDetails = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: JobActionTypes.SELECT_JOB_REQUEST });
    try {
      const response = await JobService.fetchCompanyDetails(companyId);
      // console.log("response", response);
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({
          type: JobActionTypes.SELECT_JOB_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: JobActionTypes.SELECT_JOB_FAILURE,
        payload: error.message,
      });
    }
  };
};

// apply job

export const applyJob = (jobId) => {
  return async (dispatch) => {
    try {
      // console.log("responsesss", jobId);
      const response = await JobService.applyJob(jobId);
      if (response && response.data && response.statusCode === 200) {
        dispatch({
          type: JobActionTypes.APPLY_JOB_SUCCESS,
          payload: response.data,
        });
        toast.success("Candidature reçue !", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      // dispatch({
      //   type: JobActionTypes.APPLY_JOB_FAILURE,
      //   payload: error.message,
      // });
    }
  };
};

export const fetchApplicationJobs = () => {
  return async (dispatch) => {
    dispatch({ type: JobActionTypes.FETCH_JOBS_REQUEST });

    try {
      const response = await JobService.fetchApplications();
      if (response && response.data && response.statusCode === 200) {
        dispatch({
          type: JobActionTypes.FETCH_JOBS_SUCCESS,
          payload: response,
        });
      }
    } catch (error) {
      dispatch({
        type: JobActionTypes.FETCH_JOBS_FAILURE,
        payload: error.message,
      });
    }
  };
};
