import React, { forwardRef } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import {
  formatAddress,
  renderHTMLContent,
  sliceDescription,
} from "../../../utils";

const JobCardItem = forwardRef(({ job }, firstCardRef) => {
  const getBadgeClass = (contract) => {
    switch (contract) {
      case "CDI":
        return "badge bg-success-subtle text-success fs-10 me-2";
      case "CDD":
        return "badge bg-danger-subtle text-danger fs-10 me-2";
      case "Freelance":
        return "badge bg-info-subtle text-info fs-10 mt-1 me-2";
      case "Stage":
        return "badge bg-warning-subtle text-warning fs-10 me-2";
      default:
        return "badge bg-secondary-subtle text-secondary fs-10 me-2";
    }
  };

  return (
    <Col md={6} ref={firstCardRef}>
      <div className="job-box bookmark-post card mt-4 h-100 d-flex flex-column">
        <div className="p-4 flex-grow-1">
          <Row className="align-items-center mb-1">
            <Col md={9}>
              <div className="text-start">
                <h6 className="fs-17 mb-1">
                  <Link
                    to={{ pathname: `/jobdetails/${job?.slug}` }}
                    className="primary-link"
                  >
                    {job.title}
                  </Link>
                </h6>
              </div>
            </Col>
            <Col md={3}>
              {" "}
              {job?.company ? (
                <div className="text-end mb-4">
                  <Link
                    to={{ pathname: `/companydetails/${job?.company?.id}` }}
                  >
                    <img
                      src={
                        job?.company?.logo ||
                        "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                      }
                      alt=""
                      className="img-fluid rounded-3"
                      width={40}
                      height={40}
                    />
                  </Link>
                </div>
              ) : (
                <div className="text-end mb-4">
                  <img
                    src={
                      job?.company?.logo ||
                      "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                    }
                    alt=""
                    className="img-fluid rounded-3"
                    width={40}
                    height={40}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row className=" text-align-justify mb-0">
            <Col md={7}>
              <div
                style={{ textAlign: "justify" }}
                className="text-start mb-0 mb-md-0"
              >
                <div className="ms-0">
                  {job?.contractType.map((contract) => (
                    <span key={contract} className={getBadgeClass(contract)}>
                      {contract}
                    </span>
                  ))}
                </div>{" "}
              </div>
            </Col>
            <Col md={5}>
              <div className=" text-end mb-0 mb-md-0">
                <p className="text-muted fs-14 mb-0">{job?.company?.name}</p>
              </div>
            </Col>
          </Row>
          <Row className=" text-align-justify mt-3">
            <Col md={12}>
              <div className="job-detail-desc">
                <div
                  dangerouslySetInnerHTML={renderHTMLContent(
                    sliceDescription(job?.description, 150)
                  )}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="p-3 bg-light">
          <Row className="justify-content-between">
            <Col md={8}>
              <div className="d-flex mb-0">
                <div className="flex-shrink-0">
                  <i className="mdi mdi-map-marker text-primary me-1"></i>
                </div>
                <p className="text-muted mb-0">{formatAddress(job?.address)}</p>
              </div>
            </Col>
            <Col lg={4} md={3}>
              <div className="d-flex mb-0">
                <div className="flex-shrink-0">
                  <i className="uil uil-clock-three text-primary me-1"></i>
                </div>
                <p className="text-muted mb-0">{job?.durationSincePosting}</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
});

export default JobCardItem;
