import React from "react";
import { Col, Container, Row, Nav } from "reactstrap";
import { Link } from "react-router-dom";
import "./JobGrid.css"; // Assurez-vous d'importer le fichier CSS

// import Selected from "./Selected";

const Section = () => {
  return (
    <React.Fragment>
      <section className="custom-background-section-job">
        <Container>
          <Row className="justify-content-center mb-3">
            <Col md={8} className="col-md-6">
              <div className="text-center text-white">
                <h3 className="mb-4">
                  Découvrez votre prochaine opportunité professionnelle.
                </h3>
                <div className="page-next">
                  <Nav
                    className="d-inline-block"
                    aria-label="breadcrumb text-center"
                  >
                    <ol className="breadcrumb justify-content-center">
                      <li className="breadcrumb-item">
                        <Link to="/">Accueil</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Emplois</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {" "}
                        Liste Des Emplois{" "}
                      </li>
                    </ol>
                  </Nav>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
