import React, {useEffect, useState} from "react";
import {
    Col,
    Row,
    Nav,
    NavLink,
    TabContent,
    TabPane,
    Card,
    Input,
    Form,
    NavItem,
    CardBody,
    Label,
    Modal,
    Button as ReactstrapButton,
} from "reactstrap";
import {ToastContainer} from "react-toastify";
import Select from "react-select";
import {PlusOutlined, EditOutlined, DeleteOutlined} from "@ant-design/icons";
import {Popconfirm, Button, message, Skeleton, Rate} from "antd";
import classnames from "classnames";
import AddExperienceForm from "./CvForm/ExperienceForm";
import AddEducationForm from "./CvForm/TrainingForm";
import AddSkillForm from "./CvForm/SkillForm";
import AddLanguageForm from "./CvForm/LanguageForm";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchCandidateExperiences,
    deleteCandidateExperience,
    fetchCandidateEducations,
    deleteCandidateEducation,
    fetchCandidateSkills,
    deleteCandidateSkill,
    deleteCandidateLanguage,
    fetchCandidateLanguages,
    fetchCandidateInfoPerso,
    updateCandidateInfoPerso,
    uploadProfilePicture,
    uploadProfileCvCertificate,
    fetchAllDomain,
    fetchContractTypes,
    fetchCandidateSituation,
    updateCandidateSituation,
    updatePortfolioInfo,
    fetchPortfolioInfo,
    fetchCandidatePreferences,
    updateCandidatePreferences, updatePortfolio,
    fetchFreelancePreferences,
    updateFreelancePreferences,

} from "../../../redux/profile/action";
//Images Import
// import userImage2 from "../../../assets/images/user/img-02.jpg";
import {Link} from "react-router-dom";

const RightSideContent = () => {
    const languageLevelMap = {
        BEGINNER: 1,
        INTERMEDIARY: 2,
        EXPERT: 3,
    };
    const [profileImage, setProfileImage] = useState(null);
    const [user, setUser] = useState(null);
    const [selectedImageFile, setSelectedImageFile] = useState(null);
    const [selectedCvFile, setSelectedCvFile] = useState(null);
    const [selectedCertificatFile, setSelectedCertificatFile] = useState(null);

    const [activeTab, setActiveTab] = useState("1");
    const dispatch = useDispatch();


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImage(URL.createObjectURL(file));
            setSelectedImageFile(file);
        }
    };

    const handleCvChange = (event) => {
        const file = event.target.files[0];
        if (file && file.size > 10485760) {
            // 10MB en octets
            message.error(
                "Le fichier est trop volumineux. La taille maximale autorisée est de 10 Mo."
            );
            event.target.value = ""; // Réinitialise le champ
            return;
        } else {
            setSelectedCvFile(file);
        }
    };

    const handleCertificatChange = (event) => {
        const file = event.target.files[0];
        if (file && file.size > 10485760) {
            // 10MB en octets
            message.error(
                "Le fichier est trop volumineux. La taille maximale autorisée est de 10 Mo."
            );
            event.target.value = ""; // Réinitialise le champ
            return;
        } else {
            setSelectedCertificatFile(file);
        }
    };
    // Ma situation
    const [mySituationFormData, setMySituationFormData] = useState({
        summary: "",
        latestPosition: "",
        currentState: "",
        available: "",
        grossSalary: 0,
        recognizedDisabilityStatus: false,
        jobCategoryId: null,
    });

    // Ma situation Freelance
    const [freelanceSituationFormData, setFreelanceSituationFormData] = useState({
        summary: "",
        latestPosition: "",
        currentState: "",
        available: "",
        grossSalary: 0,
        recognizedDisabilityStatus: false,
        jobCategoryId: null,
    });

    // Mes Preferences
    const [preferencesFormData, setPreferencesFormData] = useState({
        jobCategoryIds: [],
        contractTypeIds: [],
        jobAddress: [{country: ""}],
    })
    // Mes Préférences Freelance
    const [freelancePreferencesFormData, setFreelancePreferencesFormData] = useState({
        jobCategoryIds: [],
        tags: [],
        jobAddress: [{country: ""}],
    });

    const {
        allExperiences,
        allEducations,
        allSkills,
        allLanguages,
        loading,
        error,
        educationLoading,
        educationError,
        skillLoading,
        skillError,
        languageLoading,
        languageError,
        infoPersoDetails,
        infoPersoLoading,
        loadingFile,
        // errorFile,
        // infoPersoError,
        domainTypes,
        contractTypes,
        infoSituationDetails,
        /*infoFreelanceSituationDetails,*/
        infoPreferenceDetails,
    } = useSelector((state) => state.profileReducer);

    const [infoPersoFormData, setInfoPersoFormData] = useState({
        firstName: "",
        lastName: "",
        whatsapp: "",
        birthday: "",
        street: "",
        city: "",
        country: "",
        zipCode: "",
        summary: "",
        urlFacebook: "",
        urlTwitter: "",
        urlLinkedIn: "",
        urlCV: "",
        urlCertificate: "",
    });

    useEffect(() => {
        dispatch(fetchCandidateExperiences());
        dispatch(fetchCandidateEducations());
        dispatch(fetchCandidateSkills());
        dispatch(fetchCandidateLanguages());
        dispatch(fetchCandidateInfoPerso());
        dispatch(fetchAllDomain());
        dispatch(fetchContractTypes());
        dispatch(fetchCandidateSituation());
        dispatch(fetchCandidatePreferences());
        window.scrollTo(0, 0);
    }, [dispatch]);

    useEffect(() => {
        if (infoPersoDetails) {
            setInfoPersoFormData({
                firstName: infoPersoDetails.data.user.firstName || "",
                lastName: infoPersoDetails.data.user.lastName || "",
                whatsapp: infoPersoDetails.data.whatsapp || "",
                birthday: infoPersoDetails.data.birthday || "",
                summary: infoPersoDetails.data.summary || "",
                urlPicture: infoPersoDetails.data.user.imageUrl || "",
                driverLicense: infoPersoDetails.data.driverLicense, // Utiliser le tableau pour driverLicense
                street: infoPersoDetails.data.address?.street || "",
                city: infoPersoDetails.data.address?.city || "",
                country: infoPersoDetails.data.address?.country || "",
                zipCode: infoPersoDetails.data.address?.zipCode || "",
                urlCV: infoPersoDetails.data.urlCV || "",
                urlCertificate: infoPersoDetails.data.urlCertificate || "",
                urlFacebook: infoPersoDetails.data.urlFacebook || "",
                urlTwitter: infoPersoDetails.data.urlTwitter || "",
                urlLinkedIn: infoPersoDetails.data.urlLinkedIn || "",
            });
        }
        if (infoSituationDetails) {
            setMySituationFormData({
                summary: infoSituationDetails.summary || "",
                latestPosition: infoSituationDetails.latestPosition || "",
                currentState: infoSituationDetails.currentState || "",
                available: infoSituationDetails.available || "",
                grossSalary: infoSituationDetails.grossSalary || 0,
                recognizedDisabilityStatus:
                    infoSituationDetails.recognizedDisabilityStatus || false,
                jobCategoryId: infoSituationDetails?.jobCategory?.id || null,
            });
        }

        if (infoPreferenceDetails) {
            // Extraire les IDs des catégories d'emploi
            const jobCategoryIds = infoPreferenceDetails.jobCategories
                ? infoPreferenceDetails.jobCategories.map(
                    (jobCategory) => jobCategory.id
                )
                : [];

            // Extraire les IDs des types de contrat
            const contractTypeIds = infoPreferenceDetails.contractTypes
                ? infoPreferenceDetails.contractTypes.map(
                    (contractType) => contractType.id
                )
                : [];

            // Extraire les adresses d'emploi (jobAddress)
            const jobAddress = infoPreferenceDetails.jobAddress || [];
            // Mettre à jour l'état avec les données extraites
            setPreferencesFormData({
                jobCategoryIds, // Liste des ID des catégories d'emploi
                contractTypeIds, // Liste des ID des types de contrat
                jobAddress, // Liste des adresses d'emploi
            });
        }
    }, [infoPersoDetails, infoSituationDetails, infoPreferenceDetails]);

    const handleChangeInfoPerso = (e) => {
        const {id, value} = e.target;
        setInfoPersoFormData({...infoPersoFormData, [id]: value});
    };

    // Gérer le changement de sélection
    const handleChangeDriverLicense = (selectedOptions) => {
        // Si rien n'est sélectionné, selectedOptions sera null
        const selectedValues = selectedOptions
            ? selectedOptions.map((option) => option.value).join(",") // Formater en chaîne de caractères
            : "";

        setInfoPersoFormData({
            ...infoPersoFormData,
            driverLicense: selectedValues,
        });
    };

    const handleSubmitInfoPerso = (event) => {
        event.preventDefault(); // Empêche le rafraîchissement de la page

        // Vérification de la date de naissance
        let birthday = infoPersoFormData.birthday || "";
        if (birthday) {
            const birthDate = new Date(birthday);
            const today = new Date();

            // Vérification si la date est valide
            if (isNaN(birthDate.getTime())) {
                message.error("Date de naissance invalide.");
                return;
            }

            // Vérification si la date est dans le futur
            if (birthDate > today) {
                message.error("La date de naissance ne peut pas être dans le futur.");
                return;
            }

            // Vérification de l'âge minimal (18 ans par exemple)
            let age = today.getFullYear() - birthDate.getFullYear();
            const month = today.getMonth() - birthDate.getMonth();
            if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            const ageMinimum = 18; // L'âge minimal requis pour postuler
            if (age < ageMinimum) {
                message.error(`Vous devez avoir au moins ${ageMinimum} ans`);
                return;
            }
        }

        const formattedData = {
            lastName: infoPersoFormData.lastName || "",
            firstName: infoPersoFormData.firstName || "",
            birthday: birthday, // Si valide, on garde la date
            available: infoPersoFormData.available || false,
            summary: infoPersoFormData.summary || "",
            urlFacebook: infoPersoFormData.urlFacebook || "",
            whatsapp: infoPersoFormData.whatsapp || "",
            urlTwitter: infoPersoFormData.urlTwitter || "",
            urlLinkedIn: infoPersoFormData.urlLinkedIn || "",
            driverLicense: infoPersoFormData.driverLicense || "",
            address: {
                id: infoPersoDetails.data?.address?.id || null,
                country: infoPersoFormData.country || "",
                city: infoPersoFormData.city || "",
                street: infoPersoFormData.street || "",
                zipCode: infoPersoFormData.zipCode || 0,
            },
        };

        dispatch(updateCandidateInfoPerso(formattedData))
            .then(() => {
                if (selectedImageFile) {
                    dispatch(uploadProfilePicture(selectedImageFile));
                }
                if (selectedCvFile || selectedCertificatFile) {
                    dispatch(
                        uploadProfileCvCertificate(selectedCvFile, selectedCertificatFile)
                    );
                }
            })
            .catch((err) => {
                console.error("Erreur de mise à jour :", err);
                message.error("Échec de la mise à jour des informations");
            });
    };

    // Acctions liées a ma situation
    // Gestion des changements de formulaire

    const handleChangeMySituation = (event) => {
        const {id, name, value} = event.target;
        const field = id || name; // Assure que vous gérez correctement les champs avec un "name"

        setMySituationFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleSubmitMySituation = async (event) => {
        event.preventDefault();
        const body = {
            summary: mySituationFormData.summary,
            latestPosition: mySituationFormData.latestPosition,
            currentState: mySituationFormData.currentState,
            available: mySituationFormData.available,
            grossSalary: mySituationFormData.grossSalary,
            recognizedDisabilityStatus:
            mySituationFormData.recognizedDisabilityStatus,
            jobCategoryId: mySituationFormData.jobCategoryId,
        };

        // Effectuez votre logique de soumission ici, par exemple en utilisant une action Redux
        dispatch(updateCandidateSituation(body));
    };

    const [portfolioFormData, setPortfolioFormData] = useState({
        projectTitle: "",
        description: "",
        technologyStack: "",
        completionDate: "",
    });

    const handleChangePortfolio = (event) => {
        const {id, name, value} = event.target;
        const field = id || name;

        setPortfolioFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleSubmitPortfolio = async (event) => {
        event.preventDefault();
        const body = {
            projectTitle: portfolioFormData.projectTitle,
            description: portfolioFormData.description,
            technologyStack: portfolioFormData.technologyStack,
            completionDate: portfolioFormData.completionDate,
        };

        dispatch(updatePortfolio(body)); // Envoi des données
    };
    // Action liée a mes preferences

    // Transform domains and contracts into `react-select` options
    const domainOptions = domainTypes?.map((domain) => ({
        value: domain.id,
        label: domain.name,
    }));

    const contractOptions = contractTypes?.map((contract) => ({
        value: contract.id,
        label: contract.name,
    }));

    // Handle select change
    const handleDomainChange = (selectedOptions) => {
        setPreferencesFormData({
            ...preferencesFormData,
            jobCategoryIds: selectedOptions.map((option) => option.value),
        });
    };

    const handleContractChange = (selectedOptions) => {
        setPreferencesFormData({
            ...preferencesFormData,
            contractTypeIds: selectedOptions.map((option) => option.value),
        });
    };

    const handleAddressChange = (name, selectedOptions) => {
        setPreferencesFormData({
            ...preferencesFormData,
            [name]: selectedOptions.map((option) => ({country: option.value})),
        });
    };
    const isPreferencesFormValid = () => {
        return (
            preferencesFormData.jobCategoryIds.length > 0 && // Vérifie que des catégories de métier sont sélectionnées
            preferencesFormData.contractTypeIds.length > 0 && // Vérifie que des types de contrat sont sélectionnés
            preferencesFormData.jobAddress.some((address) => address.country !== "") // Vérifie qu'au moins une localité est sélectionnée
        );
    };

    const handleSubmitPreferences = async (event) => {
        event.preventDefault(); // Empêche le rechargement de la page
        // Effectuez votre logique de soumission ici, par exemple en utilisant une action Redux
        dispatch(updateCandidatePreferences(preferencesFormData));
    };

    // Action liée à mes préférences Freelance


// Handle select change for domains
    const handleFreelanceDomainChange = (selectedOptions) => {
        setFreelancePreferencesFormData({
            ...freelancePreferencesFormData,
            jobCategoryIds: selectedOptions.map((option) => option.value),
        });
    };

// Handle select change for tags
    const handleFreelanceTagsChange = (selectedOptions) => {
        setFreelancePreferencesFormData({
            ...freelancePreferencesFormData,
            tags: selectedOptions.map((option) => option.value), // Met à jour avec les valeurs des options sélectionnées
        });
    };

// Handle select change for addresses
    const handleFreelanceAddressChange = (name, selectedOptions) => {
        setFreelancePreferencesFormData({
            ...freelancePreferencesFormData,
            [name]: selectedOptions.map((option) => ({country: option.value})),
        });
    };

// Validate form
    const isFreelancePreferencesFormValid = () => {
        return (
            freelancePreferencesFormData.jobCategoryIds.length > 0 && // Vérifie que des catégories de métier sont sélectionnées
            freelancePreferencesFormData.tags.length > 0 && // Vérifie que des types de contrat sont sélectionnés
            freelancePreferencesFormData.jobAddress.some((address) => address.country !== "") // Vérifie qu'au moins une localité est sélectionnée
        );
    };

// Handle submit
    const handleSubmitFreelancePreferences = async (event) => {
        event.preventDefault(); // Empêche le rechargement de la page
        // Effectuez votre logique de soumission ici, par exemple en utilisant une action Redux
        dispatch(updateFreelancePreferences(freelancePreferencesFormData));
    };

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const [modal, setModal] = useState(false);
    const [currentContentType, setCurrentContentType] = useState("");
    const [selectedExperience, setSelectedExperience] = useState(null); // État pour l'expérience à modifier
    const [selectedEducation, setSelectedEducation] = useState(null); // État pour l'expérience à modifier
    const [currentSkill, setCurrentSkill] = useState(null); // État pour la compétence à modifier
    const [currentLanguage, setCurrentLanguage] = useState(null); // État pour la compétence à modifier

    const toggleModal = (contentType) => {
        setCurrentContentType(contentType);
        setModal(!modal);
    };

    const handleCloseModal = () => {
        setModal(false);
        dispatch(fetchCandidateExperiences());
        dispatch(fetchCandidateEducations());
        dispatch(fetchCandidateSkills());
        dispatch(fetchCandidateLanguages());
        setSelectedExperience(null); // Réinitialiser l'expérience sélectionnée
        setSelectedEducation(null); // Réinitialiser l'éducation sélectionnée
        setCurrentSkill(null); // Réinitialiser la compétence sélectionnée
        setCurrentLanguage(null); // Réinitialiser la compétence sélectionnée
        setCurrentContentType("");
    };
    // Fonction pour ouvrir le modal et remplir les données lors de la modification
    const handleEditExperience = (experience) => {
        setSelectedExperience(experience); // Stocke l'expérience sélectionnée pour l'édition
        toggleModal("Experience", experience); // Ouvre le modal avec les données de l'expérience
    };

    const handleEditEducation = (education) => {
        setSelectedEducation(education); // Stocke l'expérience sélectionnée pour l'édition
        toggleModal("Formation", education); // Ouvre le modal avec les données de l'expérience
    };
    const handleEditSkill = (skill) => {
        setCurrentSkill(skill); // Stocke la compétence sélectionnée pour l'édition
        toggleModal("Compétence", skill); // Ouvre le modal avec les données de la compétence
    };

    const handleEditLanguage = (language) => {
        setCurrentLanguage(language); // Stocke la compétence sélectionnée pour l'édition
        toggleModal("Langue", language); // Ouvre le modal avec les données de la compétence
    };

    // Fonction pour supprimer une expérience
    const deleteExperience = async (id) => {
        dispatch(deleteCandidateExperience(id))
            .then(() => {
                message.success("Expérience supprimée avec succès !");
                dispatch(fetchCandidateExperiences());
            })
            .catch(() => {
                message.error("Erreur lors de la suppression de l'expérience.");
            });
    };

    // Fonction pour supprimer une formation
    const deleteEducation = async (id) => {
        dispatch(deleteCandidateEducation(id))
            .then(() => {
                message.success("Formation supprimée avec succès !");
                dispatch(fetchCandidateEducations());
            })
            .catch(() => {
                message.error("Erreur lors de la suppression de l'expérience.");
            });
    };
    // Fonction pour supprimer une compétence
    const deleteSkill = async (id) => {
        dispatch(deleteCandidateSkill(id))
            .then(() => {
                message.success("Compétence supprimée avec succès!");
                dispatch(fetchCandidateSkills());
            })
            .catch(() => {
                message.error("Erreur lors de la suppression de la compétence.");
            });
    };
    // Fonction pour supprimer une langue
    const deleteLanguage = async (id) => {
        dispatch(deleteCandidateLanguage(id))
            .then(() => {
                dispatch(fetchCandidateLanguages());
            })
            .catch(() => {
                message.error("Erreur lors de la suppression de la langue.");
            });
    };

    const icons = {
        Experience: <i className="uil uil-briefcase fs-22"></i>,
        Formation: <i className="uil uil-graduation-cap fs-22"></i>,
        Compétence: <i className="uil uil-star fs-22"></i>, // Exemple pour une compétence
        Langue: <i className="uil uil-globe fs-22"></i>, // Exemple pour une langue
        // Ajoute d'autres types de contenu et leurs icônes ici
    };

    const optionsDriverLicense = [
        {value: "A", label: "Permis A"},
        {value: "B", label: "Permis B"},
        {value: "C", label: "Permis C"},
        {value: "D", label: "Permis D"},
    ];

    // Formater les options sélectionnées pour l'affichage dans le Select
    const selectedValues = infoPersoFormData.driverLicense
        ? optionsDriverLicense.filter((option) =>
            infoPersoFormData.driverLicense.split(",").includes(option.value)
        )
        : [];

    const optionsLocalities = [
        {value: "CI", label: "Côte d'Ivoire"},
        {value: "TG", label: "Togo"},
        {value: "GU", label: "Guinée"},
        {value: "SN", label: "Sénégal"},
        {value: "BF", label: "Burkina Faso"},
        {value: "ML", label: "Mali"},
        // Ajoutez d'autres pays si nécessaire
    ];

    const listOfOptionTags = [
        'Temps plein',
        'Temps partiel',
        'Freelance',
        'Télétravail',
        'Candidat local',
        'Contrat à durée déterminée (CDD)',
        'Contrat à durée indéterminée (CDI)',
        'Stage',
        'Consultant',
        'Temps flexible',
        'Emploi à distance',
        'Emploi hybride',
        'Travail flexible',
        'Travail saisonnier',
        'Freelance à long terme',
        'Travail à domicile',
        'Contrat freelance',
        'Emploi à temps réduit',
        'Emploi intérimaire',
        'Télétravail à plein temps',
        'Job à la carte',
        'Temps partiel à distance',
        'Emploi international',
        'Emploi local',
        'Mission ponctuelle',
        'Emploi nomade',
        'Mission temporaire',
        'Travail en équipe distante',
        'Emploi flexible',
        'Poste à horaires variables',
    ];

    const colourStyles = {
        option: (styles, {data, isDisabled, isFocused, isSelected}) => ({
            ...styles,
            color: isDisabled
                ? "#ccc" // Couleur du texte pour les options désactivées
                : isSelected
                    ? "white" // Couleur du texte pour les options sélectionnées
                    : isFocused
                        ? "#50D3BB" // Couleur du texte pour les options survolées
                        : "black", // Couleur du texte pour les options normales
        }),
        control: (styles, {isFocused}) => ({
            ...styles,
            borderColor: isFocused ? "#86d9da" : "#f3f4f6", // Bordure verte
            "&:hover": {
                borderColor: isFocused ? "#86d9da" : "#f3f4f6", // Garder la bordure verte au survol
            },
            boxShadow: isFocused ? "0 0 0 1px #86d9da" : "none", // Ombre verte lors de la focalisation
        }),
    };

    const renderForm = () => {
        switch (currentContentType) {
            case "Experience":
                return (
                    <AddExperienceForm
                        selectedExperience={selectedExperience} // Passer l'expérience sélectionnée au formulaire
                        onCancel={handleCloseModal}
                    />
                );
            case "Formation":
                return (
                    <AddEducationForm
                        selectedEducation={selectedEducation} // Passer l'education sélectionnée au formulaire
                        onCancel={handleCloseModal}
                    />
                );
            case "Compétence":
                return (
                    <AddSkillForm
                        selectedSkill={currentSkill} // Passer la compétence sélectionnée au formulaire
                        onCancel={handleCloseModal}
                    />
                );
            case "Langue":
                return (
                    <AddLanguageForm
                        selectedLanguage={currentLanguage} // Passer la langue sélectionnée au formulaire
                        onCancel={handleCloseModal}
                    />
                );
            default:
                return null;
        }
    };

    const formatDate = (datestring) => {
        const options = {month: "short", year: "numeric"};
        return new Date(datestring).toLocaleDateString("fr-FR", options); // Utiliser "fr-FR" pour le français
    };

    return (
        <React.Fragment>
            <Col lg={8}>
                <Card className="profile-content-page mt-4 mt-lg-0">
                    <Nav className="profile-content-nav nav-pills border-bottom mb-4" id="pills-tab" role="tablist">
                        <NavItem role="informations personnelles">
                            <NavLink
                                to="#"
                                className={classnames({active: activeTab === "1"})}
                                onClick={() => tabChange("1")}
                                type="button"
                            >
                                Infos persos
                            </NavLink>
                        </NavItem>

                        {user === null && infoPersoDetails?.data?.customerType && (
                            <>
                                {infoPersoDetails.data.customerType === "JOB_SEEKER" ? (
                                    <>
                                        <NavItem role="ma situation">
                                            <NavLink
                                                to="#"
                                                className={classnames({active: activeTab === "2"})}
                                                onClick={() => tabChange("2")}
                                                type="button"
                                            >
                                                Ma situation
                                            </NavLink>
                                        </NavItem>
                                        <NavItem role="ma recherche">
                                            <NavLink
                                                to="#"
                                                className={classnames({active: activeTab === "3"})}
                                                onClick={() => tabChange("3")}
                                                type="button"
                                            >
                                                Mes préférences
                                            </NavLink>
                                        </NavItem>
                                    </>
                                ) : infoPersoDetails.data.customerType === "FREELANCER" ? (
                                    <>
                                        <NavItem role="portfolio">
                                            <NavLink
                                                to="#"
                                                className={classnames({active: activeTab === "5"})}
                                                onClick={() => tabChange("5")}
                                                type="button"
                                            >
                                                Portfolio
                                            </NavLink>
                                        </NavItem>
                                        <NavItem role="preferences freelance">
                                            <NavLink
                                                to="#"
                                                className={classnames({active: activeTab === "6"})}
                                                onClick={() => tabChange("6")}
                                                type="button"
                                            >
                                                Mes préférences Freelance
                                            </NavLink>
                                        </NavItem>
                                    </>
                                ) : null}
                            </>
                        )}
                        < NavItem role="dossier de compétence">
                            <NavLink
                                to="#"
                                className={classnames({
                                    active: activeTab === "4",
                                    "text-danger": activeTab === "4", // Classe pour le texte rouge
                                })}
                                onClick={() => {
                                    tabChange("4");
                                }}
                                type="button"
                            >
                                Dossier de compétence
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <CardBody className="p-4">
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                {infoPersoLoading || loadingFile ? (
                                    <Skeleton active paragraph={{rows: 4}}/>
                                ) : (
                                    <Form onSubmit={handleSubmitInfoPerso}>
                                        <div>
                                            <h5 className="fs-17 fw-semibold mb-3 mb-0">
                                                Mon Compte
                                            </h5>
                                            <div className="text-center">
                                                <div className="mb-4 profile-user">
                                                    <img
                                                        src={
                                                            profileImage ||
                                                            infoPersoFormData.urlPicture ||
                                                            "https://res.cloudinary.com/dryskp7hv/image/upload/v1718890903/189632_pu5gij.png"
                                                        }
                                                        className="rounded-circle img-thumbnail profile-img"
                                                        id="profile-img"
                                                        alt=""
                                                    />
                                                    <div className="p-0 rounded-circle profile-photo-edit">
                                                        <Input
                                                            id="profile-img-file-input"
                                                            type="file"
                                                            className="profile-img-file-input"
                                                            onChange={handleImageChange}
                                                        />
                                                        <Label
                                                            htmlFor="profile-img-file-input"
                                                            className="profile-photo-edit avatar-xs"
                                                        >
                                                            <i className="uil uil-edit"></i>
                                                        </Label>
                                                    </div>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <label htmlFor="firstName" className="form-label">
                                                            Nom
                                                        </label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="firstName"
                                                            value={infoPersoFormData.firstName}
                                                            onChange={handleChangeInfoPerso}
                                                            required
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="lastName" className="form-label">
                                                            Prénom(s)
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="lastName"
                                                            value={infoPersoFormData.lastName}
                                                            onChange={handleChangeInfoPerso}
                                                            required
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="whatsapp" className="form-label">
                                                            Téléphone
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="whatsapp"
                                                            placeholder="(+225) 123 456 7890"
                                                            value={infoPersoFormData.whatsapp}
                                                            onChange={handleChangeInfoPerso}
                                                            required
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="birthday" className="form-label">
                                                            Date de naissance
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            name="birthday"
                                                            id="birthday"
                                                            value={infoPersoFormData.birthday}
                                                            onChange={handleChangeInfoPerso}
                                                            required
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="street" className="form-label">
                                                            Rue
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="street"
                                                            value={infoPersoFormData.street}
                                                            onChange={handleChangeInfoPerso}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="city" className="form-label">
                                                            Ville
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="city"
                                                            value={infoPersoFormData.city}
                                                            onChange={handleChangeInfoPerso}
                                                            required
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <label htmlFor="country" className="form-label">
                                                            Pays
                                                        </label>
                                                        <select
                                                            className="form-select"
                                                            data-trigger
                                                            name="country"
                                                            id="country"
                                                            aria-label="Default select example"
                                                            value={infoPersoFormData.country}
                                                            onChange={handleChangeInfoPerso}
                                                            required
                                                        >
                                                            <option value=""></option>
                                                            <option value="Cote D'ivoire">
                                                                Côte d'ivoire
                                                            </option>
                                                            <option value="Togo">Togo</option>
                                                            <option value="Guinnée">Guinnée</option>
                                                            <option value="Sénégale">Sénégale</option>
                                                            <option value="Burkina-Faso">Burkina-Faso</option>
                                                            <option value="Mali">Mali</option>
                                                            <option value="Niger">Niger</option>
                                                            <option value="Benin">Benin</option>
                                                            <option value="Mauritanie">Mauritanie</option>
                                                            <option value="Allemagne">Allemagne</option>
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="zipCode" className="form-label">
                                                            Code Postale
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="zipCode"
                                                            value={infoPersoFormData.zipCode}
                                                            onChange={handleChangeInfoPerso}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="driverLicense"
                                                            className="form-label"
                                                        >
                                                            Permis de conduite
                                                        </label>
                                                        <div className="selecte-tag position-relative">
                                                            <Select
                                                                id="driverLicense"
                                                                name="driverLicense"
                                                                placeholder="Sélectionner le type de permis de conduire"
                                                                options={optionsDriverLicense}
                                                                isMulti
                                                                className="selecte-tag position-relative"
                                                                classNamePrefix="select"
                                                                styles={colourStyles}
                                                                value={selectedValues} // Utiliser les options correspondantes aux valeurs sélectionnées
                                                                onChange={handleChangeDriverLicense}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="mt-4">
                                            <h5 className="fs-17 fw-semibold mb-3">Profile</h5>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="exampleFormControlTextarea1"
                                                            className="form-label"
                                                        >
                                                            Présentez-vous
                                                        </Label>
                                                        <textarea
                                                            className="form-control"
                                                            rows="5"
                                                            id="summary"
                                                            value={infoPersoFormData.summary}
                                                            onChange={handleChangeInfoPerso}
                                                            required
                                                        ></textarea>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="attachmentsCv"
                                                            className="form-label"
                                                        >
                                                            Mon CV
                                                        </Label>
                                                        <Input
                                                            className="form-control"
                                                            type="file"
                                                            accept="application/pdf"
                                                            id="attachmentsCv"
                                                            onChange={handleCvChange}
                                                            required={!infoPersoFormData.urlCV}
                                                        />
                                                        {infoPersoFormData.urlCV ? (
                                                            <div>
                                                                <a
                                                                    href={infoPersoFormData.urlCV}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="d-block mb-2"
                                                                >
                                                                    📄
                                                                </a>
                                                                <small>
                                                                    Téléverser un nouveau CV si nécessaire
                                                                </small>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="attachmentsCertificate"
                                                            className="form-label"
                                                        >
                                                            Mes Certifications
                                                        </Label>
                                                        <Input
                                                            className="form-control"
                                                            type="file"
                                                            accept="application/pdf"
                                                            id="attachmentsCertificate"
                                                            onChange={handleCertificatChange}
                                                        />
                                                        {infoPersoFormData.urlCertificate ? (
                                                            <div>
                                                                <a
                                                                    href={infoPersoFormData.urlCertificate}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="d-block mb-2"
                                                                >
                                                                    📄
                                                                </a>
                                                                <small>
                                                                    Téléverser un nouveau certificat si
                                                                    nécessaire
                                                                </small>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="mt-4">
                                            <h5 className="fs-17 fw-semibold mb-3">
                                                Réseaux Sociaux
                                            </h5>
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="urlFacebook" className="form-label">
                                                            Facebook
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="urlFacebook"
                                                            value={infoPersoFormData.urlFacebook || ""}
                                                            onChange={handleChangeInfoPerso}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="urlTwitter" className="form-label">
                                                            Twitter
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="urlTwitter"
                                                            value={infoPersoFormData.urlTwitter || ""}
                                                            onChange={handleChangeInfoPerso}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="urlLinkedIn" className="form-label">
                                                            Linkedin
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="urlLinkedIn"
                                                            value={infoPersoFormData.urlLinkedIn || ""}
                                                            onChange={handleChangeInfoPerso}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="mt-4 text-end">
                                            <ReactstrapButton
                                                type="submit"
                                                color="primary"
                                                className="me-2"
                                            >
                                                Mettre à jour
                                            </ReactstrapButton>
                                        </div>
                                    </Form>
                                )}
                            </TabPane>
                            <TabPane tabId="2">
                                <Form onSubmit={handleSubmitMySituation}>
                                    <h5 className="fs-17 fw-semibold mb-3 mb-0">
                                        Ma situation actuelle
                                    </h5>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <label htmlFor="latestPosition" className="form-label">
                                                    Poste actuel ou dernier poste occupé
                                                </label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="latestPosition"
                                                    value={mySituationFormData.latestPosition}
                                                    onChange={handleChangeMySituation}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <label htmlFor="currentState" className="form-label">
                                                    Situation actuelle
                                                </label>
                                                <select
                                                    className="form-select"
                                                    id="currentState"
                                                    name="currentState"
                                                    value={mySituationFormData.currentState}
                                                    onChange={handleChangeMySituation}
                                                >
                                                    <option value="">-- Choisissez une option --</option>
                                                    <option value="IN_POSITION">En poste</option>
                                                    <option value="UN_EMPLOYED">Sans emploi</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <label htmlFor="available" className="form-label">
                                                    Disponibilité
                                                </label>
                                                <select
                                                    className="form-select"
                                                    id="available"
                                                    value={mySituationFormData.available}
                                                    onChange={handleChangeMySituation}
                                                >
                                                    <option value="false">À convenir</option>
                                                    <option value="true">Immédiate</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <label htmlFor="jobCategoryId" className="form-label">
                                                    Secteur d'activité
                                                </label>
                                                <select
                                                    className="form-select"
                                                    name="jobCategoryId"
                                                    id="jobCategoryId"
                                                    value={mySituationFormData.jobCategoryId || ""}
                                                    onChange={handleChangeMySituation}
                                                >
                                                    <option value="">
                                                        -- Choisissez un secteur d'activité --
                                                    </option>
                                                    {domainTypes &&
                                                        domainTypes.map((type) => (
                                                            <option key={type.id} value={type.id}>
                                                                {type.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    Salaire mensuel: {mySituationFormData.grossSalary} kE
                                                </label>
                                                <input
                                                    type="range"
                                                    min="100"
                                                    max="900"
                                                    value={mySituationFormData.grossSalary}
                                                    onChange={({target: {value}}) =>
                                                        setMySituationFormData((prevState) => ({
                                                            ...prevState,
                                                            grossSalary: value,
                                                        }))
                                                    }
                                                    className="slider"
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="form-check mb-2 mt-3">
                                                <label
                                                    htmlFor="recognizedDisabilityStatus"
                                                    className="form-check-label ms-2"
                                                >
                                                    Reconnu travailleur handicapé
                                                </label>
                                                <Input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="recognizedDisabilityStatus"
                                                    checked={
                                                        mySituationFormData.recognizedDisabilityStatus
                                                    }
                                                    onChange={() =>
                                                        setMySituationFormData((prevState) => ({
                                                            ...prevState,
                                                            recognizedDisabilityStatus:
                                                                !prevState.recognizedDisabilityStatus,
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="mt-4 text-end">
                                        <ReactstrapButton
                                            type="submit"
                                            color="primary"
                                            className="me-2"
                                            disabled={loading}
                                        >
                                            {loading ? "Chargement..." : "Mettre à jour"}
                                        </ReactstrapButton>
                                    </div>
                                </Form>
                            </TabPane>
                            <TabPane tabId="3">
                                <Form onSubmit={handleSubmitPreferences}>
                                    <div>
                                        <p className="fs-14 text-muted fw-semibold mb-4">
                                            Ces infos nous permettent de vous envoyer de meilleures
                                            recommandations !
                                        </p>
                                        <Row>
                                            <Col lg={5}>
                                                <div className="mb-3">
                                                    <label htmlFor="contractTypes" className="form-label">
                                                        Type(s) de contrat
                                                    </label>
                                                    <div className="selecte-tag position-relative">
                                                        <Select
                                                            isMulti
                                                            options={contractOptions}
                                                            value={contractOptions?.filter((option) =>
                                                                preferencesFormData.contractTypeIds.includes(
                                                                    option.value
                                                                )
                                                            )}
                                                            onChange={handleContractChange}
                                                            placeholder="Sélectionner les contrats"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={7}>
                                                <div className="mb-3">
                                                    <label htmlFor="localities" className="form-label">
                                                        Localité(s) recherché(s)
                                                    </label>
                                                    <div className="selecte-tag position-relative">
                                                        <Select
                                                            isMulti
                                                            options={optionsLocalities}
                                                            value={optionsLocalities?.filter((option) =>
                                                                preferencesFormData.jobAddress.some(
                                                                    (address) => address.country === option.value
                                                                )
                                                            )}
                                                            placeholder="Sélectionner les localités..."
                                                            onChange={(value) =>
                                                                handleAddressChange("jobAddress", value)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <label htmlFor="jobs" className="form-label">
                                                        Métier recherché(s)
                                                    </label>
                                                    <div className="selecte-tag position-relative">
                                                        <Select
                                                            isMulti
                                                            options={domainOptions}
                                                            value={domainOptions?.filter((option) =>
                                                                preferencesFormData.jobCategoryIds.includes(
                                                                    option.value
                                                                )
                                                            )}
                                                            onChange={handleDomainChange}
                                                            placeholder="Sélectionner les domaines"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="mt-4 mb-4 text-end">
                                        <ReactstrapButton
                                            type="submit"
                                            color="primary"
                                            className="me-2"
                                            disabled={!isPreferencesFormValid()} // Bouton grisé si le formulaire est invalide
                                        >
                                            Mettre à jour
                                        </ReactstrapButton>
                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </Form>
                            </TabPane>
                            <TabPane tabId="4">
                                <Modal
                                    isOpen={modal}
                                    toggle={handleCloseModal}
                                    style={{maxWidth: "700px"}}
                                    centered
                                    backdrop="static"
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title text-danger">
                                            {icons[currentContentType]} {currentContentType}
                                        </h5>
                                    </div>
                                    <div
                                        className="modal-body"
                                        style={{maxHeight: "600px", overflowY: "auto"}} // Style en ligne
                                    >
                                        {/* Appel à renderForm pour afficher le formulaire correspondant */}
                                        {renderForm()}
                                    </div>
                                </Modal>

                                <div className="candidate-education-details">
                                    <h6 className="fs-18 fw-bold mb-0 text-danger">
                                        <i className="uil uil-briefcase fs-22"></i> EXPERIENCE(S)
                                        PROFESSIONNELLE(S){" "}
                                        <Button
                                            onClick={() => toggleModal("Experience", null)}
                                            shape="circle"
                                            icon={<PlusOutlined/>}
                                        />
                                    </h6>
                                    <hr/>
                                    {loading && <Skeleton active paragraph={{rows: 4}}/>}{" "}
                                    {error && (
                                        <p className="text-muted mt-2">
                                            Une erreur est survenue : {error}
                                        </p>
                                    )}
                                    {!loading &&
                                        !error &&
                                        allExperiences?.content?.length > 0 &&
                                        allExperiences.content.map((experience) => (
                                            <div key={experience.id} className="mt-4 d-flex">
                                                <div className="ms-4">
                                                    {/* Titre en majuscule avec icônes modifier et supprimer */}
                                                    <div
                                                        className="d-flex justify-content-between align-items-center mb-3">
                                                        <h6 className="fs-16 mb-0 text-uppercase">
                                                            {experience.position}
                                                        </h6>
                                                        <div className="d-flex gap-2">
                                                            <Button
                                                                type="text"
                                                                style={{color: "red"}}
                                                                icon={<EditOutlined/>}
                                                                shape="circle"
                                                                onClick={() => handleEditExperience(experience)}
                                                            />
                                                            <Popconfirm
                                                                title="Êtes-vous sûr de vouloir supprimer cette expérience ?"
                                                                onConfirm={() =>
                                                                    deleteExperience(experience.id)
                                                                }
                                                                okText="Oui"
                                                                cancelText="Non"
                                                            >
                                                                <Button
                                                                    type="text"
                                                                    style={{color: "red"}}
                                                                    shape="circle"
                                                                    icon={<DeleteOutlined/>}
                                                                />
                                                            </Popconfirm>
                                                        </div>
                                                    </div>

                                                    {/* Entreprise et période */}
                                                    <p className="mb-1 mt-2 fw-bold text-danger">
                                                        {experience.company} - (
                                                        {formatDate(experience.startDate)} -{" "}
                                                        {formatDate(experience.endDate)})
                                                    </p>

                                                    {/* Contexte */}
                                                    {experience.context && (
                                                        <p className="text-muted">{experience.context}</p>
                                                    )}

                                                    {/* Actions */}
                                                    {experience.actions && (
                                                        <>
                                                            <p className="text-danger">Missions</p>
                                                            <ul className="text-muted">
                                                                {experience.actions
                                                                    .split("\n")
                                                                    .map((action, index) => (
                                                                        <li key={index}>{action.trim()}</li>
                                                                    ))}
                                                            </ul>
                                                        </>
                                                    )}

                                                    {/* Résultats */}
                                                    {experience.results && (
                                                        <>
                                                            <p className="text-danger">Résultats</p>
                                                            <ul className="text-muted">
                                                                {experience.results
                                                                    .split("\n")
                                                                    .map((result, index) => (
                                                                        <li key={index}>{result.trim()}</li>
                                                                    ))}
                                                            </ul>
                                                        </>
                                                    )}

                                                    {/* Environnement technique */}
                                                    {experience.technicalEnvironment && (
                                                        <>
                                                            <p className="text-danger">
                                                                Environnement technique
                                                            </p>
                                                            <p className="text-muted">
                                                                {experience.technicalEnvironment
                                                                    .replace(/\n/g, ", ") // Remplacer les retours à la ligne par des virgules
                                                                    .split(",")
                                                                    .map((tech, index) => tech.trim())
                                                                    .join(", ")}{" "}
                                                                {/* Affichage sur une seule ligne */}
                                                            </p>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    {/* Si aucune expérience n'est disponible */}
                                    {!loading &&
                                        !error &&
                                        allExperiences?.content?.length === 0 && (
                                            <p className="text-muted mt-2">
                                                Aucune expérience disponible.
                                            </p>
                                        )}
                                </div>

                                <div className="candidate-education-details mt-4">
                                    <h6 className="fs-18 fw-bold mb-0 text-info">
                                        <i className="uil uil-graduation-cap fs-22"></i>
                                        FORMATION(S){" "}
                                        <Button
                                            onClick={() => toggleModal("Formation", null)}
                                            shape="circle"
                                            icon={<PlusOutlined/>}
                                        />
                                    </h6>
                                    <hr/>
                                    {educationLoading && (
                                        <Skeleton active paragraph={{rows: 4}}/>
                                    )}
                                    {educationError && (
                                        <p className="text-muted mt-2">
                                            Une erreur est survenue : {educationError}
                                        </p>
                                    )}
                                    {!educationLoading &&
                                        !educationError &&
                                        allEducations?.content?.length > 0 &&
                                        // Tri des formations par date de début (date la plus récente en premier)
                                        allEducations.content
                                            .sort(
                                                (a, b) => new Date(b.startDate) - new Date(a.startDate)
                                            ) // Tri décroissant par startDate
                                            .map((formation, index) => (
                                                <div key={formation.id} className="mt-4 d-flex">
                                                    <div className="ms-4">
                                                        {/* Titre en majuscule avec icônes modifier et supprimer */}
                                                        <div
                                                            className="d-flex justify-content-between align-items-center mb-3">
                                                            <h6 className="fs-16 mb-1 text-uppercase">
                                                                {formation.name}
                                                            </h6>
                                                            <div>
                                                                <Button
                                                                    type="text"
                                                                    style={{color: "#31ABDF"}}
                                                                    icon={<EditOutlined/>}
                                                                    shape="circle"
                                                                    onClick={() => handleEditEducation(formation)}
                                                                />
                                                                <Popconfirm
                                                                    title="Êtes-vous sûr de vouloir supprimer cette formation ?"
                                                                    onConfirm={() =>
                                                                        deleteEducation(formation.id)
                                                                    } // Confirme la suppression
                                                                    okText="Oui"
                                                                    cancelText="Non"
                                                                >
                                                                    <Button
                                                                        type="text"
                                                                        style={{color: "#31ABDF"}}
                                                                        shape="circle"
                                                                        icon={<DeleteOutlined/>}
                                                                    />
                                                                </Popconfirm>
                                                            </div>
                                                        </div>
                                                        <p className="mb-2 text-info">
                                                            {formation.school} - (
                                                            {formatDate(formation.startDate)} -{" "}
                                                            {formatDate(formation.endDate)})
                                                        </p>
                                                        {(formation.summary || "")
                                                            .split("\n")
                                                            .map((summary, index) => (
                                                                <p className="text-muted" key={index}>
                                                                    {summary.trim()}
                                                                </p>
                                                            ))}
                                                    </div>
                                                </div>
                                            ))}
                                    {!educationLoading &&
                                        !educationError &&
                                        allEducations?.content?.length === 0 && (
                                            <p className="text-muted mt-2">
                                                Aucune formation disponible.
                                            </p>
                                        )}
                                </div>

                                <div className="mt-4">
                                    <h5 className="fs-18 fw-bold text-primary">
                                        <i className="uil uil-file-bookmark-alt fs-22"></i>{" "}
                                        COMPETENCE(S){" "}
                                        <Button
                                            onClick={() => toggleModal("Compétence", null)}
                                            shape="circle"
                                            icon={<PlusOutlined/>}
                                        />
                                    </h5>
                                    <hr/>
                                </div>

                                <div className="mt-0">
                                    {skillLoading && <Skeleton active paragraph={{rows: 1}}/>}
                                    {skillError && (
                                        <p className="text-muted mt-2">
                                            Une erreur est survenue : {skillError}
                                        </p>
                                    )}
                                    {!skillError && allSkills?.content?.length > 0 && (
                                        <table className="table table-inline mb-0">
                                            <tbody>
                                            {allSkills.content.map((skill) => (
                                                <tr key={skill.id}>
                                                    <td>{skill.fieldOfCompetence}</td>
                                                    <td>
                                                        {skill.competence}&nbsp;&nbsp;
                                                        <Rate
                                                            disabled
                                                            value={skill.experienceLevel.id}
                                                            count={4}
                                                            style={{color: "#50D3BB"}}
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <Button
                                                            type="text"
                                                            style={{color: "#50D3BB"}}
                                                            icon={<EditOutlined/>}
                                                            shape="circle"
                                                            onClick={() => handleEditSkill(skill)}
                                                        />
                                                        <Popconfirm
                                                            title="Êtes-vous sûr de vouloir supprimer cette compétence ?"
                                                            onConfirm={() => deleteSkill(skill.id)}
                                                            okText="Oui"
                                                            cancelText="Non"
                                                        >
                                                            <Button
                                                                type="text"
                                                                style={{color: "#50D3BB"}}
                                                                shape="circle"
                                                                icon={<DeleteOutlined/>}
                                                            />
                                                        </Popconfirm>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    )}
                                    {!skillLoading &&
                                        !skillError &&
                                        allSkills?.content?.length === 0 && (
                                            <p className="text-muted mt-2">
                                                Aucune compétence disponible.
                                            </p>
                                        )}
                                </div>

                                <div className="mt-4">
                                    <h5 className="fs-18 fw-bold  text-warning">
                                        <i className="uil uil-comments-alt fs-22"></i> LANGUE(S){" "}
                                        <Button
                                            onClick={() => toggleModal("Langue", null)}
                                            shape="circle"
                                            icon={<PlusOutlined/>}
                                        />
                                    </h5>
                                    <hr/>
                                </div>
                                <div className="mt-0 d-flex flex-wrap align-items-start gap-1">
                                    {languageLoading && (
                                        <Skeleton active paragraph={{rows: 1}}/>
                                    )}{" "}
                                    {languageError && (
                                        <p className="text-muted mt-2">
                                            Une erreur est survenue : {skillError}
                                        </p>
                                    )}
                                    {!languageError && allLanguages?.content?.length > 0 && (
                                        <table className="table table-inline mb-0">
                                            <tbody>
                                            {allLanguages.content.map((language) => (
                                                <tr key={language.id}>
                                                    <td>{language?.language?.name}</td>
                                                    <td>
                                                        <Rate
                                                            disabled
                                                            value={languageLevelMap[language.level]} // Utilisez le mapping ici
                                                            count={3}
                                                            style={{color: "#F9BB4A"}}
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <Button
                                                            type="text"
                                                            style={{color: "#F9BB4A"}}
                                                            icon={<EditOutlined/>}
                                                            shape="circle"
                                                            onClick={() => handleEditLanguage(language)}
                                                        />
                                                        <Popconfirm
                                                            title="Êtes-vous sûr de vouloir supprimer cette langue ?"
                                                            onConfirm={() => deleteLanguage(language.id)}
                                                            okText="Oui"
                                                            cancelText="Non"
                                                        >
                                                            <Button
                                                                type="text"
                                                                style={{color: "#F9BB4A"}}
                                                                shape="circle"
                                                                icon={<DeleteOutlined/>}
                                                            />
                                                        </Popconfirm>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    )}
                                    {!languageLoading &&
                                        !languageError &&
                                        allLanguages?.content?.length === 0 && (
                                            <p className="text-muted mt-2">
                                                Aucune langue disponible.
                                            </p>
                                        )}
                                </div>
                                <div className="mt-4 text-end">
                                    <Link
                                        to="#"
                                        className="btn btn-danger"
                                        onClick={() => window.open("/monCV", "_blank")}
                                    >
                                        <i className="uil uil-eye"></i> Visualiser
                                    </Link>
                                </div>
                            </TabPane>
                            <TabPane tabId="5">
                                <Form onSubmit={handleSubmitPortfolio}>
                                    <h5 className="fs-17 fw-semibold mb-3 mb-0">
                                        Mon Portfolio
                                    </h5>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <label htmlFor="projectTitle" className="form-label">
                                                    Titre du projet
                                                </label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="projectTitle"
                                                    value={portfolioFormData.projectTitle}
                                                    onChange={handleChangePortfolio}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <label htmlFor="description" className="form-label">
                                                    Description du projet
                                                </label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="description"
                                                    value={portfolioFormData.description}
                                                    onChange={handleChangePortfolio}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <label htmlFor="technologyStack" className="form-label">
                                                    Technologies utilisées
                                                </label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="technologyStack"
                                                    value={portfolioFormData.technologyStack}
                                                    onChange={handleChangePortfolio}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <label htmlFor="completionDate" className="form-label">
                                                    Date d'achèvement
                                                </label>
                                                <Input
                                                    type="date"
                                                    className="form-control"
                                                    id="completionDate"
                                                    value={portfolioFormData.completionDate}
                                                    onChange={handleChangePortfolio}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="mt-4 text-end">
                                        <ReactstrapButton
                                            type="submit"
                                            color="primary"
                                            className="me-2"
                                            disabled={loading}
                                        >
                                            {loading ? "Chargement..." : "Mettre à jour le portfolio"}
                                        </ReactstrapButton>
                                    </div>
                                </Form>
                            </TabPane>
                            <TabPane tabId="6">
                                <Form onSubmit={handleSubmitFreelancePreferences}>
                                    <div>
                                        <p className="fs-14 text-muted fw-semibold mb-4">
                                            Ces infos nous permettent de vous envoyer de meilleures recommandations
                                            Freelance !
                                        </p>
                                        <Row>
                                            <Col lg={5}>
                                                <div className="mb-3">
                                                    <label htmlFor="tags" className="form-label">
                                                        Tags
                                                    </label>
                                                    <div className="selecte-tag position-relative">
                                                        <Select
                                                            isMulti
                                                            options={listOfOptionTags.map((tag) => ({
                                                                value: tag,
                                                                label: tag,
                                                            }))}
                                                            value={listOfOptionTags
                                                                .map((tag) => ({value: tag, label: tag}))
                                                                .filter((option) =>
                                                                    freelancePreferencesFormData.tags.includes(option.value)
                                                                )}
                                                            onChange={handleFreelanceTagsChange}
                                                            placeholder="Sélectionner les tags"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={7}>
                                                <div className="mb-3">
                                                    <label htmlFor="localities" className="form-label">
                                                        Localité(s) recherchée(s) Freelance
                                                    </label>
                                                    <div className="selecte-tag position-relative">
                                                        <Select
                                                            isMulti
                                                            options={optionsLocalities}
                                                            value={optionsLocalities?.filter((option) =>
                                                                freelancePreferencesFormData.jobAddress.some(
                                                                    (address) => address.country === option.value
                                                                )
                                                            )}
                                                            placeholder="Sélectionner les localités Freelance..."
                                                            onChange={(value) =>
                                                                handleFreelanceAddressChange("jobAddress", value)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <label htmlFor="jobs" className="form-label">
                                                        Métier recherché(s) Freelance
                                                    </label>
                                                    <div className="selecte-tag position-relative">
                                                        <Select
                                                            isMulti
                                                            options={domainOptions}
                                                            value={domainOptions?.filter((option) =>
                                                                freelancePreferencesFormData.jobCategoryIds.includes(option.value)
                                                            )}
                                                            onChange={handleFreelanceDomainChange}
                                                            placeholder="Sélectionner les domaines Freelance"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="experience" className="form-label">
                                                        Expérience (en années)
                                                    </label>
                                                    <Input
                                                        type="number"
                                                        className="form-control"
                                                        id="experience"
                                                        value={freelancePreferencesFormData.experience || ""}
                                                        onChange={(e) =>
                                                            setFreelancePreferencesFormData({
                                                                ...freelancePreferencesFormData,
                                                                experience: e.target.value,
                                                            })
                                                        }
                                                        placeholder="Entrez votre expérience en années"
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="rate" className="form-label">
                                                        Taux horaire ou tarif journalier
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="rate"
                                                        value={freelancePreferencesFormData.rate || ""}
                                                        onChange={(e) =>
                                                            setFreelancePreferencesFormData({
                                                                ...freelancePreferencesFormData,
                                                                rate: e.target.value,
                                                            })
                                                        }
                                                        placeholder="Entrez votre taux ou tarif"
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <label htmlFor="paymentMode" className="form-label">
                                                        Mode de paiement
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        id="paymentMode"
                                                        value={freelancePreferencesFormData.paymentMode || ""}
                                                        onChange={(e) =>
                                                            setFreelancePreferencesFormData({
                                                                ...freelancePreferencesFormData,
                                                                paymentMode: e.target.value,
                                                            })
                                                        }
                                                    >
                                                        <option value="">-- Choisissez un mode de paiement --</option>
                                                        <option value="hourly">À l'heure</option>
                                                        <option value="daily">Journalier</option>
                                                        <option value="monthly">Mensuel</option>
                                                        <option value="project">Par projet</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="mt-4 mb-4 text-end">
                                        <ReactstrapButton
                                            type="submit"
                                            color="primary"
                                            className="me-2"
                                            disabled={!isFreelancePreferencesFormValid()} // Bouton grisé si le formulaire est invalide
                                        >
                                            Mettre à jour Freelance
                                        </ReactstrapButton>
                                    </div>
                                </Form>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
                <ToastContainer/>
            </Col>
        </React.Fragment>
    )
        ;
};

export default RightSideContent;
