import FreelanceJobService from "../../../../service/freelance/job";
import * as OfferActionTypes from "../types/index";
import {toast} from "react-toastify";
import {message} from "antd";

// Fonction pour récupérer toutes les offres
export const fetchAllOffers = () => async (dispatch) => {
    dispatch({type: OfferActionTypes.FETCH_OFFERS_REQUEST});
    try {
        const response = await FreelanceJobService.fetchAllOffers(); // Utilisation du service
        if (response?.data?.statusCode === 200) {
            console.log("Toutes les offres :", response.data);
            dispatch({
                type: OfferActionTypes.FETCH_OFFERS_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.error("Erreur lors de la récupération des offres :", error);
        toast.error(error.response?.data?.message || error.message, {
            position: toast.POSITION.TOP_CENTER,
        });
        dispatch({
            type: OfferActionTypes.FETCH_OFFERS_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
    }
};

// Fonction pour récupérer les offres paginées
export const fetchOffersByPage =
    (selectedDateFilter, query, domaines, country, experience, profil, page = 1) =>
        async (dispatch) => {
            dispatch({type: OfferActionTypes.FETCH_OFFERS_REQUEST});
            try {
                const response = await FreelanceJobService.fetchOffersByPage(
                    selectedDateFilter,
                    query,
                    domaines,
                    country,
                    experience,
                    profil,
                    page
                ); // Utilisation du service
                if (response && response.data && response.data.statusCode === 200) {
                    // console.log(`Offres de la page ${page} :`, response.data);
                    dispatch({
                        type: OfferActionTypes.FETCH_OFFERS_SUCCESS,
                        payload: response.data,
                    });
                }
            } catch (error) {
                console.error(
                    `Erreur lors de la récupération des offres de la page ${page} :`,
                    error
                );
                toast.error(error.response?.data?.message || error.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                dispatch({
                    type: OfferActionTypes.FETCH_OFFERS_FAILURE,
                    payload: error.response?.data?.message || error.message,
                });
            }
        };


// Fonction pour récupérer les détails d'une offre par slug
export const fetchOfferDetails = (slug) => async (dispatch) => {
    dispatch({type: OfferActionTypes.FETCH_OFFER_DETAILS_REQUEST});
    try {
        const response = await FreelanceJobService.fetchOfferDetails(slug); // Utilisation du service
        if (response && response.data && response.data.statusCode === 200) {
            console.log(`Détails de l'offre (${slug}) :`, response.data);
            dispatch({
                type: OfferActionTypes.FETCH_OFFER_DETAILS_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.error(
            `Erreur lors de la récupération des détails de l'offre (${slug}) :`,
            error
        );
        toast.error(error.response?.data?.message || error.message, {
            position: toast.POSITION.TOP_CENTER,
        });
        dispatch({
            type: OfferActionTypes.FETCH_OFFER_DETAILS_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
    }
};

// Fonction pour récupérer les offres similaires
export const fetchSimilarOffers = (slug) => async (dispatch) => {
    dispatch({type: OfferActionTypes.FETCH_SIMILAR_OFFERS_REQUEST});
    try {
        const response = await FreelanceJobService.fetchSimilarOffers(slug); // Utilisation du service
        if (response && response.data && response.data.statusCode === 200) {
            console.log(response.data);
            dispatch({
                type: OfferActionTypes.FETCH_SIMILAR_OFFERS_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.error(
            "Erreur lors de la récupération des offres similaires :",
            error
        );
        toast.error(error.response?.data?.message || error.message, {
            position: toast.POSITION.TOP_CENTER,
        });
        dispatch({
            type: OfferActionTypes.FETCH_SIMILAR_OFFERS_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
    }
};

// Fontion pour récupérer les adresses des offres
export const fetchAddressOffers = () => async (dispatch) => {
    dispatch({type: OfferActionTypes.FETCH_OFFERS_ADDRESSES_REQUEST});
    try {
        const response = await FreelanceJobService.fetchAddressOffers(); // Utilisation du service
        if (response?.status === 200) {
            dispatch({
                type: OfferActionTypes.FETCH_OFFERS_ADDRESSES_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        toast.error(error.response?.data?.message || error.message, {
            position: toast.POSITION.TOP_CENTER,
        });
        dispatch({
            type: OfferActionTypes.FETCH_OFFERS_ADDRESSES_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
    }
};

export const fetchFavOffers =
    (search, page = 1) =>
        async (dispatch) => {
            dispatch({type: OfferActionTypes.FETCH_FAVORITE_OFFERS_REQUEST});
            try {
                const response = await FreelanceJobService.fetchFavOffers(search, page);
                if (response?.status === 200) {
                    dispatch({
                        type: OfferActionTypes.FETCH_FAVORITE_OFFERS_SUCCESS,
                        payload: response.data,
                    });
                }
            } catch (error) {
                toast.error(error.response?.data?.message || error.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                dispatch({
                    type: OfferActionTypes.FETCH_FAVORITE_OFFERS_FAILURE,
                    payload: error.response?.data?.message || error.message,
                });
            }
        };

export const addOfferInFavorite = (offerId) => async (dispatch) => {
    dispatch({type: OfferActionTypes.ADD_FAVORITE_OFFER_REQUEST});
    try {
        const response = await FreelanceJobService.addOfferInFavorite(offerId);
        if (response && response.data && response.data.statusCode === 201) {
            dispatch({
                type: OfferActionTypes.ADD_FAVORITE_OFFER_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        toast.error(error.response?.data?.message || error.message, {
            position: toast.POSITION.TOP_CENTER,
        });
        dispatch({
            type: OfferActionTypes.ADD_FAVORITE_OFFER_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
    }
};

export const removeOfferInFavorite = (favOfferId) => async (dispatch) => {
    dispatch({type: OfferActionTypes.REMOVE_FAVORITE_OFFER_REQUEST});
    try {
        const response = await FreelanceJobService.removeOfferInFavorite(
            favOfferId
        );
        if (response && response.data && response.data.statusCode === 204) {
            dispatch({
                type: OfferActionTypes.REMOVE_FAVORITE_OFFER_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        toast.error(error.response?.data?.message || error.message, {
            position: toast.POSITION.TOP_CENTER,
        });
        dispatch({
            type: OfferActionTypes.REMOVE_FAVORITE_OFFER_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
    }
};

//Postuler à une mission
export const applyOffer = (jobId, message, duration, price, devise) => {
    return async (dispatch) => {
        try {
            const response = await FreelanceJobService.applyOffer(jobId, message, duration, price, devise);
            if (response && response.data && response.data.statusCode === 201) {
                dispatch({
                    type: OfferActionTypes.APPLY_FREELANCE_JOB_SUCCESS,
                    payload: response.data,
                });
                toast.success("Candidature reçue pour l'offre freelance !", {
                    position: toast.POSITION.TOP_CENTER,
                });
                return;
            }
            if (response && response.data && response.data?.statusCode === 510) {
                toast.error("Vous avez deja postuler a cette offre.", {
                    position: toast.POSITION.TOP_CENTER,
                });
                return;
            }
            if (response && response.data && response.data.statusCode === 429) {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                return;
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_CENTER,
            });
            dispatch({
                type: OfferActionTypes.APPLY_FREELANCE_JOB_FAILURE,
                payload: errorMessage,
            });
        }
    };
};


// Fonction pour récupérer les candidatures d'une offre
export const fetchOfferApplications = (offerSlug) => async (dispatch) => {
    dispatch({
        type: OfferActionTypes.FETCH_OFFER_APPLICATIONS_REQUEST,
    });

    try {
        // Appel au service FreelanceJobService pour récupérer les candidatures d'une offre
        const response = await FreelanceJobService.fetchOfferApplications(offerSlug);
        if (response && response.data && response.data.statusCode === 200) {
            dispatch({
                type: OfferActionTypes.FETCH_OFFER_APPLICATIONS_SUCCESS,
                payload: response.data,  // Envoi des données des candidatures à Redux
            });
        } else {
            throw new Error("Erreur lors de la récupération des candidatures.");
        }
    } catch (error) {
        // Gestion des erreurs
        toast.error(
            error.response?.data?.message || "Erreur lors de la récupération des candidatures",
            {
                position: toast.POSITION.TOP_CENTER,
            }
        );
        dispatch({
            type: OfferActionTypes.FETCH_OFFER_APPLICATIONS_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
    }
};


//Pour récupérer les offres candidatées
export const fetchFreelancerApplications = (page = 1) => async (dispatch) => {
    dispatch({type: OfferActionTypes.FETCH_APPLICATIONS_FREELANCE_JOB_REQUEST,});
    try {
        const response = await FreelanceJobService.fetchApplications(page);
        if (response && response.data && response.data.statusCode === 200) {
            dispatch({
                type: OfferActionTypes.FETCH_APPLICATIONS_FREELANCE_JOB_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        toast.error(error.response?.data?.message || "Erreur lors de la récupération des candidatures",
            {
                position: toast.POSITION.TOP_CENTER,
            });
        dispatch({
            type: OfferActionTypes.FETCH_APPLICATIONS_FREELANCE_JOB_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
    }
};


// Supprimer une candidature
export const removeFreelancerApplication = (slug) => async (dispatch) => {
    dispatch({type: OfferActionTypes.REMOVE_APPLICATION_REQUEST});
    try {
        const response = await FreelanceJobService.removeApplication(slug); // Suppression via le slug
        if (response && response.data && response.data.statusCode === 204) {
            dispatch({
                type: OfferActionTypes.REMOVE_APPLICATION_SUCCESS,
                payload: response.data,
            });
            toast.success("Candidature supprimée avec succès !", {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }
        if (response && response.data && response.data.statusCode === 510) {
            dispatch({
                type: OfferActionTypes.REMOVE_APPLICATION_SUCCESS,
                payload: response.data,
            });
            toast.error(response.data.message || "Vous ne pouvez pas retirer votre candidature", {
                position: toast.POSITION.TOP_CENTER,
            })
            return;
        }
    } catch (error) {
        dispatch({
            type: OfferActionTypes.REMOVE_APPLICATION_FAILURE,
            payload: error.response?.data?.message || "Erreur lors de la suppression",
        });
        toast.error(
            error.response?.data?.message ||
            "Erreur lors de la suppression de la candidature",
            {
                position: toast.POSITION.TOP_CENTER,
            }
        );
    }
};

export const fetchRecentFreelanceJobs = () => {
    return async (dispatch) => {
        dispatch({type: OfferActionTypes.FETCH_RECENT_FREELANCE_JOBS_REQUEST});
        try {
            const response = await FreelanceJobService.fetchRecentFreelanceJobs();
            if (response && response.data && response.data.statusCode === 200) {
                dispatch({
                    type: OfferActionTypes.FETCH_RECENT_FREELANCE_JOBS_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({
                type: OfferActionTypes.FETCH_RECENT_FREELANCE_JOBS_FAILURE,
                payload: error.message,
            });
        }
    };
};

// Fonction pour récupérer les détails d'une proposition
export const fetchProposalDetails = (slug) => async (dispatch) => {
    dispatch({type: OfferActionTypes.FETCH_PROPOSAL_DETAILS_REQUEST});
    try {
        const response = await FreelanceJobService.fetchProposalDetails(slug); // Utilisation du service pour récupérer les détails de la proposition
        if (response && response.data && response.data.statusCode === 200) {
            console.log(`Détails de la proposition (${slug}) :`, response.data);
            dispatch({
                type: OfferActionTypes.FETCH_PROPOSAL_DETAILS_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.error(
            `Erreur lors de la récupération des détails de la proposition (${slug}) :`,
            error
        );
        toast.error(error.response?.data?.message || error.message, {
            position: toast.POSITION.TOP_CENTER,
        });
        dispatch({
            type: OfferActionTypes.FETCH_PROPOSAL_DETAILS_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
    }
};

//La récupération des contrats

export const fetchContracts = () => async (dispatch) => {
    dispatch({type: OfferActionTypes.FETCH_CONTRACTS_REQUEST});
    try {
        // Données mockées
        const mockContracts = [
            {
                id: 1,
                contractNumber: "CTR-2025001",
                title: "Développement d'une application mobile",
                company: "Tech Solutions",
                startDate: "2025-01-15",
                endDate: "2025-06-15",
                status: "En cours",
            },
            {
                id: 2,
                contractNumber: "CTR-2025002",
                title: "Création d'un site web",
                company: "Web Solutions",
                startDate: "2025-02-01",
                endDate: "2025-08-01",
                status: "Terminé",
            },
            {
                id: 3,
                contractNumber: "CTR-2025003",
                title: "Développement d'un logiciel ERP",
                company: "SoftCorp",
                startDate: "2025-03-01",
                endDate: "2025-12-01",
                status: "Annulé",
            },
        ];

        // Simule un délai d'attente pour la récupération des données
        setTimeout(() => {
            dispatch({
                type: OfferActionTypes.FETCH_CONTRACTS_SUCCESS,
                payload: mockContracts,
            });
        }, 1000); // Simule un délai d'attente de 1 seconde
    } catch (error) {
        dispatch({
            type: OfferActionTypes.FETCH_CONTRACTS_FAILURE,
            payload: error.message,
        });
    }
};

// details contrats

export const fetchContractDetails = (id) => {
    return (dispatch) => {
        dispatch({type: OfferActionTypes.FETCH_CONTRACT_DETAILS_REQUEST});

        setTimeout(() => {
            const mockContracts = [{
                id: 1,
                contractNumber: "CTR-2025001",
                title: "Développement d'une application mobile",
                company: {
                    name: "Tech Solutions",
                    address: "15 Rue des Innovateurs, 75001 Paris, France",
                    taxId: "FR123456789",
                },
                freelancer: {
                    name: "Alex Dupont",
                    email: "alex.dupont@freelance.com",
                    phone: "+33 6 12 34 56 78",
                    address: "8 Avenue du Code, 69001 Lyon, France",
                },
                startDate: "2025-01-15",
                endDate: "2025-06-15",
                status: {
                    current: "En cours",
                    lastUpdated: "2025-03-20",
                },
                description: "Le projet consiste à concevoir et développer une application mobile innovante pour un client opérant dans le secteur technologique. L’application vise à offrir une expérience utilisateur fluide et intuitive, tout en intégrant des fonctionnalités avancées telles que l’authentification sécurisée, les notifications en temps réel et la gestion de contenu dynamique. Le freelance devra collaborer étroitement avec l’équipe produit de l’entreprise pour affiner les spécifications et garantir l’alignement avec les besoins du marché. Une première phase d’étude permettra de définir les exigences fonctionnelles et techniques, suivie par la conception de l’interface utilisateur. Le développement s’effectuera en plusieurs étapes : création du backend, intégration des API, et développement du front-end avec une approche mobile-first. Un accent particulier sera mis sur la performance et l’optimisation du temps de réponse. Des tests rigoureux seront réalisés pour assurer la stabilité et la compatibilité avec les principaux appareils mobiles. Une version bêta sera déployée avant la mise en production afin de recueillir les retours des premiers utilisateurs. Enfin, le freelance devra fournir une documentation complète pour assurer la maintenance et les futures mises à jour.",
                tasks: [
                    {
                        title: "Définition des spécifications",
                        details: [
                            "Analyse des besoins du client",
                            "Rédaction du cahier des charges",
                            "Validation des fonctionnalités avec l'entreprise",
                        ]
                    },
                    {
                        title: "Design de l'interface",
                        details: [
                            "Création des wireframes",
                            "Développement de la charte graphique",
                            "Tests UX avec les utilisateurs cibles",
                        ]
                    },
                    {
                        title: "Développement des fonctionnalités principales",
                        details: [
                            "Intégration des API externes",
                            "Développement des modules front-end et back-end",
                            "Mise en place de l’authentification sécurisée",
                        ]
                    },
                    {
                        title: "Tests de performance",
                        details: [
                            "Tests unitaires et d'intégration",
                            "Optimisation du temps de chargement",
                            "Correction des bugs et ajustements",
                        ]
                    }
                ],
                budget: {
                    total: "100,000€",
                    paymentMethod: "Virement bancaire",
                    paymentSchedule: [
                        {milestone: "Acompte", amount: "30,000€", dueDate: "2025-01-20"},
                        {milestone: "Milieu de projet", amount: "40,000€", dueDate: "2025-04-01"},
                        {milestone: "Finalisation", amount: "30,000€", dueDate: "2025-06-15"},
                    ],
                    penalties: {
                        latePayment: "5% de pénalité par semaine de retard",
                        deliveryDelay: "10% de réduction si la livraison dépasse 30 jours de retard",
                    }
                }
            },
                {
                    id: 2,
                    contractNumber: "CTR-2025002",
                    title: "Conception d'un site e-commerce",
                    company: {
                        name: "WebMarket",
                        address: "22 Boulevard du Commerce, 75002 Paris, France",
                        taxId: "FR987654321",
                    },
                    freelancer: {
                        name: "Sophie Martin",
                        email: "sophie.martin@freelance.com",
                        phone: "+33 7 98 76 54 32",
                        address: "10 Place du Digital, 33000 Bordeaux, France",
                    },
                    startDate: "2025-02-01",
                    endDate: "2025-07-30",
                    status: {
                        current: "Terminé",
                        lastUpdated: "2025-07-30",
                    },
                    description: "Ce projet a pour objectif la création d’une plateforme de commerce en ligne performante et sécurisée pour l’entreprise WebMarket. La mission inclut l’élaboration d’un design ergonomique et responsive, garantissant une navigation fluide sur mobile et desktop. Le freelance sera chargé de concevoir l’architecture du site, en intégrant une base de données robuste permettant la gestion des produits, des commandes et des utilisateurs. Le développement du front-end se fera avec une attention particulière sur l’expérience utilisateur, incluant une interface moderne et intuitive. L’intégration d’un système de paiement sécurisé avec Stripe et PayPal sera essentielle pour garantir des transactions fiables. Une infrastructure cloud sera mise en place afin d’assurer une disponibilité optimale et une scalabilité en fonction du trafic. Le site devra être optimisé pour le référencement naturel (SEO) afin d’améliorer sa visibilité sur les moteurs de recherche. Des fonctionnalités avancées, comme la gestion des avis clients et les recommandations personnalisées, seront développées pour renforcer l’engagement des utilisateurs. Enfin, une phase de test sera réalisée avant le lancement officiel, avec une attention particulière portée à la performance, la sécurité et l’ergonomie du site.",
                    tasks: [
                        {
                            title: "Création de la maquette",
                            details: [
                                "Définition de l’arborescence du site",
                                "Conception graphique des pages principales",
                                "Validation avec le client",
                            ]
                        },
                        {
                            title: "Intégration du système de paiement",
                            details: [
                                "Configuration des solutions Stripe et PayPal",
                                "Mise en place de la gestion des paniers",
                                "Tests des transactions sécurisées",
                            ]
                        },
                        {
                            title: "Mise en place de l'infrastructure serveur",
                            details: [
                                "Configuration du serveur cloud AWS",
                                "Mise en place de la base de données MySQL",
                                "Sécurisation des accès",
                            ]
                        },
                        {
                            title: "Test utilisateur",
                            details: [
                                "Recueil des retours utilisateurs",
                                "Corrections et optimisations",
                                "Finalisation et mise en production",
                            ]
                        }
                    ],
                    budget: {
                        total: "200,000€",
                        paymentMethod: "Virement bancaire",
                        paymentSchedule: [
                            {milestone: "Acompte", amount: "50,000€", dueDate: "2025-02-10"},
                            {milestone: "Avancement à 50%", amount: "75,000€", dueDate: "2025-05-01"},
                            {milestone: "Livraison finale", amount: "75,000€", dueDate: "2025-07-30"},
                        ],
                        penalties: {
                            latePayment: "4% de pénalité par semaine de retard",
                            deliveryDelay: "5% de réduction en cas de retard supérieur à 20 jours",
                        }
                    }
                }]; // Garde ta liste mockée ici

            const contract = mockContracts.find((c) => c.id === id);

            if (contract) {
                dispatch({type: OfferActionTypes.FETCH_CONTRACT_DETAILS_SUCCESS, payload: contract});
            } else {
                dispatch({
                    type: OfferActionTypes.FETCH_CONTRACT_DETAILS_FAILURE,
                    payload: "Contrat non trouvé",
                });
                message.error("Contrat non trouvé");
            }
        }, 1000);
    };
};

