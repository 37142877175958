import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { fetchFavOffers, removeOfferInFavorite } from "../../../../redux/freelance/offer/action";

const FavOfferContext = createContext({
    query: "",
    setQuery: () => {},
    currentPage: 1,
    setCurrentPage: () => {},
    handleSearch: () => {},
    handleClickUnFav: () => {},
});

export const FavOfferProvider = ({ children }) => {
    const dispatch = useDispatch();

    // États pour la recherche et la pagination
    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    // Effet pour le chargement des offres favorites
    useEffect(() => {
        dispatch(fetchFavOffers(query, currentPage)).catch((error) => {
            console.error("Erreur lors du chargement des offres favorites:", error);
        });
    }, [query, currentPage, dispatch]);

    // Handler pour la recherche avec useCallback
    const handleSearch = useCallback(() => {
        setCurrentPage(1); // Réinitialiser à la première page lors d'une nouvelle recherche
        dispatch(fetchFavOffers(query, 1)).catch((error) => {
            console.error("Erreur lors de la recherche:", error);
        });
    }, [query, dispatch]);

    // Handler pour retirer une offre des favoris
    const handleClickUnFav = useCallback((offerId) => {
        if (!offerId) return;

        dispatch(removeOfferInFavorite(offerId))
            .then(() => {
                // Après avoir retiré des favoris, rafraîchir la liste
                dispatch(fetchFavOffers(query, 1));
            })
            .catch((error) => {
                console.error("Erreur lors du retrait des favoris:", error);
            });
    }, [query, dispatch]);

    // Valeurs exposées par le contexte
    const contextValue = {
        query,
        setQuery,
        currentPage,
        setCurrentPage,
        handleSearch,
        handleClickUnFav,
    };

    return (
        <FavOfferContext.Provider value={contextValue}>
            {children}
        </FavOfferContext.Provider>
    );
};

export const useFavOfferContext = () => {
    const context = useContext(FavOfferContext);

    if (!context) {
        throw new Error("useFavOfferContext doit être utilisé à l'intérieur d'un FavOfferProvider");
    }

    return context;
};
