import React, {useEffect, useState, useRef} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Card, Spin, Typography, List, Modal, button, Button, message} from "antd";
import SignatureCanvas from "react-signature-canvas";
import {fetchContractDetails} from "../../../../redux/freelance/offer/action";

const {Title, Text} = Typography;

const ContractDetailPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [signature, setSignature] = useState(null);
    const sigCanvas = useRef(null);

    const {contract, loading, error} = useSelector((state) => state.offerReducer);

    useEffect(() => {
        if (!isNaN(id)) {
            dispatch(fetchContractDetails(Number(id)));
        }
    }, [dispatch, id]);

    if (loading) {
        return (
            <div style={{textAlign: "center", padding: "20px"}}>
                <Spin size="large"/>
            </div>
        );
    }

    if (error || !contract) {
        return <p style={{textAlign: "center", color: "red"}}>Contrat non trouvé</p>;
    }

    const showSignatureModal = () => setIsModalVisible(true);
    const handleCancel = () => setIsModalVisible(false);

    const handleSaveSignature = () => {
        const signatureData = sigCanvas.current.toDataURL();
        setSignature(signatureData);
        setIsModalVisible(false);
    };

    const handleClearSignature = () => sigCanvas.current.clear();

    return (
        <div style={{maxWidth: "800px", margin: "0 auto", padding: "20px"}}>
            <Card className="shadow-lg" style={{border: "1px solid #e8e8e8", borderRadius: "8px"}}>
                <Title level={2} style={{textAlign: "center", marginBottom: "24px"}}>
                    Contrat de Travail
                </Title>

                <div style={{marginBottom: "16px"}}>
                    <Text strong>Nº Contrat:</Text> {contract.contractNumber || "N/A"}
                </div>

                {contract.company && typeof contract.company === "object" ? (
                    <div style={{marginBottom: "16px"}}>
                        <Text strong>Entreprise:</Text> {contract.company.name || "N/A"} <br/>
                        <Text strong>Adresse:</Text> {contract.company.address || "N/A"} <br/>
                        <Text strong>ID Fiscal:</Text> {contract.company.taxId || "N/A"}
                    </div>
                ) : (
                    <div style={{marginBottom: "16px"}}>
                        <Text strong>Entreprise:</Text> {contract.company || "N/A"}
                    </div>
                )}

                <div style={{marginBottom: "16px"}}>
                    <Text strong>Date de Début:</Text> {contract.startDate || "N/A"}
                </div>
                <div style={{marginBottom: "16px"}}>
                    <Text strong>Date de Fin:</Text> {contract.endDate || "N/A"}
                </div>
                <div style={{marginBottom: "16px"}}>
                    <Text strong>Statut:</Text>
                    {contract.status && typeof contract.status === "object" ? (
                        <>
                            {contract.status.current || "N/A"} (Mis à jour: {contract.status.lastUpdated || "N/A"})
                        </>
                    ) : (
                        contract.status || "N/A"
                    )}
                </div>
                <div style={{marginBottom: "16px"}}>
                    <Text strong>Description:</Text> {contract.description || "Aucune description"}
                </div>
                <div style={{marginBottom: "16px"}}>
                    <Text strong>Budget:</Text> {contract.budget.total || "Non précisé"} <br/>
                    <Text strong>Méthode de Paiement:</Text> {contract.budget.paymentMethod || "Non précisé"} <br/>
                    <Text strong>Echéancier de Paiement:</Text>
                    <ul>
                        {contract.budget.paymentSchedule && contract.budget.paymentSchedule.length > 0 ? (
                            contract.budget.paymentSchedule.map((item, index) => (
                                <li key={index}>
                                    {item.milestone}: {item.amount} (Date d'échéance: {item.dueDate})
                                </li>
                            ))
                        ) : (
                            <li>Non précisé</li>
                        )}
                    </ul>
                    <Text strong>Pénalités:</Text>
                    {contract.budget.penalties ? (
                        <div>
                            <p>Retard de paiement: {contract.budget.penalties.latePayment}</p>
                            <p>Retard de livraison: {contract.budget.penalties.deliveryDelay}</p>
                        </div>
                    ) : (
                        <p>Aucune pénalité</p>
                    )}
                </div>

                <div style={{marginBottom: "24px"}}>
                    <Text strong>Personne de
                        contact:</Text> {contract.contactPerson || "Non précisé"} ({contract.contactEmail || "Non précisé"})
                </div>

                <Title level={4}>Tâches à réaliser</Title>
                {contract.tasks && contract.tasks.length > 0 ? (
                    <List
                        bordered
                        dataSource={contract.tasks}
                        renderItem={(task) => (
                            <List.Item>
                                <Text strong>{task.title}</Text>
                                <ul>
                                    {task.details.map((detail, index) => (
                                        <li key={index}>{detail}</li>
                                    ))}
                                </ul>
                            </List.Item>
                        )}
                        style={{marginBottom: "24px"}}
                    />
                ) : (
                    <p>Aucune tâche disponible</p>
                )}

                <div style={{marginTop: "24px", textAlign: "center"}}>
                    {signature ? (
                        <div>
                            <Text strong>Signature:</Text>
                            <img src={signature} alt="Signature"
                                 style={{width: "200px", border: "1px solid #e8e8e8", marginTop: "8px"}}/>
                        </div>
                    ) : (
                        <button class="btn btn-primary btn-hover" onClick={showSignatureModal}>
                            Signer le contrat
                        </button>
                    )}
                </div>
            </Card>

            <Modal
                title="Signer le contrat"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <div >
                        <button key="clear" class="btn-danger btn-hover rounded" onClick={handleClearSignature} danger>
                            Effacer
                        </button>
                        ,
                        <button key="save" class="btn-primary btn-hover rounded" onClick={handleSaveSignature}>
                            Enregistrer la signature
                        </button>,
                    </div>
                ]}
                centered
            >
                <div
                    style={{
                        border: "1px solid #e8e8e8",
                        borderRadius: "4px",
                        userSelect: "none",
                    }}
                >
                    <SignatureCanvas
                        ref={sigCanvas}
                        canvasProps={{
                            width: 450,
                            height: 200,
                            className: "signature-canvas",
                            style: {touchAction: "none"},
                        }}
                        penColor="black"
                        clearOnResize={false}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default ContractDetailPage;
