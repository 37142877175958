import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button as ReactstrapButton, // Renommé pour éviter un conflit avec le bouton d'Ant Design
} from "reactstrap";
import { DownOutlined, RightOutlined, UploadOutlined } from "@ant-design/icons"; // Correction ici
import { Upload, Button, message } from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchContractTypes,
  addCandidateExperience,
  fetchCandidateExperienceById,
  updateCandidateExperience,
  resetSelectedDetails,
} from "../../../../redux/profile/action";
import { toast, ToastContainer } from "react-toastify";

const ExperienceForm = ({ selectedExperience, onCancel }) => {
  const [showMoreInfo, setShowMoreInfo] = useState(false); // Pour gérer l'affichage du dropdown
  const [showMoreEntrepriseInfo, setShowMoreEntrepriseInfo] = useState(false); // Pour gérer l'affichage du dropdown
  const [fileName, setFileName] = useState(""); // Pour stocker le nom du fichier
  const [isValid, setIsValid] = useState(false);

  const initialExperienceState = {
    position: "",
    startDate: "",
    endDate: "",
    country: "",
    city: "",
    contractTypeId: "",
    company: "",
    companyLogo: null,
    summaryCompany: "",
    websiteCompany: "",
    context: "",
    actions: "",
    results: "",
    technicalEnvironment: "",
  };

  const [experience, setExperience] = useState(initialExperienceState); // Initialiser avec l'état initial

  const dispatch = useDispatch();
  const { contractTypes, selectedDetails } = useSelector(
    (state) => state.profileReducer
  );

  const transformExperienceData = (selectedDetails) => {
    return {
      position: selectedDetails.position || "",
      startDate: selectedDetails.startDate || "",
      endDate: selectedDetails.endDate || "",
      country: selectedDetails.address?.country || "", // Utilisation de l'opérateur de coalescence nulle
      city: selectedDetails.address?.city || "", // Utilisation de l'opérateur de coalescence nulle
      contractTypeId: selectedDetails.contractType?.id || "", // Récupérer l'ID du type de contrat
      company: selectedDetails.company || "",
      companyLogo: selectedDetails.urlPictureCompany || null, // Renommer si nécessaire
      summaryCompany: selectedDetails.summaryCompany || "",
      websiteCompany: selectedDetails.websiteCompany || "",
      context: selectedDetails.context || "",
      actions: selectedDetails.actions || "",
      results: selectedDetails.results || "",
      technicalEnvironment: selectedDetails.technicalEnvironment || "",
    };
  };

  useEffect(() => {
    dispatch(fetchContractTypes());
    const loadExperience = async () => {
      if (selectedExperience) {
        // console.log("LoadingExperience", selectedExperience);
        dispatch(fetchCandidateExperienceById(selectedExperience.id));
      }
    };
    loadExperience();
  }, [dispatch, selectedExperience]);

  useEffect(() => {
    if (selectedDetails) {
      const formattedExperience = transformExperienceData(selectedDetails);
      setExperience(formattedExperience);
    }
  }, [selectedDetails]);

  const handleChange = (e) => {
    setExperience({ ...experience, [e.target.name]: e.target.value });
  };

  const handleFileChange = (info) => {
    const file = info.file.originFileObj; // Récupérer le fichier sélectionné
    setExperience({ ...experience, companyLogo: file });
    setFileName(file ? file.name : ""); // Mettre à jour le nom du fichier
    if (info.file.status === "done") {
      message.success(`${info.file.name} a été téléchargé avec succès.`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedExperience = {
      contractTypeId: experience.contractTypeId,
      position: experience.position,
      startDate: experience.startDate,
      endDate: experience.endDate,
      company: experience.company,
      summaryCompany: experience.summaryCompany,
      websiteCompany: experience.websiteCompany,
      context: experience.context,
      actions: experience.actions,
      results: experience.results,
      technicalEnvironment: experience.technicalEnvironment,
      address: {
        country: experience.country,
        city: experience.city,
      },
      id: selectedExperience?.id,
    };

    if (selectedExperience?.id) {
      // Logique de modification
      dispatch(updateCandidateExperience(formattedExperience))
        .then(() => {
          onCancel();
          resetForm(); // Réinitialiser le formulaire
        })
        .catch(() => {
          toast.error("Une erreur est survenue lors de la modification !");
        });
    } else {
      // Logique d'ajout
      dispatch(addCandidateExperience(formattedExperience))
        .then(() => {
          toast.success("Expérience ajoutée avec succès !");
          onCancel();
          resetForm(); // Réinitialiser le formulaire
        })
        .catch(() => {
          toast.error("Une erreur est survenue lors de l'ajout !");
        });
    }
  };

  const resetForm = () => {
    setExperience(initialExperienceState); // Réinitialiser l'état du formulaire
    setFileName(""); // Réinitialiser le nom du fichier
    setShowMoreInfo(false); // Réinitialiser l'état du dropdown
    setShowMoreEntrepriseInfo(false); // Réinitialiser l'état du dropdown
  };

  // Fonction pour vérifier si le formulaire est valide
  const isFormValid = useCallback(() => {
    return (
      (experience.position ?? "").trim() !== "" &&
      (experience.startDate ?? "").trim() !== "" &&
      (experience.endDate ?? "").trim() !== "" &&
      (experience.city ?? "").trim() !== "" &&
      (experience.country ?? "").trim() !== "" &&
      String(experience.contractTypeId ?? "").trim() !== "" &&
      (experience.company ?? "").trim() !== "" &&
      (experience.context ?? "").trim() !== "" &&
      (experience.actions ?? "").trim() !== "" &&
      (experience.results ?? "").trim() !== "" &&
      (experience.technicalEnvironment ?? "").trim() !== ""
    );
  }, [experience]);

  useEffect(() => {
    setIsValid(isFormValid());
    window.scrollTo(0, 0);
  }, [isFormValid]);

  return (
    <Card>
      <CardBody>
        <h6 className="text-start">Informations Générales</h6>
        <hr />
        <Form onSubmit={handleSubmit}>
          {/* Titre du poste */}
          <FormGroup>
            <Label className="text-danger" for="position">
              Intitulé du poste *
            </Label>
            <Input
              type="text"
              name="position"
              id="position"
              value={experience.position}
              onChange={handleChange}
              required
            />
          </FormGroup>

          {/* Dates */}
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className="text-danger" for="startDate">
                  Date de début *
                </Label>
                <Input
                  type="date"
                  name="startDate"
                  id="startDate"
                  value={experience.startDate}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className="text-danger" for="endDate">
                  Date de fin *
                </Label>
                <Input
                  type="date"
                  name="endDate"
                  id="endDate"
                  value={experience.endDate}
                  onChange={handleChange}
                  disabled={experience.isCurrent}
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          {/* Informations supplémentaires */}
          <FormGroup>
            <Label>
              <button
                className="btn btn-link text-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setShowMoreInfo(!showMoreInfo);
                }}
              >
                {showMoreInfo ? (
                  <>
                    <DownOutlined /> Ajouter plus d'infos générales *
                  </>
                ) : (
                  <>
                    <RightOutlined /> Ajouter plus d'infos générales *
                  </>
                )}
              </button>
            </Label>
          </FormGroup>

          {/* Champs additionnels */}
          {showMoreInfo && (
            <>
              <hr />
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="text-danger" for="city">
                      Ville *
                    </Label>
                    <Input
                      type="text"
                      name="city"
                      id="city"
                      value={experience.city}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className="text-danger" for="country">
                      Pays *
                    </Label>
                    <Input
                      type="text"
                      name="country"
                      id="country"
                      value={experience.country}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label className="text-danger" for="contractTypeId">
                  Type de contrat *
                </Label>
                <Input
                  type="select"
                  name="contractTypeId"
                  id="contractTypeId"
                  required
                  value={experience.contractTypeId}
                  onChange={(e) => handleChange(e)} // S'assurer que l'événement de changement est bien lié
                >
                  <option value="">Sélectionnez un type de contrat</option>
                  {contractTypes &&
                    contractTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </>
          )}
          <h6 className="text-start mt-5">Entreprise</h6>
          <hr />
          <FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="text-danger" for="company">
                    Nom de l'entreprise *
                  </Label>
                  <Input
                    type="text"
                    name="company"
                    id="company"
                    value={experience.company}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="companyLogo">Logo de l'entreprise</Label>
                  <Upload
                    name="companyLogo"
                    accept="image/*"
                    showUploadList={false}
                    onChange={handleFileChange}
                  >
                    <Button icon={<UploadOutlined />}>
                      {fileName || "Télécharger le logo"}
                    </Button>
                  </Upload>
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>

          {/* Informations supplémentaires sur l'entreprise */}
          <FormGroup>
            <Label>
              <button
                className="btn btn-link text-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setShowMoreEntrepriseInfo(!showMoreEntrepriseInfo);
                }}
              >
                {showMoreEntrepriseInfo ? (
                  <>
                    <DownOutlined /> Ajouter plus d'infos sur l'entreprise *
                  </>
                ) : (
                  <>
                    <RightOutlined /> Ajouter plus d'infos sur l'entreprise *
                  </>
                )}
              </button>
            </Label>
          </FormGroup>

          {showMoreEntrepriseInfo && (
            <>
              <hr />
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="summaryCompany">Résumé de l'entreprise</Label>
                    <Input
                      type="text"
                      name="summaryCompany"
                      id="summaryCompany"
                      value={experience.summaryCompany}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label for="websiteCompany">Site web de l'entreprise</Label>
                    <Input
                      type="text"
                      name="websiteCompany"
                      id="websiteCompany"
                      value={experience.websiteCompany}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label className="text-danger" for="context">
                      Objectif *
                    </Label>
                    <Input
                      type="textarea"
                      name="context"
                      id="context"
                      rows="5"
                      value={experience.context}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label className="text-danger" for="actions">
                      Missions *
                    </Label>
                    <Input
                      type="textarea"
                      name="actions"
                      id="actions"
                      value={experience.actions}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label className="text-danger" for="results">
                      Résultats *
                    </Label>
                    <Input
                      type="textarea"
                      name="results"
                      id="results"
                      value={experience.results}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label className="text-danger" for="technicalEnvironment">
                      Environnement technique *
                    </Label>
                    <Input
                      type="textarea"
                      name="technicalEnvironment"
                      id="technicalEnvironment"
                      value={experience.technicalEnvironment}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}

          <div className="text-end">
            <ReactstrapButton
              color="danger"
              type="submit"
              disabled={!isValid} // Désactive si les champs requis ne sont pas remplis
            >
              {selectedExperience?.id ? "Modifier" : "Ajouter"} l'expérience
            </ReactstrapButton>
            <ReactstrapButton
              color="primary"
              onClick={() => {
                resetForm(); // Appeler la fonction de réinitialisation
                dispatch(resetSelectedDetails()); // Réinitialiser selectedDetails
                onCancel(); // Annuler l'action
              }}
              className="ms-2"
            >
              Annuler
            </ReactstrapButton>
          </div>
        </Form>
      </CardBody>
      <ToastContainer />
    </Card>
  );
};

export default ExperienceForm;
