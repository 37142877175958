import React from "react";

// import Layout1 from "../pages/Home/Layout1/Layout1";
// import Layout2 from "../pages/Home/Layout2/Layout2";
// import Layout3 from "../pages/Home/Layout3/Layout3";

//Company Section
import AboutUs from "../pages/Company/AboutUs/AboutUs";
import Services from "../pages/Company/Services/Services";
import Team from "../pages/Company/Team/Team";
import Pricing from "../pages/Company/Pricing/Pricing";
import PrivacyAndPolicy from "../pages/Company/PrivacyAndPolicy/PrivacyAndPolicy";
import Faqs from "../pages/Company/Faqs/Faqs";

//Jobs Section
import JobList from "../pages/Jobs/JobList/JobList";
import JobList2 from "../pages/Jobs/JobList2/JobList2";
import JobGrid from "../pages/Jobs/JobGrid/JobGrid";
import JobCategory from "../pages/Jobs/JobCategory/JobCategory";
import JobGrid2 from "../pages/Jobs/JobGrid2/JobGrid2";
import JobDetails from "../pages/Jobs/JobDetails/JobDetails";
import JobsCategories from "../pages/Jobs/JobsCategories/JobsCategories";

//Candidate and Company Section
import CandidateList from "../pages/CandidateAndCompany/CandidateList/CandidateList";
import CandidateGrid from "../pages/CandidateAndCompany/CandidateGrid/CandidateGrid";
import CandidateDetails from "../pages/CandidateAndCompany/CandidateDetails/CandidateDetails";
import CompanyList from "../pages/CandidateAndCompany/CompanyList/CompanyList";
import CompanyDetails from "../pages/CandidateAndCompany/CompanyDetails/CompanyDetails";

//Blog Section
import Blog from "../pages/Blog/Blog/Blog";
import BlogGrid from "../pages/Blog/BlogGrid/BlogGrid";
import BlogModern from "../pages/Blog/BlogModern/BlogModern";
import BlogMasonary from "../pages/Blog/BlogMasonary/BlogMasonary";
import BlogDetails from "../pages/Blog/BlogDetails/BlogDetails";
import BlogAuther from "../pages/Blog/BlogAuther/BlogAuther";

//Contacts
import Contact from "../pages/Contact/Contact";

//AuthPages
import SignOut from "../pages/ExtraPages/SignOut";
import SendOtp from "../pages/ExtraPages/SendOtp";
import ResetPassword from "../pages/ExtraPages/ResetPassword";
import ComingSoon from "../pages/ExtraPages/ComingSoon";
import Error404 from "../pages/ExtraPages/Error404";
import Components from "../pages/ExtraPages/Components/Components";

//profile section(User Profile)
import BookMarkJobPost from "../pages/Profile/BookMarkJobPost/BookMarkJobPost";
import ManageJobs from "../pages/Profile/ManageJobs/ManageJobs";
import BookMarkJobs from "../pages/Profile/BookMarkJobs/BookMarkJobs";
import MyProfile from "../pages/Profile/MyProfile/MyProfile";
import MonCV from "../pages/Profile/MyProfile/CvForm/pdfGenerator";
import CompleteProfile from "../pages/Profile/CompleteProfile/CompleteProfile";

//Accompaniement section
import TestGrid from "../pages/Accompaniment/TestGrid/TestGrid";
import MultiStepForm from "../pages/Jobs/JobDetails/JobApplication/MultiStepForm ";
import NewPassword from "../pages/ExtraPages/NewPassword";
import OfferList from "../pages/Freelance/Offers/OfferList";
import MissionDetails from "../pages/Freelance/Offers/OfferDetails/JobDetails";
import OfferFavList from "../pages/Freelance/Offers/FavOffers/OfferFavList";
import MyApply from "../pages/Freelance/Offers/MyApplications/MyApply";
import ApplyDetails from "../pages/Freelance/Offers/MyApplications/MyApplicationDetails/JobDetails";
import Contracts from "../pages/Freelance/Contrats/Contracts";
import ContractsDetails from "../pages/Freelance/Contrats/DetailsContract/ContractsDetails";
import Messenger from "../pages/Freelance/Messenger/Messenger";
import Auth from "../pages/ExtraPages/Auth";

//Home Section
const Layout1 = React.lazy(() => import("../pages/Home/Layout1/Layout1"));
// const Layout2 = React.lazy(() => import('../pages/Home/Layout2/Layout2'));
const Layout3 = React.lazy(() => import("../pages/Home/Layout3/Layout3"));

const userRoutes = [
    //profile Section(User Profile)
    {path: "/bookmarkjobpost", component: <BookMarkJobPost/>},
    {path: "/myprofile", component: <MyProfile/>},
    {path: "/completeprofile", component: <CompleteProfile/>},
    {path: "/bookmarkjobs", component: <BookMarkJobs/>},
    {path: "/managejobs", component: <ManageJobs/>},

    //Components Section(Extra Pages)
    {path: "/components", component: <Components/>},

    //Contact
    {path: "/contact", component: <Contact/>},

    // Blog Section
    {path: "/myjobapplication", component: <BlogAuther/>},
    {path: "/formation-details/:slug", component: <BlogDetails/>},
    {path: "/blogmodern", component: <BlogModern/>},
    {path: "/formation", component: <BlogMasonary/>},
    {path: "/course/:slug", component: <BlogGrid/>},
    {path: "/blog", component: <Blog/>},

    //Candidate and Company Section
    {path: "/companydetails/:companyId", component: <CompanyDetails/>},
    {path: "/companies", component: <CompanyList/>},
    {path: "/academydetails/:slug", component: <CandidateDetails/>},
    {path: "/candidategrid", component: <CandidateGrid/>},
    {path: "/candidatelist", component: <CandidateList/>},

    //Jobs Section
    {path: "/apply/:type", component: <MultiStepForm/>},
    {path: "/jobscategories", component: <JobsCategories/>},
    {path: "/jobdetails/:slug", component: <JobDetails/>},
    {path: "/jobgrid2", component: <JobGrid2/>},
    {path: "/jobs", component: <JobGrid/>},
    {path: "/jobcategory/:id", component: <JobCategory/>},
    {path: "/joblist2", component: <JobList2/>},
    {path: "/academies", component: <JobList/>},

    //Company Section
    {path: "/faqs", component: <Faqs/>},
    {path: "/privacyandpolicy", component: <PrivacyAndPolicy/>},
    {path: "/technical-test", component: <Pricing/>},
    {path: "/team", component: <Team/>},
    {path: "/result-technical-test", component: <Services/>},
    {path: "/aboutus", component: <AboutUs/>},

    //Accompaniement section
    {path: "/challenge-test", component: <TestGrid/>},

    //Home Section
    {path: "/layout3", component: <Layout3/>},
    {path: "/layout2", component: <Layout1/>},
    {path: "/", component: <Layout3/>},

    {path: "/monCV", component: <MonCV/>},

    // Freelance section

    {path: "/freelance-jobs", component: <OfferList/>},
    {path: "/freelance-jobs/myfavs", component: <OfferFavList/>},
    {path: "/mission-details/:slug", component: <MissionDetails/>},
    {path: "/myfreelanceapplication", component: <MyApply/>},
    {path: "/myapplication-details/:slug", component: <ApplyDetails/>},
    {path: "/contracts", component: <Contracts/>},
    {path: "/details-contract/:id", component: <ContractsDetails/>},
    {path: "/messenger", component: <Messenger/>},
];

const authRoutes = [
    {path: "/error404", component: <Error404/>},
    {path: "/comingsoon", component: <ComingSoon/>},
    {path: "/resetpassword", component: <ResetPassword/>},
    {path: "/newpassword", component: <NewPassword/>},
    {path: "/signout", component: <SignOut/>},
    {path: "/verify", component: <SendOtp/>},
    {path: "/oauth", component: <Auth/>},
];
export {userRoutes, authRoutes};
