import axios from "axios";
import TokenService from "../service/TokenService";

const API_BASE_URL = "https://api.freelance.recrutech.pro/api/v1";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercepteurs pour ajouter un token si nécessaire
apiClient.interceptors.request.use((config) => {
  const accessToken = TokenService.getUser();
  const excludeUrls = [];
  const isExcluded = excludeUrls.some((url) => config.url.includes(url));
  if (accessToken && !isExcluded) {
    config.headers.Authorization = `Bearer ${accessToken.data.id_token}`;
  }
  return config;
});

const apiService = {
  get: (url, params = {}) => apiClient.get(url, { params }),
  post: (url, data) => apiClient.post(url, data),
  put: (url, data) => apiClient.put(url, data),
  delete: (url) => apiClient.delete(url),
};

export default apiService;
