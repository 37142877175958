import React, { useState } from "react";
import { Col, Row, Modal, ModalBody, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  formatAddress,
  renderHTMLContent,
  sliceDescription,
} from "../../../../utils";
import { CustomResponsivePagination } from "../../../../Layout/CommonLayout/Pagination";
import { FiveStarRating } from "../../../../Layout/CommonLayout/FiveStarRating";
import { useFavOfferContext } from "./FavOfferContext";
import { Trash } from "lucide-react"; // Icônes modernes

import Lottie from "react-lottie";
import JobCardItemSkeleton from "../../../Jobs/JobGrid/JobCardItemSkeleton";

import * as errorAnimationData from "../../../../../src/assets/error.json"; // Animation pour l'erreur
import * as emptyAnimationData from "../../../../../src/assets/emptyData.json"; // Animation pour données vides

const JobFavVacancy = () => {
  const { favOffers, loading, error } = useSelector(
    (state) => state.offerReducer
  );
  const { currentPage, setCurrentPage, handleClickUnFav } =
    useFavOfferContext();
  const totalPages = favOffers?.total_pages;
  // Modal
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  // Paramètres pour l'animation Lottie d'erreur
  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // Paramètres pour l'animation Lottie de données vides
  const emptyOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Row className="align-items-center mb-1">
          <Col md={12}>
            <JobCardItemSkeleton />
          </Col>
          <Col md={12}>
            <JobCardItemSkeleton />
          </Col>
        </Row>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-0">
        <Lottie options={errorOptions} height={300} width={300} />
        <p className="text-danger mt-0">Une erreur s'est produite : {error}</p>
      </div>
    );
  }

  if (favOffers?.data?.length === 0) {
    return (
      <div className="text-center mt-0">
        <Lottie options={emptyOptions} height={300} width={300} />
        <p className="text-muted mt-0">
          Aucune offre trouvée. Veuillez réessayer plus tard.
        </p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Row className="mb-3 align-items-center">
        {favOffers?.data?.map((offer, key) => (
          <Col md={6} key={key}>
            <div className="job-box card mt-4 shadow-sm border-0 position-relative">
              <div className="p-4">
                <Row className="align-items-center">
                  <Col lg={12}>
                    <div>
                      <h5 className="fs-17 mb-1 d-flex align-items-center">
                        {/* Logo et Titre à côté */}
                        <img
                          src={
                            offer.offer.enterprise_logo ||
                            "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                          }
                          alt={offer.offer.enterprise_name || "Entreprise"}
                          className="img-fluid rounded-3"
                          style={{ maxWidth: "60px", height: "auto" }}
                        />
                        <Link
                          to={`/mission-details/${offer.offer.slug}`}
                          className="text-dark fw-bold"
                        >
                          {offer.offer.enterprise_name}
                        </Link>
                      </h5>
                      {/* Le bouton de suppression dans le coin inférieur droit */}
                      <div className="position-absolute top-0 end-0 p-3">
                        <Link
                          to="#"
                          onClick={() => {
                            handleClickUnFav(offer?.offer?.id);
                          }}
                          className="text-white bg-warning rounded-circle p-2"
                        >
                          <Trash size={18} className="text-withe" />
                        </Link>
                      </div>
                      <p className="text-muted mb-3 d-flex justify-content-between">
                        <span className="d-flex align-items-center">
                          {/* <i className="uil uil-building text-start"></i>{" "} */}
                          {offer.offer.title}
                        </span>
                      </p>
                      <div className="job-detail-desc">
                        <div
                          dangerouslySetInnerHTML={renderHTMLContent(
                            sliceDescription(offer.offer.description, 200)
                          )}
                        />
                      </div>
                      <div className="mt-3">
                        <ul className="list-inline d-flex flex-wrap gap-2">
                          {offer.offer.skills?.map((skill, index) => (
                            <li className="list-inline-item" key={index}>
                              <span className="badge bg-info">
                                {skill.skill.name}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="p-3 bg-light">
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                  <div>
                    <span className="d-flex align-items-center">
                      <i className="mdi mdi-map-marker text-end"></i>{" "}
                      {offer.offer.address
                        ? formatAddress(offer.offer.address)
                        : "Non renseigné"}
                    </span>
                  </div>
                  <FiveStarRating rating={0} />
                  <small className="text-muted fw-normal">
                    <i className="uil uil-hourglass"></i> {offer.offer.duration}{" "}
                    jours
                  </small>
                  <p className="text-muted mb-0">
                    <i className="uil uil-wallet"></i>{" "}
                    {Math.floor(offer?.offer.budget)} {offer.offer.devise}
                  </p>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
      {/* Modal de candidature */}
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalBody className="p-4">
          <div className="text-center mb-4">
            <h5 className="modal-title">Postuler à cette Offre</h5>
          </div>
          <button
            type="button"
            onClick={toggleModal}
            className="btn-close position-absolute top-0 end-0 m-3"
          ></button>

          <div className="mb-3">
            <Label for="specialization">Titre ou Spécialisation</Label>
            <Input
              type="text"
              id="specialization"
              placeholder="Ex. Développeur Full Stack"
              required
            />
          </div>

          <div className="mb-3">
            <Label for="coverLetter">Lettre de Motivation</Label>
            <textarea
              className="form-control"
              id="coverLetter"
              rows="5"
              placeholder="Entrez votre lettre de motivation"
              required
            ></textarea>
          </div>

          <div className="mb-3">
            <Label for="resumeUpload">Téléchargez votre CV</Label>
            <Input type="file" id="resumeUpload" required />
          </div>

          <div className="mb-3">
            <Label for="skills">Compétences</Label>
            <Input
              type="text"
              id="skills"
              placeholder="Listez vos compétences (ex. JavaScript, React)"
              required
            />
          </div>

          <div className="mb-3">
            <Label for="rate">Tarif (€/heure)</Label>
            <Input
              type="number"
              id="rate"
              placeholder="Entrez votre tarif horaire"
              required
            />
          </div>

          <div className="mb-3">
            <Label for="availability">Disponibilité</Label>
            <Input
              type="text"
              id="availability"
              placeholder="Quand pouvez-vous commencer ?"
              required
            />
          </div>

          <button type="submit" className="btn btn-primary w-100">
            Envoyer ma Candidature
          </button>
        </ModalBody>
      </Modal>

      {totalPages > 1 && (
        <CustomResponsivePagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      )}
    </React.Fragment>
  );
};

export default JobFavVacancy;
