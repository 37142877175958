import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import JobDetailImage from "../../../assets/images/job-detail.jpg";
// import { Link } from "react-router-dom";

const JobDetailsDescription = ({ selectedDetailsDescription }) => {
  const [isCoverLoaded, setIsCoverLoaded] = useState(false);

  // Fonction de gestion du chargement de l'image de couverture
  const handleCoverImageLoad = () => {
    setIsCoverLoaded(true);
  };

  // Valeurs par défaut pour éviter les erreurs
  const defaultDetails = {
    title: "Titre non disponible",
    positionAvailable: 0,
    jobCategory: { name: "Non spécifié" },
    contractTypes: [],
    description: "<p>Aucune description disponible.</p>",
    responsibility: "<p>Aucune responsabilité définie.</p>",
    qualification: "<p>Aucune qualification requise.</p>",
    technicalSkills: "<p>Aucune compétence technique spécifiée.</p>",
    skills: [],
    languages: [],
    company: {
      coverPhoto: JobDetailImage,
      logo: "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg",
    },
  };

  // Si selectedDetails? est undefined, renvoyez null pour ne rien afficher
  if (!selectedDetailsDescription) {
    return defaultDetails;
  }

  // Fonction pour afficher du HTML en toute sécurité
  const renderHTMLContent = (htmlContent) => {
    return { __html: htmlContent };
  };

  const renderResponsibilities = () => {
    if (!selectedDetailsDescription?.responsibility) return null;

    return (
      <div className="job-detail-desc mt-2">
        <div
          dangerouslySetInnerHTML={renderHTMLContent(
            selectedDetailsDescription?.responsibility
          )}
        />
      </div>
    );
  };

  const renderQualifications = () => {
    if (!selectedDetailsDescription?.qualification) return null;

    return (
      <div className="job-detail-desc mt-2">
        <div
          dangerouslySetInnerHTML={renderHTMLContent(
            selectedDetailsDescription?.qualification
          )}
        />
      </div>
    );
  };

  const renderTechnicalSkills = () => {
    if (!selectedDetailsDescription?.technicalSkills) return null;

    return (
      <div className="job-details-desc mt-2">
        <div
          dangerouslySetInnerHTML={renderHTMLContent(
            selectedDetailsDescription?.technicalSkills
          )}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <Card className="job-detail overflow-hidden">
        <div>
          <img
            src={
              selectedDetailsDescription?.company?.coverPhoto || JobDetailImage
            }
            alt=""
            width="100%"
            height={80}
            className="img-fluid"
            onLoad={handleCoverImageLoad} // Déclenchement après le chargement de l'image
          />
          {/* Le logo ne s'affiche que lorsque l'image de couverture est chargée */}
          {isCoverLoaded && (
            <div className="job-details-compnay-profile">
              <img
                src={
                  selectedDetailsDescription?.company?.logo ||
                  "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                }
                alt=""
                width="100"
                height="100"
                style={{ border: "2px solid black", borderRadius: "10px" }}
                className="img-fluid rounded-3"
              />
            </div>
          )}
        </div>
        <CardBody className="p-4">
          <div>
            <Row>
              <Row>
                <Col md={8}>
                  <h5 className="mb-1">{selectedDetailsDescription?.title}</h5>
                </Col>
                <Col md={4}>
                  <ul className="list-inline text-end mb-0">
                    <li className="list-inline-item text-muted">
                      <i className="mdi mdi-account-multiple"></i>{" "}
                      {selectedDetailsDescription?.positionAvailable} Poste
                      {selectedDetailsDescription?.positionAvailable > 1
                        ? "s"
                        : ""}
                    </li>
                  </ul>
                </Col>
              </Row>
            </Row>
          </div>
          {selectedDetailsDescription?.jobType !== "EXTERNAL" ? (
            <div className="mt-4">
              <Row className="g-2">
                <Col lg={6}>
                  <div className="border rounded-start p-3">
                    <p className="text-black mb-0 fs-13">
                      Domaine d'emploi :
                      <span className="fs-12 fw-bold">
                        {" "}
                        {selectedDetailsDescription?.jobCategory?.name?.toUpperCase()}
                      </span>
                    </p>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="border p-3">
                    <p className="text-black fs-13 mb-0">
                      Type de Contrat :
                      {selectedDetailsDescription?.contractTypes?.map(
                        (contract) => (
                          <span key={contract} className="fs-12 fw-bold ms-2">
                            {contract?.name}
                          </span>
                        )
                      )}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <Row></Row>
          )}
          <div className="mt-4">
            <h5 className="mb-3 text-danger">Description de l'offre</h5>
            <div
              dangerouslySetInnerHTML={renderHTMLContent(
                selectedDetailsDescription?.description
              )}
            />
          </div>
          {selectedDetailsDescription?.jobType !== "EXTERNAL" ? (
            <>
              <div className="mt-4">
                <h5 className="mb-3 text-danger">Responsabilités</h5>
                {renderResponsibilities()}
              </div>
              <div className="mt-4">
                <h5 className="mb-3 text-danger">Qualification</h5>

                {renderQualifications()}
              </div>
              <div className="mt-4">
                <h5 className="mb-3 text-danger">Compétences Techniques</h5>
                {renderTechnicalSkills()}
                <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
                  {selectedDetailsDescription?.skills?.map((skill, index) => (
                    <span key={index} className="badge bg-primary">
                      {skill?.name}
                    </span>
                  ))}
                </div>
              </div>
              <div className="mt-4">
                <h5 className="mb-3 text-danger">Langues parlées</h5>
                <div className="job-details-desc">
                  <ul className="job-detail-list list-unstyled mb-0 text-muted">
                    {selectedDetailsDescription?.languages?.length > 0 && (
                      <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
                        {selectedDetailsDescription?.languages?.map(
                          (language, index) => (
                            <span key={index} className="badge bg-secondary">
                              {language?.name}
                            </span>
                          )
                        )}
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </>
          ) : (
            <Row></Row>
          )}
          {selectedDetailsDescription?.jobType === "EXTERNAL" ? (
            <div className="mt-3 text-end">
              <Link
                to={selectedDetailsDescription?.externalLink}
                className="btn btn-primary btn-hover"
                target="_blank"
              >
                Candidater <i className="uil uil-arrow-right"></i>
              </Link>
            </div>
          ) : (
            <Row></Row>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default JobDetailsDescription;
