import React from "react";
import { Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

const RightSideContent = ({ selectedDetails }) => {
  const popular = [
    {
      id: 1,
      count: "20.5 M",
      jobTitle: " Chiffre d'affaire",
    },
    {
      id: 2,
      count: "500",
      jobTitle: "collaborateurs",
    },
    {
      id: 3,
      count: "5",
      jobTitle: "Pays",
    },
    {
      id: 4,
      count: "15",
      jobTitle: "an(s) d'expérience(s)",
    },
  ];
  return (
    <React.Fragment>
      <Col lg={7}>
        <Card className="ms-lg-4 mt-4 mt-lg-0">
          <CardBody className="p-4">
            <div className="mb-5">
              <h6 className="fs-17 fw-semibold mb-4">A propos de nous</h6>
              <p className="text-muted">{selectedDetails.description}</p>
            </div>
            <div className="mb-5">
              <h6 className="fs-17 fw-semibold mb-4">
                Pourquoi nous rejoindre
              </h6>
              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                <li>
                  <i className="uil uil-plus"></i> 13ème mois
                </li>
                <li>
                  <i className="uil uil-plus"></i> Flexibilité des horaires
                </li>
                <li>
                  <i className="uil uil-plus"></i> Activités sportives
                </li>
                <li>
                  <i className="uil uil-plus"></i> Bonne mutuelle
                </li>
              </ul>
            </div>
            <div className="mb-5">
              <h6 className="fs-17 fw-semibold mb-4">
                Notre processus de recrutement
              </h6>
              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                <li>
                  <i className="uil uil-circle"></i> Pré-sélection par le
                  recruteur en charge du poste
                </li>
                <li>
                  <i className="uil uil-circle"></i> Qualification téléphonique
                  pour la vérification du profil
                </li>
                <li>
                  <i className="uil uil-circle"></i> Entretien de recrutement
                  avec le recruteur
                </li>
                <li>
                  <i className="uil uil-circle"></i> Entretien de recrutement
                  avec le manager et/ou des opérationnels
                </li>
              </ul>
            </div>
            <div className="mb-5">
              <h6 className="fs-17 fw-semibold mb-4">
                L'entreprise en chiffre
              </h6>
              <div className="wedget-popular-title mt-1">
                {/* <h5>Populaire</h5> */}
                <ul className="list-inline">
                  {(popular || []).map((popularDetails, key) => (
                    <li className="list-inline-item" key={key}>
                      <div className="popular-box d-flex align-items-center">
                        <div className="lex-shrink-0 me-2">
                          {popularDetails.count}
                        </div>
                        <br />
                        <Link to="#" className="warning-link stretched-link">
                          <h6 className="fs-14 mb-0">
                            {popularDetails.jobTitle}
                          </h6>
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightSideContent;
