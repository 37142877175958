import React, { useEffect, useState } from "react";
import {
  Col,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Card,
  NavItem,
  CardBody,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchApplicationJobs } from "../../../redux/job/action";
import classnames from "classnames";
import Lottie from "react-lottie";
import * as loadingAnimationData from "../../../../src/assets/loading.json";
import * as errorAnimationData from "../../../../src/assets/error.json"; // Animation pour l'erreur
import * as emptyAnimationData from "../../../../src/assets/emptyData.json"; // Animation pour données vides
import { Table } from "antd";

const Content = () => {
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();

  const { loading, job, error } = useSelector((state) => state.jobReducer);
  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(fetchApplicationJobs());
    window.scrollTo(0, 0);
  }, [dispatch]);

  const getBadgeClass = (status) => {
    switch (status) {
      case "ACCEPT":
        return "badge bg-success-subtle text-success fs-15 me-2"; // Accepté
      case "IN_PROGRESS":
        return "badge bg-info-subtle text-info fs-15 me-2"; // En cours
      case "REJECT":
        return "badge bg-danger-subtle text-danger fs-15 me-2"; // Rejeté
      case null:
      default:
        return "badge bg-info-subtle text-info fs-15 me-2"; // En attente
    }
  };

  const columns = [
    {
      title: "Libellé",
      dataIndex: "libelle",
      key: "libelle",
      render: (text) => (
        <strong style={{ textTransform: "uppercase" }}>{text}</strong>
      ), // Affichage en majuscules
    },
    {
      title: "Statut",
      dataIndex: "decisionJobStatus",
      key: "decisionJobStatus",
      render: (status) => (
        <span className={getBadgeClass(status)}>
          {status === "VALID"
            ? "ACCEPTÉ"
            : status === "IN_PROGRESS"
            ? "EN COURS"
            : status === "REJECT"
            ? "REJETÉ"
            : "RECUE"}
        </span>
      ), // Affichage du badge de statut
    },
    {
      title: "Entreprise",
      dataIndex: "company",
      key: "company",
      render: (company) => company || "N/A",
    },
  ];

  const data = job?.content?.map((jobDetail) => ({
    key: jobDetail?.id,
    libelle: jobDetail?.job?.title,
    decisionJobStatus: jobDetail?.status,
    company: jobDetail?.job?.company?.name,
  }));

  // Paramètres pour l'animation Lottie de chargement
  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Paramètres pour l'animation Lottie d'erreur
  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Paramètres pour l'animation Lottie de données vides
  const emptyOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card className="profile-content-page mt-4 mt-lg-0">
          <Nav
            className="profile-content-nav nav-pills border-bottom mb-4"
            id="pills-tab"
            role="tablist"
          >
            <NavItem role="mes candidatures">
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  tabChange("1");
                }}
                type="button"
              >
                Mes candidatures
              </NavLink>
            </NavItem>
            {/* <NavItem role="mes formations">
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  tabChange("2");
                }}
                type="button"
              >
                Mes formations
              </NavLink>
            </NavItem> */}
          </Nav>

          <CardBody className="p-4">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {loading ? (
                  <Lottie options={loadingOptions} height={100} width={100} />
                ) : error ? (
                  <div className="text-center mt-5">
                    <Lottie options={errorOptions} height={100} width={100} />
                    <p className="text-danger mt-3">
                      Une erreur s'est produite : {error}
                    </p>
                  </div>
                ) : job?.totalElements === 0 ? (
                  <div className="text-center mt-5">
                    <Lottie options={emptyOptions} height={100} width={100} />
                    <p className="text-muted mt-3">
                      Aucune candidature trouvée. Veuillez réessayer plus tard.
                    </p>
                  </div>
                ) : (
                  <>
                    <Table size="small" columns={columns} dataSource={data} />
                  </>
                )}
              </TabPane>
              {/* <TabPane tabId="2"></TabPane> */}
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Content;
