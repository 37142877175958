import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, Card, CardBody } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import TokenService from "../../../../service/TokenService";
import { fetchCandidateInfoPerso } from "../../../../redux/profile/action";
import { Tooltip } from "antd";
import "./JobDetails.css";
import { FiveStarRating } from "../../../../Layout/CommonLayout/FiveStarRating";
import { applyOffer } from "../../../../redux/freelance/offer/action";
import 'react-toastify/dist/ReactToastify.css';

const RightSideContent = ({ offerDetails }) => {
  const [user, setUser] = useState(null);
  const [modal, setModal] = useState(false);
  const [showProposalFields, setShowProposalFields] = useState(false);
  const [message, setMessage] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("FCFA");
  const [duration, setDuration] = useState("");
  const [hasApplied, setHasApplied] = useState(false);

  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { infoPersoDetails } = useSelector((state) => state.profileReducer);
  /*const applications = useSelector(state => state.offer?.applications || []);*/

  // Récupérer l'utilisateur connecté
  useEffect(() => {
    const getUser = TokenService.getUser();
    if (getUser) {
      setUser(getUser);
    }
  }, []);

  const openModal = () => {
    if (hasApplied) {
      toast.warning("Vous avez déjà postulé à cette offre.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setModal(true);
      dispatch(fetchCandidateInfoPerso());
    }
  };

  const closeModal = () => setModal(false);

  const onSubmit = () => {
    if (hasApplied) {
      toast.warning("Vous avez déjà postulé à cette offre.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (authUser?.isLoggedIn && infoPersoDetails?.data.urlCV) {
      dispatch(applyOffer(offerDetails.id, message, duration, price, currency))
          .then(() => {

            toast.success("Votre candidature a été envoyée avec succès.", {
              position: toast.POSITION.TOP_CENTER,
            });
            setHasApplied(true); // Marquer comme postulé
            closeModal();
          })
    };


      // toast.success("Votre candidature a été envoyée avec succès.", {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      // setHasApplied(true); // Marquer comme postulé
      // closeModal();
  };
  return (
    <React.Fragment>
      <div className="side-bar ms-lg-4">
        <Card className="job-overview">
          <CardBody className="p-4">
            <h6 className="fs-17">Aperçu du poste</h6>
            <ul className="list-unstyled mt-4 mb-0">
              <li>
                <div className="d-flex mt-4">
                  <i className="mdi mdi-cash-multiple icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Budget</h6>
                    <p className="text-muted mb-0">
                      {Math.floor(offerDetails?.budget)} {offerDetails?.devise}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-location-point icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Lieu</h6>
                    <p className="text-muted mb-0">
                      {offerDetails?.address?.city},{" "}
                      {offerDetails?.address?.country}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-history icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Date de publication</h6>
                    <p className="text-muted mb-0">
                      {offerDetails?.published_at &&
                        new Date(offerDetails.published_at).toLocaleDateString(
                          "fr-FR",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="mdi mdi-calendar-clock icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Delai de candidature</h6>
                    <p className="text-muted mb-0">
                      {offerDetails?.deadline &&
                        new Date(offerDetails.deadline).toLocaleDateString(
                          "fr-FR",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-3">
              <Tooltip
                title={
                  user === null ? "Veuillez vous connecter pour postuler" : ""
                }
                color="red"
              >
                <button
                  id="applyButton"
                  onClick={user !== null ? openModal : undefined}
                  className="btn btn-primary btn-hover w-100 mt-2"
                  disabled={offerDetails?.hasApplied}
                >
                  {hasApplied ? "Déjà postulé" : "Candidater"}{" "}
                  <i className="uil uil-arrow-right"></i>
                </button>
              </Tooltip>
            </div>
          </CardBody>
        </Card>

        <Card className="company-profile mt-4">
          <CardBody className="p-4">
            <div className="text-center">
              <img
                src={
                  offerDetails?.enterprise_logo ||
                  "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                }
                width="50"
                height="50"
                alt=""
                style={{ border: "2px solid black", borderRadius: "10px" }}
                className="img-fluid rounded-3"
              />

              <div className="mt-4">
                <h6 className="fs-17 mb-1">{offerDetails?.enterprise_name}</h6>
                {/*<p className="text-muted">Depuis juillet 2017</p>*/}
              </div>
            </div>
            <ul className="list-unstyled mt-4">
              <li>
                <div className="d-flex">
                  <i className="uil uil-phone-volume text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Phone</h6>
                    <p className="text-muted fs-14 mb-0">
                      {offerDetails?.enterprise_phone}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="uil uil-envelope text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Email</h6>
                    <p className="text-muted fs-14 mb-0">
                      {offerDetails?.enterprise_email}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="uil uil-globe text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Website</h6>
                    <p className="text-muted fs-14 text-break mb-0">
                      {offerDetails?.enterprise_website}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="uil uil-map-marker text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Localisation</h6>
                    <p className="text-muted fs-14 mb-0">
                      {`${offerDetails?.enterprise_address?.city}, ${offerDetails?.enterprise_address?.country}`}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="uil uil-star text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Note</h6>
                    <FiveStarRating rating={5} />
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-4">
              <Link
                to={{
                  pathname: `/companydetails/${offerDetails?.enterprise_id}`,
                }}
                className="btn btn-primary btn-hover w-100 rounded"
              >
                <i className="mdi mdi-eye"></i> Voir plus
              </Link>
            </div>
          </CardBody>
        </Card>
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal isOpen={modal} toggle={openModal} centered>
              <ModalBody className="modal-body p-5">
                <div className="text-center mb-4">
                  <h5 className="modal-title mb-1" id="staticBackdropLabel">
                    Candidater avec mon profil{" "}
                  </h5>
                  <p className="text-muted mb-0 fs-15">{offerDetails?.title}</p>
                </div>
                <div className="mt-3">
                  <button
                    className="btn btn-outline-primary w-100 fw-bold py-2"
                    onClick={() => setShowProposalFields(!showProposalFields)}
                  >
                    + Faire une proposition
                  </button>
                </div>
                {showProposalFields && (
                  <div className="mt-3">
                    <textarea
                      className="form-control mb-2"
                      placeholder="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <div className="d-flex gap-2">
                      <input
                        type="number"
                        className="form-control mb-2"
                        placeholder="Prix"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                      <select
                        className="form-control mb-2"
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                      >
                        <option value="FCFA">FCFA</option>
                        <option value="EUR">EURO (€) </option>
                        <option value="USD">USD ($)</option>
                      </select>
                    </div>

                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Durée"
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                    />
                  </div>
                )}
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="btn-close"
                    aria-label="Close"
                  ></button>
                </div>

                <button
                  type="button"
                  onClick={onSubmit}
                  className="btn btn-primary mt-3 w-100"
                >
                  Postuler
                </button>

                {/* Ajout du message pour mettre à jour le profil */}
                <div className="text-center mt-3">
                  <p className="text-muted mb-0">
                    Vous n'avez pas encore mis à jour votre profil ?
                  </p>
                  <a
                    href="/myprofile"
                    className="btn btn-link text-primary fw-semibold"
                  >
                    Mettre à jour mon profil
                  </a>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

export default RightSideContent;

