export const FETCH_OFFERS_REQUEST = "FETCH_OFFERS_REQUEST";
export const FETCH_OFFERS_SUCCESS = "FETCH_OFFERS_SUCCESS";
export const FETCH_OFFERS_FAILURE = "FETCH_OFFERS_FAILURE";
export const FETCH_OFFER_DETAILS_REQUEST = "FETCH_OFFER_DETAILS_REQUEST";
export const FETCH_OFFER_DETAILS_SUCCESS = "FETCH_OFFER_DETAILS_SUCCESS";
export const FETCH_OFFER_DETAILS_FAILURE = "FETCH_OFFER_DETAILS_FAILURE";
export const FETCH_SIMILAR_OFFERS_REQUEST = "FETCH_SIMILAR_OFFERS_REQUEST";
export const FETCH_SIMILAR_OFFERS_SUCCESS = "FETCH_SIMILAR_OFFERS_SUCCESS";
export const FETCH_SIMILAR_OFFERS_FAILURE = "FETCH_SIMILAR_OFFERS_FAILURE";
export const FETCH_OFFERS_ADDRESSES_REQUEST = "FETCH_OFFERS_ADDRESSES_REQUEST";
export const FETCH_OFFERS_ADDRESSES_SUCCESS = "FETCH_OFFERS_ADDRESSES_SUCCESS";
export const FETCH_OFFERS_ADDRESSES_FAILURE = "FETCH_OFFERS_ADDRESSES_FAILURE";
export const FETCH_FAVORITE_OFFERS_REQUEST = "FETCH_FAVORITE_OFFERS_REQUEST";
export const FETCH_FAVORITE_OFFERS_SUCCESS = "FETCH_FAVORITE_OFFERS_SUCCESS";
export const FETCH_FAVORITE_OFFERS_FAILURE = "FETCH_FAVORITE_OFFERS_FAILURE";
export const ADD_FAVORITE_OFFER_REQUEST = "ADD_FAVORITE_OFFER_REQUEST";
export const ADD_FAVORITE_OFFER_SUCCESS = "ADD_FAVORITE_OFFER_SUCCESS";
export const ADD_FAVORITE_OFFER_FAILURE = "ADD_FAVORITE_OFFER_FAILURE";
export const REMOVE_FAVORITE_OFFER_REQUEST = "REMOVE_FAVORITE_OFFER_REQUEST";
export const REMOVE_FAVORITE_OFFER_SUCCESS = "REMOVE_FAVORITE_OFFER_SUCCESS";
export const REMOVE_FAVORITE_OFFER_FAILURE = "REMOVE_FAVORITE_OFFER_FAILURE";

// recents job types
export const FETCH_RECENT_FREELANCE_JOBS_REQUEST =
    "FETCH_RECENT_FREELANCE_JOBS_REQUEST";
export const FETCH_RECENT_FREELANCE_JOBS_SUCCESS =
    "FETCH_RECENT_FREELANCE_JOBS_SUCCESS";
export const FETCH_RECENT_FREELANCE_JOBS_FAILURE =
    "FETCH_RECENT_FREELANCE_JOBS_FAILURE";

//Postuler à une offre & supprimer
export const APPLY_FREELANCE_JOB_REQUEST = "APPLY_FREELANCE_JOB_REQUEST";
export const APPLY_FREELANCE_JOB_SUCCESS = "APPLY_FREELANCE_JOB_SUCCESS";
export const APPLY_FREELANCE_JOB_FAILURE = "APPLY_FREELANCE_JOB_FAILURE";
export const REMOVE_APPLICATION_REQUEST = "REMOVE_APPLICATION_REQUEST";
export const REMOVE_APPLICATION_SUCCESS = "REMOVE_APPLICATION_SUCCESS";
export const REMOVE_APPLICATION_FAILURE = "REMOVE_APPLICATION_FAILURE";

//Proposition détails
export const FETCH_PROPOSAL_DETAILS_REQUEST = "FETCH_PROPOSAL_DETAILS_REQUEST";
export const FETCH_PROPOSAL_DETAILS_SUCCESS = "FETCH_PROPOSAL_DETAILS_SUCCESS";
export const FETCH_PROPOSAL_DETAILS_FAILURE = "FETCH_PROPOSAL_DETAILS_FAILURE";
//
export const FETCH_OFFER_AND_PROPOSAL_DETAILS_SUCCESS = "FETCH_OFFER_AND_PROPOSAL_DETAILS_SUCCESS";
export const FETCH_OFFER_AND_PROPOSAL_DETAILS_REQUEST = "FETCH_OFFER_AND_PROPOSAL_DETAILS_REQUEST";
export const FETCH_OFFER_AND_PROPOSAL_DETAILS_FAILURE = "FETCH_OFFER_AND_PROPOSAL_DETAILS_FAILURE";


export const FETCH_APPLICATIONS_FREELANCE_JOB_REQUEST = "FETCH_APPLICATIONS_FREELANCE_JOB_REQUEST";
export const FETCH_APPLICATIONS_FREELANCE_JOB_SUCCESS = "FETCH_APPLICATIONS_FREELANCE_JOB_SUCCESS";
export const FETCH_APPLICATIONS_FREELANCE_JOB_FAILURE = "FETCH_APPLICATIONS_FREELANCE_JOB_FAILURE";
export const REMOVE_FREELANCE_JOB_REQUEST = "REMOVE_FREELANCE_JOB_REQUEST";
export const REMOVE_FREELANCE_JOB_SUCCESS = "REMOVE_FREELANCE_JOB_SUCCESS";
export const  REMOVE_FREELANCE_JOB_FAILURE = "REMOVE_FREELANCE_JOB_FAILURE";

//Liste des candidatures à une offre

export const FETCH_OFFER_APPLICATIONS_REQUEST = "FETCH_OFFER_APPLICATIONS_REQUEST";
export const FETCH_OFFER_APPLICATIONS_SUCCESS = "FETCH_OFFER_APPLICATIONS_SUCCESS";
export const FETCH_OFFER_APPLICATIONS_FAILURE = "FETCH_OFFER_APPLICATIONS_FAILURE";

//contrat
export const FETCH_CONTRACTS_REQUEST = "FETCH_CONTRACTS_REQUEST";
export const FETCH_CONTRACTS_SUCCESS = "FETCH_CONTRACTS_SUCCESS";
export const FETCH_CONTRACTS_FAILURE = "FETCH_CONTRACTS_FAILURE";
//details contrat
export const FETCH_CONTRACT_DETAILS_REQUEST = "FETCH_CONTRACT_DETAILS_REQUEST";
export const FETCH_CONTRACT_DETAILS_SUCCESS = "FETCH_CONTRACT_DETAILS_SUCCESS";
export const FETCH_CONTRACT_DETAILS_FAILURE = "FETCH_CONTRACT_DETAILS_FAILURE";
