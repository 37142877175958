import React, { useState, useEffect } from "react";

import resetPasswordImage from "../../assets/images/auth/reset-password.png";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { resetPasswordInit, clearError } from "../../redux/auth/action";
import { clearMessage } from "../../redux/message/action";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";

const ResetPassword = () => {
  document.title = "Reset Password | Recrutech";

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [activationSuccess, setActivationSuccess] = useState(false); // Ajouter un état pour suivre le succès de l'activation
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (activationSuccess) {
      navigate("/newpassword"); // Naviguer uniquement si l'activation est réussie
    }
  }, [activationSuccess, navigate]);

  const handleEmailChange = (e) => {
    dispatch(clearMessage());
    setEmail(e.target.value);
  };

  const submitVerification = async () => {
    setLoading(true);
    const result = await dispatch(resetPasswordInit(email));
    if (result.success) {
      setActivationSuccess(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    dispatch(clearError());
    dispatch(clearMessage());
  }, [dispatch]);
  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src="https://res.cloudinary.com/dryskp7hv/image/upload/v1688758187/Eburnie%20hub/logo/rt_couleur_simple_480_dh81k5.png"
                                height="100"
                                alt=""
                                className="logo-dark"
                              />
                            </Link>
                            <div className="mt-0">
                              <img
                                src={resetPasswordImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content p-5 h-100 text-black">
                            <div className="text-center mb-4">
                              <h5>Réinitialiser le mot de passe</h5>
                            </div>
                            <Form className="auth-form text-black">
                              <div
                                className="alert alert-warning text-center mb-4"
                                role="alert"
                              >
                                {" "}
                                Entrez votre Email et les instructions vous
                                seront envoyées!{" "}
                              </div>
                              <div className="mb-4">
                                <Input
                                  type="email"
                                  className="form-control"
                                  value={email}
                                  onChange={handleEmailChange}
                                  id="email"
                                  placeholder="Enter username or email"
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                />
                              </div>
                              <div className="mt-3">
                                <button
                                  type="submit"
                                  onClick={submitVerification}
                                  className="btn btn-primary w-100"
                                  disabled={loading} // Désactiver le bouton lorsque le chargement est en cours
                                >
                                  {loading ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    "soumettre"
                                  )}
                                </button>
                              </div>
                            </Form>
                            <div className="mt-5 text-center text-black-50">
                              <p>
                                Vous vous en souvenez ?{" "}
                                <Link
                                  to="/"
                                  className="fw-medium text-primary text-decoration-underline"
                                >
                                  {" "}
                                  Retour à l'Accueil{" "}
                                </Link>
                              </p>
                            </div>
                            <ToastContainer />
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
