import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import JobDetailsDescription from "./JobDetailsDescription";
import JobVacancyPost from "./JobVacancyPost";
import RightSideContent from "./RightSideContent";
import { fetchJobDetails } from "../../../redux/job/action";
import { useParams } from "react-router-dom";
import Section from "./Section";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "react-lottie";

import * as animationData from "../../../assets/loading.json"; // Remplacez par le chemin de votre fichier Lottie
import JobDetailsSkeleton from "./JobDetailsSkeleton";
// import * as errorAnimationData from "../../../../src/assets/error.json"; // Animation pour l'erreur

const JobDetails = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  document.title = "Detail de l'emploi | RecruTech";
  const [leftJobDescription, setLeftJobDescription] = useState(null);
  const [rightJobDescription, setRightJobDescription] = useState(null);

  // Gérer l'état de chargement
  const [loading, setLoading] = useState(true);

  // Sélectionner les détails du job et les erreurs depuis le store
  const { selectedDetails, error } = useSelector((state) => state.jobReducer);

  useEffect(() => {
    if (slug) {
      dispatch(fetchJobDetails(slug));
    } else {
      // Si le slug est vide, actualiser la page
      window.location.reload();
    }
    window.scrollTo(0, 0);
  }, [dispatch, slug]);

  // Gérer l'état de chargement en fonction des données ou des erreurs
  useEffect(() => {
    if (selectedDetails) {
      setLeftJobDescription(selectedDetails);
      setRightJobDescription(selectedDetails);
      // Simuler un délai de 3000ms (3 secondes)
      setTimeout(() => {
        setLoading(false);
      }, 1000); // 3000 millisecondes = 3 secondes
    }
    if (error) {
      window.location.reload();
    }
  }, [selectedDetails, error]);

  // Paramètres pour l'animation Lottie de chargement
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            {loading ? (
              <Col lg={12}>
                <div className="mt-5">
                  {/* Utilisation de Skeleton d'Ant Design */}
                  <JobDetailsSkeleton />
                </div>
              </Col>
            ) : error ? (
              <div className="text-center mt-5">
                <Lottie options={defaultOptions} height={300} width={300} />
                <p className="text-danger mt-3">
                  Une erreur s'est produite : {error}
                </p>
              </div>
            ) : (
              <>
                {selectedDetails?.jobType !== "EXTERNAL" ? (
                  <>
                    <Col lg={8}>
                      <Row>
                        <JobDetailsDescription
                          selectedDetailsDescription={leftJobDescription}
                        />
                      </Row>

                      <Row>
                        <JobVacancyPost
                          selectedSimilarJobs={selectedDetails?.id}
                          companyId={selectedDetails?.company?.id}
                        />
                      </Row>
                    </Col>
                    <Col lg={4} className="mt-4 mt-lg-0">
                      <RightSideContent
                        selectedRightDetails={rightJobDescription}
                      />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col lg={12}>
                      <Row>
                        <JobDetailsDescription
                          selectedDetailsDescription={leftJobDescription}
                        />
                      </Row>
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobDetails;
